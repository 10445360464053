<template>
  <div class="quanmin-index" v-if="ns">
    <member v-if="isMember" :namespace="ns" />
    <open v-else :namespace="ns" />
    <!-- 提前预加载图片 -->
    <img
      style="display: none"
      :src="require('../../assets/images/qm_back_header_bg06.png')"
    />
    <img
      style="display: none"
      :src="require('../../assets/images/qm_back_header_bg03.png')"
    />
    <img
      style="display: none"
      :src="`https://cdn.xiangshuheika.com/static/${namespace}/leave-dialog.png`"
    />
    <van-dialog
      v-model="show"
      :theme="`confirm qm-exit-dialog dialog-${namespace}`"
      confirm-button-text="我再想想"
      cancel-button-text="忍痛离开"
      @confirm="close"
      @cancel="leave"
      show-cancel-button
    >
      <div :class="`leave-modal leave-model-${namespace}`">
        <van-image
          :src="`https://cdn.xiangshuheika.com/static/${namespace}/leave-dialog.png`"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import ans from "@/utils/ans";
import { mapActions, mapState } from "vuex";
import member from "./member";
import open from "./open";

export default {
  components: {
    member,
    open,
  },
  data() {
    return {
      show: false,
      ns: "",
    };
  },

  async mounted() {
    if (this.isMember) {
      ans.pageView("page_member");
    } else {
      ans.pageView("page_visitor");
    }
    if (!this.namespace) {
      const user = await this.fetchUserInfo();
      this.ns = user.namespace;
    }
    const fromApp =
      this.$route.query.fromApp || sessionStorage.getItem("fromApp");
    if (fromApp) {
      sessionStorage.setItem("fromApp", fromApp);
    }
    document.title = this.$getConfigValue("INDEX_TITLE");
    // if (this.$route.query.user_token) {
    //   this.$router.replace("/quanmin");
    // }
    window.stayAlert = () => {
      if (!this.userInfo.is_member) {
        // 显示弹窗
        ans.track("visitor_quit", {
          namespace: this.namespace,
          event_type: "EXP",
        });
        this.show = true;
      } else if (window.qm) {
        window.qm.returnPage();
      } else if (window.ydh) {
        window.ydh.returnPage();
      } else if (window.NEWQMQB) {
        window.NEWQMQB.returnPage();
      }
    };
  },

  computed: {
    ...mapState(["isMember", "userInfo", "namespace"]),
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    leave() {
      // 返回
      ans.track("visitor_quit1", {
        namespace: this.namespace,
      });
      if (window.qm) {
        window.qm.returnPage();
      } else if (window.ydh) {
        window.ydh.returnPage();
      } else if (window.NEWQMQB) {
        window.NEWQMQB.returnPage();
      }
    },

    close() {
      ans.track("visitor_quit2", {
        namespace: this.namespace,
      });
    },
  },
  watch: {
    namespace: {
      immediate: true,
      handler(val) {
        this.ns = val;
      },
    },
  },
};
</script>

<style lang="less">
.quanmin-index {
  .van-dialog__content {
    padding: 0 !important;
  }
  .dialog-content {
    padding: 0;
  }
  .dialog-title {
    display: none;
  }
  .van-dialog__footer .van-dialog__cancel {
    background-color: rgb(240, 242, 248);
    border: none;
  }
  .leave-modal-header {
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.qm-exit-dialog {
  background: transparent;
  width: 241px;
  .van-dialog__footer {
    justify-content: space-between;
    height: 36px;
    padding: 0 !important;
    margin-top: 15px;
  }
  .van-dialog__cancel {
    margin-right: 0 !important;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0) !important;
    width: 99px !important;
    height: 36px !important;
    background: url("../../assets/images/qm_quit_cancel.png") no-repeat !important;
    background-size: 100% !important;
  }
  .van-dialog__confirm {
    &::before,
    &::after {
      content: none;
    }
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0) !important;
    width: 132px !important;
    height: 36px !important;
    background: url("../../assets/images/qm_quit_confirm.png") no-repeat !important;
    background-size: 100% !important;
  }
}

.quanmin {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
}

.quanmin-index .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(#222d49, 0.8);
  box-shadow: 0px -5px 10px 0px rgba(#000, 0.2);
}
.quanmin_quanmin03 {
  background-color: rgb(246, 248, 252);
  background-image: url(../../assets/images/quanmin03_header.png);
  .footer {
    background-color: #222d49;
  }
  .agreement {
    color: #3b4051;
    background: #fdf7e6;
  }
  .package {
    background: linear-gradient(180deg, #44495c, #20212d);
  }
  .package-btn {
    background-image: url(../../assets/images/quanmin_footer_btn.png);
  }
  .package-discount-tag {
    background-image: url(../../assets/images/quanmin_discount.png);
  }
  .package-face-value {
    color: #f6fbff;
  }
}
.quanmin_quanmin06,
.quanmin_qmfqy06,
.quanmin_weiguang {
  background-color: rgb(246, 248, 252);
  background-image: url(../../assets/images/quanmin06_header.png);
  .page-footer {
    background-color: #850204;
  }
  .footer {
    background-color: #791710;
  }
  .agreement {
    color: #3b4051;
    background: #fdf7e6;
  }

  .package {
    background: linear-gradient(180deg, #44495c, #20212d);
  }
  .package-btn {
    background-image: url(../../assets/images/quanmin_footer_btn.png);
  }
  .package-discount-tag {
    background-image: url(../../assets/images/quanmin_discount.png);
  }
  .package-face-value {
    color: #fff0d8;
  }
}

.quanmin.quanmin_ydh1,
.quanmin.quanmin_qmydh1,
.quanmin.quanmin_ydh2 {
  padding: 550px 12px 0;

  .agreement {
    height: 24px;
    line-height: 24px;
    padding-left: 20px;
    text-align: left;
  }
  .page-footer {
    height: 90px;
  }
  .package {
    justify-content: space-between;
    .ydh-price {
      height: 66px;
      width: 230px;
      background-size: cover;
    }
    .ydh-facevalue {
      height: 66px;
      width: 142px;
      background-size: cover;
    }
    .ydh-count-dwon {
      position: absolute;
      display: flex;
      color: #fff;
      background: linear-gradient(to right, #ff9c32, #ff7402);
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 24px;
      right: 10px;
      top: 10px;
      font-size: 10px;
      line-height: 14px;
    }
    .count-down {
      color: #fff;
      font-size: 10px;
    }
  }

  .package-face-value {
    color: #fff0d8;
  }
  .page-benefits {
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
  .page-benefit-z6_privileges {
    .page-benefit-spu {
      margin-bottom: 10px;
    }
  }

  .fixed-height {
    height: 100px;
  }
}

.quanmin.quanmin_ydh1,
.quanmin.quanmin_qmydh1 {
  background-color: #17191d;
  background-image: url(../../assets/images/quanmin_ydh1.png);
  .page-footer {
    background: linear-gradient(180deg, #42356e, #42356c);
  }
  .agreement {
    color: #c8bcf2;
  }
  .ydh-price {
    background: url("../../assets/images/ydh1-price.png") no-repeat;
  }
  .ydh-facevalue {
    background: url("../../assets/images/ydh1-facevalue.png") no-repeat;
  }
}
.quanmin.quanmin_qmydh1 {
  background-image: url(../../assets/images/quanmin_qmydh1.jpg);
}
.quanmin.quanmin_ydh2 {
  background-color: #101116;
  background-image: url(../../assets/images/quanmin_ydh2.png);
  .page-footer {
    background-color: #392b2a;
  }
  .agreement {
    color: #ab9494;
    background: transparent;
  }
  .ydh-price {
    background: url("../../assets/images/ydh2-price.png") no-repeat;
  }
  .ydh-facevalue {
    background: url("../../assets/images/ydh2-facevalue.png") no-repeat;
  }
}

.quanmin.quanmin_qmwallet1,
.quanmin.quanmin_qmqb1,
.quanmin.quanmin_qmwallet2 {
  padding: 617px 12px 0;

  background-color: #150f12;

  .agreement {
    height: 24px;
    line-height: 24px;
    padding-left: 20px;
    text-align: left;
  }
  .page-benefits {
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
  .page-benefit-z6_privileges {
    .page-benefit-spu {
      margin-bottom: 16px;
    }
  }

  .package {
    position: relative;
    justify-content: space-between;
    .ydh-price {
      height: 66px;
      width: 230px;
      background-size: cover;
    }
    .ydh-facevalue {
      height: 66px;
      width: 142px;
      background-size: cover;
    }
    .ydh-count-dwon {
      position: absolute;
      display: flex;
      color: #fff;
      background: linear-gradient(to right, #ff9c32, #ff7402);
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 24px;
      right: 10px;
      top: 10px;
      font-size: 10px;
      line-height: 14px;
      line-height: 34px;
    }
    .count-down {
      color: #fff;
      font-size: 10px;
      line-height: 34px;
    }
  }
  .page-footer {
    background: #271c21;
    height: auto;
  }
  .agreement {
    color: #8e646f;
    margin-bottom: 10px;
  }
  .ydh-price {
    background: url("../../assets/images/qmwallet1-price.png") no-repeat right
      top;
    position: absolute;
    right: 0;
    top: -11px;
    width: 70% !important;
    background-size: contain !important;
    height: 77px !important;
  }
  .ydh-facevalue {
    width: 70% !important;
    background: url("../../assets/images/qmwallet1-facevalue.png") no-repeat;
    background-size: contain !important;
  }

  .ydh-count-dwon {
    top: -26px !important;
    background-size: 100% !important;
    height: 40px !important;
    line-height: 30px;
    font-size: 10px;
    align-items: flex-start !important;
  }
  .fixed-height {
    height: 120px !important;
  }
}

@qmwalletlist: qmwallet1, qmwallet2;
each(@qmwalletlist, {
  .c(@v){
     background-image: url("../../assets/images/quanmin_@{v}.png");
    .ydh-count-dwon {
      background: url("../../assets/images/@{v}-discount-bg.png") no-repeat
        center/100% !important;
    }
    .ydh-price {
      background: url("../../assets/images/@{v}-price.png") no-repeat right
        top;
      background-size: contain !important;
    }
    .ydh-facevalue {
      background: url("../../assets/images/@{v}-facevalue.png") no-repeat;
    }
  }
  .quanmin.quanmin_@{value} when (@index = 1) {
    .c(@value)
  }
  .quanmin.quanmin_qmqb1 when (@index = 1) {
    .c(@value);
    background-image: url("../../assets/images/quanmin_qmqb1.png");
  }
  .quanmin.quanmin_@{value} when (@index = 2) {
    .c(@value)
  }
})
  .quanmin_wg,
.quanmin_quanmin {
  background-image: url(../../assets/images/wg_header.png);
  .page-footer {
    background-color: #850204;
  }
  .agreement {
    color: #52270b;
    background: #fdf7e6;
  }

  .package {
    background: linear-gradient(180deg, #ef5751, #be2c26);
  }
  .package-btn {
    background-image: url(../../assets/images/wg_footer_btn.png);
  }
  .package-discount-tag {
    background-image: url(../../assets/images/wg_discount.png);
  }
  .package-face-value {
    color: #fff0d8;
  }
}
.quanmin {
  .package-discount {
    margin-left: 20px;
  }

  .package-price {
    font-size: 32px;
    padding-left: 9px;
    flex: 1;
    color: #f9ebc3;
  }

  .package-face-value {
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    text-decoration: line-through;
    color: #fce9cb;
    opacity: 0.9;
  }
  .page-benefit-free_privileges {
    .page-benefit-spu {
      margin-bottom: 40px;
    }
  }
  .package-discount-tag {
    background-repeat: no-repeat;
    background-size: contain;
    width: 70px;
    height: 20px;
    margin-bottom: 9px;
  }
  .package-price-unit {
    font-size: 30px;
    vertical-align: 10px;
    font-size: 14px;
    margin-left: -5px;
  }
  .package {
    display: flex;
    height: 65px;
    align-items: center;
    .package-btn {
      height: 40px;
      width: 120px;
      margin-right: 8px;
      margin-left: auto;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.quanmin-index {
  .agreement {
    padding-right: 30px;
    line-height: 30px;
    height: 30px;
    text-align: right;
    font-size: 12px;
  }
  .qm-image {
    width: 100%;
    height: 100%;
    display: block;
  }

  .fixed-height {
    height: 130px;
  }
  .quanmin_weiguang,
  .quanmin_quanmin03,
  .quanmin_qmfqy06,
  .quanmin_quanmin06 {
    padding: 370px 12px 0;
  }
  .quanmin_wg,
  .quanmin_quanmin {
    padding: 470px 12px 0;
  }
  .page-benefits {
    overflow: hidden;

    padding: 20px 13px;
    background-color: #fff;
    border-radius: 5px;
  }
  .switch-swiper-card {
    height: 200px;
    padding: 0 23px;
  }
  .van-swipe-item {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-card {
    height: 100%;
  }

  .qm-member-qmwallet1,
  .qm-member-qmqb1,
  .qm-member-qmwallet2 {
    background-color: #150f11 !important;
    .qmts06-card-wrap,
    .qmqb06-card-wrap {
      height: 263px;
    }
    .qmte06-card-wrap {
      height: 246px;
    }
  }

  .qm-card {
    height: 198px;
  }

  .qm-member-qmwallet1,
  .qm-member-qmqb1 {
    .qm-card-item {
      .card-exprie {
        color: #e598a6;
        padding-top: 100px;
        padding-left: 75px;
      }
    }
  }
  .qm-member-qmwallet2 .qm-card-item {
    .card-exprie {
      color: #dfa07a;
      padding-top: 90px;
      padding-left: 65px;
    }
  }

  .member-benefits.member-benefits-wg,
  .member-benefits.member-benefits-quanmin {
    .partner_privileges-img {
      background-image: url("../../assets/images/partner_privileges-img-wg.png") !important;
    }
    .life_privileges-img {
      background-image: url("../../assets/images/life_privileges-img-wg.png") !important;
    }
    .parnter-title {
      color: #914b48;
    }
    .parnter-subtitle {
      color: #481e11;
    }
  }
  .member-benefit {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;

    .benefit-title {
      margin-bottom: 20px;
      height: 19px;
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center;
      &.partner_privileges-img {
        background-image: url("../../assets/images/partner_privileges-img-qm.png");
      }
      &.life_privileges-img {
        background-image: url("../../assets/images/life_privileges-img-qm.png");
      }
    }
    .parnter-list-item {
      display: flex;
      align-items: center;
      color: #1d1a1b;
      margin-bottom: 24px;
    }
    .parnter-list-image {
      flex-basis: 53px;
      height: 57px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .parnter-title {
      line-height: 18px;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 7px;
      color: #191c32;
    }
    .parnter-subtitle {
      line-height: 18px;
      font-size: 12px;
      color: #191c32;
    }
    .parnter-list-info {
      flex: 1;
      padding-right: 10px;
    }
    .parnter-btn {
      padding: 0;
      margin-left: auto;
      flex-basis: 70px;
      flex-shrink: 0;
      height: 27px;
      background: linear-gradient(0deg, #ecd183, #f9ebc3);
      color: #13151d;
      font-size: 13px;
      line-height: 28px;
      border-radius: 4px;
      border: none;
      background-repeat: no-repeat;
      background-size: contain;
      &.parnter-btn-disabled {
        pointer-events: none;
      }
    }
  }

  .member-benefits-qmwallet1,
  .member-benefits-qmqb1,
  .member-benefits-qmwallet2 {
    .partner_privileges-img {
      height: 40px;
      background-image: none !important;
    }
    .member-benefit-partner_privileges {
      padding: 25px;
      background-size: 100%;
    }
    .parnter-title {
      color: #ffe6ef;
    }
    .parnter-subtitle {
      color: #98888f;
    }
    .parnter-list-image {
      width: 46px;
      height: 46px;
      flex-basis: 46px;
      margin-right: 15px;
    }
    .parnter-btn {
      width: 70px;
      height: 35px;
    }
    .member-benefit-life_privileges {
      padding: 0 6px;
      background-color: transparent;
      .life-list-item {
        height: 100%;
        width: 100%;
        position: relative !important;
      }
      .life-list {
        .list-inner {
          width: 100%;
          height: 146px;
          background-size: 100%;
          & + .list-inner {
            margin-top: 16px;
          }
        }
        .life-list-image {
          position: absolute;
          left: 16px;
          top: 60px;
          height: 50px;
          width: auto;
          img {
            width: auto;
            height: 100%;
          }
        }
        .life-title {
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: normal;
          padding: 0 8px;
        }
        .life-title-number {
          color: #fcd7a8;
          border: 1px solid currentColor;
          border-radius: 4px;
          margin-right: 10px;
          padding: 2px;
          font-size: 12px;
          line-height: 16px;
        }
        .life-title-sellpoint {
          margin-left: auto;
        }
      }
      .life-use {
        margin-left: auto;
        margin-top: 40px;
        margin-right: 16px;
        width: 108px;
        height: 35px;
        .use,
        .amount {
          color: #a52b40;
        }
        &.life-use-amount {
          background-image: none;
          .use {
            flex: inherit;
            &:before {
              content: "/";
            }
          }
          .amount {
            width: auto;
            line-height: unset;
            height: auto;
          }
        }
      }
    }
    .benefit-title.life_privileges-img {
      background-size: 100%;
    }
  }

  .member-benefits-qmwallet1,
  .member-benefits-qmqb1 {
    .parnter-title {
      color: #ffe6ef;
    }
    .parnter-subtitle {
      color: #98888f;
    }
    .member-benefit-partner_privileges {
      background: url("../../assets/images/qmwallet1-parnter_bg.png") no-repeat
        center/contain;
    }
    .list-inner {
      background: url("../../assets/images/qmwallet1-benefit-item-bg.png")
        no-repeat;
    }
    .life-use {
      background: url("../../assets/images/qmwallet1-life-btn-bg.png") no-repeat
        center/100% !important;
    }
    .benefit-title.life_privileges-img {
      background-image: url("../../assets/images/qmwallet1-life_privileges_bg.png");
    }
  }
  .member-benefits-qmqb1 {
    .benefit-title.life_privileges-img {
      background-image: url("../../assets/images/qmb1-life_privileges_bg.png");
    }
  }
  .member-benefits-qmwallet2 {
    .parnter-title {
      color: #fff3eb;
    }
    .parnter-subtitle {
      color: #8c8681;
    }
    .member-benefit-partner_privileges {
      height: 475px;
      background: url("../../assets/images/qmwallet2-parnter_bg.png") no-repeat
        center/contain;
    }
    .list-inner {
      background: url("../../assets/images/qmwallet2-benefit-item-bg.png")
        no-repeat;
    }
    .life-use {
      background: url("../../assets/images/qmwallet2-life-btn-bg.png") no-repeat
        center/100% !important;
    }
    .benefit-title.life_privileges-img {
      background-image: url("../../assets/images/qmwallet2-life_privileges_bg.png");
    }
    .list-inner .life-list-item .life-title {
      color: #fff4e0;
    }
  }

  .life-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    .life-list-item {
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .list-inner {
      width: 50%;
      padding: 0 5px;
      box-sizing: border-box;
    }
    .life-list-image {
      width: 100%;
      height: 120px;
      display: block;
    }
    .life-title {
      line-height: 20px;
      color: #333;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 15px;
      font-size: 14px;
    }
    .life-use {
      font-size: 13px;
      display: flex;
      align-items: center;
      height: 28px;
      width: 108px;
      border-radius: 27px;
      overflow: hidden;
      justify-content: center;
      background-color: #1a1a1a;
      &.life-use-amount {
        background: linear-gradient(115deg, #ffefd7 50%, #1a1a1a 50%);
      }
      &.life-use-disabled {
        background-color: #999;
      }
    }
    .amount {
      height: 100%;
      text-align: center;
      width: 50%;
      line-height: 28px;
      position: relative;
      z-index: 2000;
    }
    .use {
      color: #ffefd7;
      flex: 1;
      text-align: center;
    }
  }

  .qm-member-qmwallet1,
  .qm-member-qmqb1,
  .qm-member-qmwallet2 {
    .footer-item {
      flex: auto;
      height: 100%;
      padding-left: 0;
      font-size: 0;
    }
    .member-footer {
      padding: 0;
      height: 77px;
      position: relative;
      .footer-service {
        width: 70%;
        background: url("../../assets/images/qmwallet1-footer-service.jpg")
          no-repeat left/contain;
      }
    }
    .footer-help {
      position: absolute;
      right: 0;
      top: 0;
      width: 70%;
      background: url("../../assets/images/qmwallet1-footer-help.png") no-repeat
        right/contain !important;
    }
  }
  .qm-member-qmwallet1,
  .qm-member-qmqb1 {
    .member-footer {
      .footer-service {
        background: url("../../assets/images/qmwallet1-footer-service.jpg")
          no-repeat left/contain;
      }
    }
    .footer-help {
      background: url("../../assets/images/qmwallet1-footer-help.png") no-repeat
        right/contain !important;
    }
  }
  .qm-member-qmwallet2 {
    .member-footer {
      .footer-service {
        background: url("../../assets/images/qmwallet2-footer-service.jpg")
          no-repeat left/contain;
      }
    }
    .footer-help {
      background: url("../../assets/images/qmwallet2-footer-help.png") no-repeat
        right/contain !important;
    }
  }

  .member-footer {
    display: flex;
    align-items: center;
    height: 66px;
    background-color: #fff;
    padding: 0 20px;
  }

  .footer-item {
    flex: 1;
    padding-left: 42px;
    background-position: left center;
    background-size: 32px;
    background-repeat: no-repeat;
    font-size: 13px;
    line-height: 30px;
    &.footer-service {
      background-image: url(../../assets/images/qm_help.png);
    }
    &.footer-help {
      background-image: url(../../assets/images/qm_service.png);
    }
  }

  .to-order-btn {
    position: fixed;
    width: 20px;
    padding: 5px 4px;
    right: 0;
    top: 60%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 13px;
    text-align: center;
    z-index: 19;
    line-height: 1.2;
    border-radius: 4px 0px 0px 4px;
  }

  .qm-card-item {
    color: #222;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    border-radius: 3px;

    .carn-name {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 10px;
    }
    .card-exprie {
      line-height: 18px;
      font-size: 12px;
      padding-top: 90px;
      padding-left: 44px;
      color: #fff;
    }
  }

  .qm-card {
    .swiper-container {
      height: 100%;
    }
    .card-name {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
    }
    .swiper-slide {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .qmfqy06-card,
    .qm06-card,
    .wg106-card {
      background-image: url(../../assets/images/qm06-card-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .qm03-card {
      background-image: url(../../assets/images/qm03-card-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .ry06-card,
    .qmydh06-card {
      background-image: url(../../assets/images/ry06-card-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .zy06-card {
      background-image: url(../../assets/images/zy06-card-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .qmts06-card,
    .qmqb06-card {
      background-image: url(../../assets/images/qmts06-card-bg.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .qmte06-card {
      background-image: url(../../assets/images/qmte06-card-bg.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .wg06-card,
    .qm106-card {
      background-image: url(../../assets/images/wg-card-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      .card-exprie {
        color: #82423a;
      }
    }
  }

  .page-benefit-z5_privileges {
    border: 2px solid #f7e6b8;
    position: relative;
    padding: 50px 0 20px;
    background: rgb(251, 247, 250);
    border-radius: 5px;
    &::after {
      content: "吃喝玩乐全覆盖";
      line-height: 34px;
      text-align: center;
      width: 100%;
      left: 0;
      top: -2px;
      font-size: 16px;
      color: #572506;
      height: 34px;
      background: url("../../assets/images/qm-benefit-title-bg.png") no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
    }
    display: flex;
    flex-wrap: wrap;
    .page-benefit-spu {
      width: 47%;
      height: 103px;
      &:nth-child(2n) {
        width: 53%;
      }
    }
  }

  .quanmin_qmfqy06 {
    .page-benefit-z5_privileges {
      background: #fff;
    }
  }

  .qm-card .slide-prev {
  }

  .quanmin06-tag,
  .qmfqy06-tag,
  .quanmin06-ydh2,
  .weiguang-tag {
    width: 21px;
    border: 1px solid #ffe9cd;
    border-radius: 4px;
    line-height: 22px;
    font-size: 13px;
    text-align: center;
    color: #ffe9cd;
    margin-left: 15px;
    font-weight: bold;
  }

  .switch-card {
    position: fixed;
    height: 33px;
    line-height: 30px;
    top: 240px;
    right: 0;
    width: 106px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: #fff;
    font-size: 12px;
    z-index: 1000;
    background-size: contain;
    background-repeat: no-repeat;
    &.switch-card-quanmin03 {
      background-image: url(../../assets/images/switch_qm06.png);
    }
    &.switch-card-quanmin06 {
      background-image: url(../../assets/images/switch_qm03.png);
    }
    &.switch-card-qmfqy06 {
      background-image: url(../../assets/images/switch_qm03.png);
    }
    &.switch-card-weiguang {
      background-image: url(../../assets/images/switch_qm03.png);
    }
  }
  .switch-card-arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/images/arrow_right_white.png) no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-top: -2px;
  }
}

.quanmin-index .dialog {
  .dialog-content {
    padding: 0;
  }
  .dialog-title {
    display: none;
  }
  .cancel {
    background-color: rgb(240, 242, 248);
    border: none;
  }
  .leave-modal-header {
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .taro-img {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.entry-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
}

.dialog-ydh1,
.dialog-qmydh1 {
  width: 250px;
  .van-dialog__footer {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 14px;
    height: 42px;
    .van-dialog__cancel {
      width: 90px !important;
      background-image: url("../../assets/images/ydh1_quit_cancel.png") !important;
    }
    .van-dialog__confirm {
      width: 126px !important;
      background-image: url("../../assets/images/ydh1_quit_confirm.png") !important;
    }
  }
}
.dialog-ydh2 {
  width: 250px;
  .van-dialog__footer {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 14px;
    height: 42px;
    .van-dialog__cancel {
      width: 90px !important;
      background-image: url("../../assets/images/ydh2_quit_cancel.png") !important;
    }
    .van-dialog__confirm {
      width: 126px !important;
      background-image: url("../../assets/images/ydh2_quit_confirm.png") !important;
    }
  }
}
.dialog-qmwallet1,
.dialog-qmqb1 {
  width: 320px;
  .van-dialog__footer {
    margin: 0 30px;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 50px;
    height: 44px !important;

    .van-dialog__cancel {
      height: 44px !important;
      width: 50% !important;
      background: url("../../assets/images/qmwallet1_quit_cancel.png") no-repeat
        center/contain !important;
    }
    .van-dialog__confirm {
      width: 50% !important;
      height: 44px !important;
      background: url("../../assets/images/qmwallet1_quit_confirm.png")
        no-repeat center/contain !important;
    }
  }
}
.dialog-qmwallet2 {
  width: 320px !important;
  .van-dialog__footer {
    margin: 0 30px;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 50px;
    height: 50px;
    .van-dialog__cancel {
      width: 50% !important;
      height: 50px !important;
      background: url("../../assets/images/qmwallet2_quit_cancel.png") no-repeat
        center/cover !important;
    }
    .van-dialog__confirm {
      width: 50% !important;
      height: 50px !important;
      background: url("../../assets/images/qmwallet2_quit_confirm.jpg")
        no-repeat center/contain !important;
    }
  }
}
</style>