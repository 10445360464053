var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"height0"}},[(_vm.showAccount)?_c('div',{staticClass:"benefit-sku-input"},[_c('div',{staticClass:"benefit-input-label",style:(("pointer-events: " + (_vm.selectSku.allowed_recharge_account_types.length > 1
            ? 'initial'
            : 'none'))),on:{"click":function($event){_vm.showSelectRechargePopup = true}}},[_vm._v(" 充值"+_vm._s(_vm.topUpText)),(_vm.selectSku.allowed_recharge_account_types.length > 1)?_c('svg-icon',{staticClass:"benefit-input-label-arrow",attrs:{"iconClass":"arrow"}}):_c('span',[_vm._v("：")])],1),_c('van-field',{staticClass:"benefit-input",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"value":_vm.phone_account,"error-message":_vm.errorMessage,"type":_vm.input_type},on:{"input":_vm.changeAccount}}),(_vm.isp && _vm.isp.phone_number === _vm.account)?_c('div',{staticClass:"isp"},[_vm._v(" "+_vm._s(_vm.isp.isp)+" ")]):_vm._e()],1):_vm._e()]),(_vm.skuInfo.length > 1)?_c('div',{staticClass:"benefit-sku-list"},[_c('van-row',_vm._l((_vm.skuInfo),function(item,idx){return _c('van-col',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_detail_click_spu',
          spu_id: _vm.benefitInfo.type,
          spu_name: _vm.benefitInfo.name,
          sku_id: _vm.selectSku.id,
          sku_name: _vm.selectSku.item_name,
        }),expression:"{\n          event_id: 'benefit_detail_click_spu',\n          spu_id: benefitInfo.type,\n          spu_name: benefitInfo.name,\n          sku_id: selectSku.id,\n          sku_name: selectSku.item_name,\n        }"}],key:item.id,class:{
          'sku-item': true,
          'sku-flex': !!item.image,
          active: item.id === _vm.selectSku.id,
        },attrs:{"span":12},on:{"click":function($event){return _vm.skuChange(item, idx)}}},[_c('div',{class:{
            'sku-item-inner': true,
            'new-image': !!item.image,
          }},[_c('van-image',{staticClass:"sku-item-inner-image",attrs:{"lazy-load":"","src":item.image || _vm.getImage(item),"fit":"contain"}}),(item.image)?_c('div',{class:{
              'sku-item-name': true,
            }},[_vm._v(" "+_vm._s(item.platform_product_name)+" ")]):_vm._e(),(item.status === 0)?_c('div',{staticClass:"out-stock"},[_vm._v("无货")]):_vm._e()],1)])}),1)],1):_vm._e(),_c('select-popup',{attrs:{"title":"选择账号充值类型","value":_vm.selectSku.recharge_account_number,"options":_vm.selectSku.allowed_recharge_account_types,"optionFieldMap":{
      label: 'account_type_name',
      value: 'account_type_number',
    }},on:{"update:value":_vm.changeRechargeAccountNumber},model:{value:(_vm.showSelectRechargePopup),callback:function ($$v) {_vm.showSelectRechargePopup=$$v},expression:"showSelectRechargePopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }