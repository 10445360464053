var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('main',{staticClass:"pay"},[_c('div',[_c('div',{staticClass:"pay-sku-inner"},[_c('div',{staticClass:"price"},[_c('span',[_vm._v("¥")]),_c('span',[_vm._v(_vm._s(_vm.payInfo.price))])]),_c('div',{staticClass:"sku-name"},[_vm._v(_vm._s(_vm.payInfo.item_name))])])]),_c('div',{staticClass:"pay-methods"},[_c('p',{staticClass:"pay-text"},[_vm._v("支付方式")]),_c('div',{staticClass:"pay-wrap"},[_vm._l((_vm.payList),function(item){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'cashier_click_button2',
          payment_method_name: item.name,
          namespace: _vm.namespace,
        }),expression:"{\n          event_id: 'cashier_click_button2',\n          payment_method_name: item.name,\n          namespace,\n        }"}],key:item.channel,staticClass:"pay-item",on:{"click":function($event){_vm.selectedMethod = item}}},[_c('van-image',{staticClass:"pay-item-icon",attrs:{"src":item.icon}}),_c('div',{staticClass:"pay-detail"},[_c('div',[_c('div',{staticClass:"pay-name"},[_vm._v(" "+_vm._s(item.name)+" "),(item.channel === 'alipay_wap')?_c('span',{staticClass:"recommend"},[_vm._v("推荐")]):_vm._e()]),(item.tips)?_c('span',{staticClass:"tips"},[_vm._v(_vm._s(item.tips))]):_vm._e()]),_c('div',{staticClass:"pay-item-radio",class:_vm.selectedMethod.channel === item.channel && _vm.checkBoxIcon})])],1)}),(_vm.otherPayMethods.length)?_c('div',{staticClass:"more-payments",on:{"click":_vm.showAllPayments}},[_c('span',{staticClass:"more-payments-text"},[_vm._v("更多支付")]),_c('svg-icon',{staticClass:"down-icon",attrs:{"iconClass":"down"}})],1):_vm._e()],2)]),_c('div',{staticClass:"pay-fixed"},[_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'cashier_click_button1',
        channel: _vm.selectedMethod.channel,
        benefit_type: _vm.$route.query.type,
        item_code: _vm.$route.query.item_code,
      }),expression:"{\n        event_id: 'cashier_click_button1',\n        channel: selectedMethod.channel,\n        benefit_type: $route.query.type,\n        item_code: $route.query.item_code,\n      }"}],staticClass:"pay-button",class:_vm.payBtnColor,attrs:{"loading":_vm.paying,"block":""},on:{"click":_vm.pay}},[_vm._v("立即支付")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }