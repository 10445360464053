import Store from '@/store'
import { storage } from '.';
import get from "lodash.get";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import cookie from 'js-cookie';
import { setGlobalCookie } from '.';

export const generateFp = async () => {
  const fingerpringid = cookie.get('fingerpringid')
  if (fingerpringid) {
    return fingerpringid
  }
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  // 获取域名domain
  setGlobalCookie('fingerpringid', result.visitorId)
  return result.visitorId
}

export default {
  init: function (data) {
  },
  alias: function (data) {
    const { aplus_queue } = window;
    aplus_queue.push({
      action: "aplus.setMetaInfo",
      arguments: ["_user_id", data]
    });
  },
  profileSetOnce: function (data) {
  },
  pageView: function (name, props, callback) {
    try {
      let page_name = name;
      const namespace = storage.getItem('namespace');
      const source = storage.getItem('source');
      const { aplus_queue } = window;
      let options = {
        //必传参数
        ts: new Date().getTime(),
        path: location.href,
        page_name,
        namespace,
        source,
        ...props
      };
      Store.state?.userInfo?.id && (options["user_id"] = Store.state.userInfo.id);
      aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [{
          is_auto: false
        }, options]
      });
      if (namespace === 'keep') {
        const temp = {
          page_keep: 'page_sunny_view',
        }
        let keep_event = temp[page_name];
        if (keep_event) {
          window.KeepSensorsVpublic.track(keep_event, {
            from: "gogo",
          });
        }
      }
    } catch (error) {
      console.log('err：调用quick tracking统计页面失败')
    }
  },
  track: function (eventName, eventInfo = {}, callback) {
    let event_name = eventName;
    let event_type = 'CLK';
    const namespace = storage.getItem('namespace');
    const source = storage.getItem('source');

    try {
      const { aplus_queue } = window;
      let options = {
        namespace,
        source,
        ...eventInfo,
      };
      Store.state?.userInfo?.id && (options["user_id"] = Store.state.userInfo.id);
      aplus_queue.push({
        action: 'aplus.record',
        arguments: [event_name, event_type, options]
      });
      // keep 点击下单上报
      if (namespace === 'keep') {
        const temp = {
          vistor_click_middle_apply: 'page_sunny_click',
          vistor_click_bottom_apply: 'page_sunny_click',
        }
        let keep_event = temp[event_name];
        if (keep_event) {
          window.KeepSensorsVpublic.track(keep_event, {
            from: "gogo",
          });
        }
      }
    } catch (error) {
      console.log('err：调用quick tracking统计事件失败')
    }
  },
  profileSet: function (data) {
    const { aplus_queue } = window;
    aplus_queue.push({
      'action': 'aplus.record',
      'arguments': ['$$_user_profile', 'OTHER', {
        ...data
      }]
    });
  },
  ansView(route) {
    const { pageData, page, title } = route.meta;
    if (pageData) {
      let params = {
        source: storage.getItem('source'),
        namespace: storage.getItem('namespace')
      };
      for (const item of pageData) {
        if (typeof item === "object") {
          const [k, v] = Object.entries(item)[0];
          params[k] = get(this, v) || get(route.query, v);
        } else {
          params[item] = get(this, item) || get(route.query, item);
        }
      }
      this.pageView(page || title, params);
    }
  },
}


