import request from "@/utils/hotelRequest"

export const getBuffetStore = (params) => {
  return request({
    url: '/api/buffet/buffet_store',
    method: 'get',
    params,
  }).then(res => {
    res.data.data.forEach(item => {
      item.defaultTag = item.tag_list_info.find(r => r.name_en === "default")?.name;
      item.tag_list_info = item.tag_list_info.filter(r => r.name_en !== "default")
    })
    return res
  })
}
export const getBuffetList = (params) => {
  return request({
    url: '/api/buffet/buffet',
    method: 'get',
    params,
  })
}
export const getBuffetDetail = (id) => {
  return request({
    url: `/api/buffet/buffet/${id}`,
    method: 'get',
  })
}

export const getBuffetStoreDetail = (id) => {
  return request({
    url: `/api/buffet/buffet_store/${id}`,
    method: 'get',
  }).then(res => {
    res.data.defaultTag = res.data.tag_list_info.find(r => r.name_en === "default")?.name;
    res.data.tag_list_info = res.data.tag_list_info.filter(r => r.name_en !== "default")
    return res
  })
}

export const getBuffetCity = () => {
  return request({
    url: `/api/buffet/buffet_city/get_custom_city_info`,
    method: 'get',
  })
}