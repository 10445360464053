<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="hit-egg">
      <div class="hit-egg-content hit-egg-rotate">
        <div
          class="hit-egg-item"
          v-for="(item, index) in fudais"
          :key="index"
          @click="drawHandleThrottle(index)"
        >
          <img
            class="edd-item-image"
            :src="item.select && item.img2 ? item.img2 : item.img1"
          />
        </div>
      </div>
    </div>
    <div class="draw-btn-group">
      <div class="draw-start-btn" @click="drawHandleThrottle()">
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </div>
    </div>

    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";
import Dialog from "./Dialog";
import Vue from "vue";
const vm = Vue.prototype;
export default defineComponent({
  inheritAttrs: false,

  components: {
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const showDialog = ref(false);
    const fudais = ref(
      Array.from({ length: 3 }).map(() => ({
        img1: props.activityInfo.special_info.fudai_img1,
        img2: props.activityInfo.special_info.fudai_img2,
        select: false,
      }))
    );
    const drawHandle = async (index) => {
      fudais.value = fudais.value.map((item) => ({ ...item, select: false }));
      const res = await props.beforeDraw(true);
      if (!res) {
        return;
      }
      fudais.value[index ?? Math.floor(Math.random() * 3)].select = true;
      const miss_tips = props.activityInfo.special_info.miss_tips;
      if (res.status === 6 && miss_tips) {
        // 未中奖
        vm.$toast(miss_tips);
      } else {
        showDialog.value = true;
      }

      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });

    return {
      drawHandleThrottle,
      showDialog,
      fudais,
    };
  },
});
</script>

<style lang="less" scoped>
.hit-egg {
  height: 150px;
  perspective: 800px;
  position: relative;
  position: absolute;
  bottom: 194px;
}

.hit-egg-content {
  backface-visibility: visible;
  transform-style: preserve-3d;
  transform: rotateX(90deg);
  position: absolute;
  margin: 0 auto;
  width: 260px;
  height: 150px;
  animation: spinBox 10s linear infinite;
  top: 0;
  left: 60px;
  // transform: rotateX(90deg) rotate(0deg);
}

.hit-egg-item {
  transform-style: preserve-3d;
  position: absolute;
  backface-visibility: visible;
  &:first-of-type {
    margin-top: 20px;
    transform: rotate(90deg) translateX(30px) rotateX(-90deg) rotateY(90deg);
  }
  &:nth-of-type(2) {
    margin-left: 110px;
    margin-top: -72px;
    transform: rotate(210deg) translateX(30px) rotateX(-90deg) rotateY(210deg);
  }
  &:nth-of-type(3) {
    margin-left: 130px;
    margin-top: 60px;
    transform: rotate(330deg) translateX(30px) rotateX(-90deg) rotateY(330deg);
  }
  img {
    animation: spinEgg 10s linear infinite;
    transform-origin: center;
    width: 120px;
    height: 160px;
    display: block;
  }
}

@keyframes spinEgg {
  0% {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(1turn);
  }
}

@keyframes spinBox {
  0% {
    transform: rotateX(90deg) rotate(0deg);
  }

  to {
    transform: rotateX(90deg) rotate(1turn);
  }
}
</style>
