import store from '@/store'
import dayjs from 'dayjs'
import CryptoJS from "crypto-js";
import qs from 'qs'
import pickBy from 'lodash.pickby'
import cookie from "js-cookie";
import { isValidPhone } from './validate';

export const webviewH5Jump = () => {
  const ua = navigator.userAgent;
  const pathname = location.pathname;

  // 小程序进入时，强制跳转页面到随机目录下
  if (ua.indexOf('miniProgram') !== -1 && pathname === '/') {
    location.replace(`${location.origin}/${window.DEPLOY_DIRECTORY}${location.search}${location.hash}`)
  }
}

export const getUdeskUrl = (url, order_number, order_name) => {
  const umid = cookie.get('cna')
  const namespace = store.state.namespace
  const project_suffex = namespace === 'xy' ? 'XY-' : 'OAKVIP-'
  const uid = store.state.userInfo.unique_id || umid || Math.random().toString(36).substring(2, 15)
  const web_token = project_suffex + uid
  const customer_token = store.state.userInfo.id ? (project_suffex + store.state.userInfo.id) : web_token
  const timestamp = dayjs().valueOf()
  const nonce = Math.random().toString(36).substring(2, 15)
  const sign_str = `nonce=${nonce}&timestamp=${timestamp}&web_token=${web_token}&251c146cf77c1ecf5734d198e90b1799`
  const signature = CryptoJS.SHA1(sign_str).toString(CryptoJS.enc.Hex).toUpperCase()
  const desc = [namespace === 'xy' ? '[小赢项目]' : '[通用项目]']
  const phone = isValidPhone(store.state.userInfo.unique_id) ? store.state.userInfo.unique_id : store.state.userInfo.user_phone
  order_name && desc.push(`${order_name}`)
  order_number && desc.push(`订单号:${order_number}`)
  namespace && desc.push(`租户:${store.state.namespace}`)
  store.state.userInfo.id && desc.push(`用户ID:${store.state.userInfo.id}`)
  phone && desc.push(`手机号:${phone}`)
  store.state.source && desc.push(`来源:${store.state.source}`)
  store.state.namespaceInfo.name_cn && desc.push(`租户名称:${store.state.namespaceInfo.name_cn}`)
  umid && desc.push(`umid:${umid}`)
  const params = {
    nonce,
    timestamp,
    web_token,
    customer_token,
    signature,
    c_desc: desc.join(" "),
    c_tags: store.state.userInfo.tags?.map(r => r.tag_name).join(","),
    c_vip: store.state.userInfo.is_member ? 'vip' : 'normal',
    c_name: store.state.userInfo.id,
    c_phone: isValidPhone(store.state.userInfo.unique_id) ? store.state.userInfo.unique_id : store.state.userInfo.user_phone,
  }
  const search = qs.stringify(pickBy(params, (v) => !!v))
  return url + '&' + search
}
