<template>
  <div class="order">
    <div class="order-tab">
      <van-tabs v-model="active">
        <van-tab title="全部">
          <template #title>
            <span
              v-ans="{
                event_id: 'order_list_click_tab',
                tab_name: '全部',
              }"
              >全部</span
            >
          </template>
        </van-tab>
        <van-tab title="已完成">
          <template #title>
            <span
              v-ans="{
                event_id: 'order_list_click_tab',
                tab_name: '已完成',
              }"
              >已完成</span
            >
          </template>
        </van-tab>
        <van-tab title="已取消">
          <template #title>
            <span
              v-ans="{
                event_id: 'order_list_click_tab',
                tab_name: '已取消',
              }"
              >已取消</span
            >
          </template>
        </van-tab>
      </van-tabs>
    </div>
    <van-empty
      class="empty"
      v-if="empty"
      :image="require('@/assets/images/order_empty.svg')"
      description="暂无最近订单"
    >
    </van-empty>
    <div class="orders" v-else>
      <div class="order-item" v-for="item in list" :key="item.order_number">
        <div class="order-top">
          <div class="order-name ellipsis">
            {{ item.type === "hotel" ? "5-8折住酒店" : item.name }}
          </div>
          <div class="order-status">{{ getStateText(item) }}</div>
        </div>
        <van-divider
          :style="{ borderColor: '#F5F5F5', padding: '10px 0', margin: 0 }"
        />
        <div class="order-info" @click="toDetail(item)">
          <van-image
            class="order-image"
            lazy-load
            fit="contain"
            :src="getImageUrl(item)"
            :data-src="getImageUrl(item)"
          />
          <div class="order-content">
            <div class="order-desc">
              {{ item.type === "hotel" ? "预订" : ""
              }}{{ item.desc || item.name }}
            </div>
            <div class="order-price">
              <sub>¥</sub
              >{{
                item.type === "ssaw" || item.type === "healthcare"
                  ? "免费领取"
                  : item.amount
              }}
              <span class="order-time">{{
                item.t_created | formatDate("YYYY-MM-DD HH:mm:ss")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityOrder } from "@/apis";
import { getUserBenefit } from "@/apis/home";
import { mapGetters, mapState } from "vuex";

const activeEnum = {
  all: 0,
  success: 1,
  canceled: 2,
  0: "all",
  1: "success",
  2: "canceled",
};

export default {
  data() {
    return {
      list: null,
      empty: false,
      active: 0,
    };
  },

  created() {
    const { status } = this.$route.query;
    this.active = activeEnum[status] || 0;
  },

  async mounted() {
    const [benefitRes] = await Promise.all([
      getUserBenefit(),
      this.fetchLIst(),
    ]);
    this.benefitList = benefitRes.data.benefit_list || [];
  },

  computed: {
    ...mapGetters(["namespaceGroup"]),
    ...mapState(["userInfo"]),
  },

  methods: {
    async fetchLIst() {
      const loading = this.$loading();
      const statusEnum = {
        all: "",
        success: "success",
        canceled: "canceled",
      };
      const res = await getActivityOrder({
        status: statusEnum[activeEnum[this.active]],
      });
      const listData = res.data.map((item) => ({
        ...item,
        payInfo: JSON.parse(item.pay_info),
      }));
      this.empty = !res.data.length;
      this.list = listData;
      loading.clear();
    },
    getImageUrl(item) {
      if (item.payInfo?.image) {
        return item.payInfo?.image;
      }
      let iconname = item.payInfo.detail_type || item.type;
      if (item.type === "atour") {
        iconname = item.type;
      }
      return `https://cdn.xiangshuheika.com/static/${this.namespaceGroup}/${iconname}.png`;
    },
    getStateText(item) {
      const state_text = {
        success: "已成功",
        confirming: "已支付，确认中",
        unpaid: "待支付",
        canceled: "已取消",
        refund: "已退款",
        paid: "已支付",
      };
      if (!this.userInfo.is_member) {
        state_text.confirming = "待领取";
        state_text.canceled = "已失效";
      }
      return state_text[item.status];
    },
    toDetail(item) {
      // 从iframe内跳出
      if (window.self !== window.top) {
        top.location.href =
          location.origin +
          location.pathname +
          `#/orderDetail?order_number=${item.order_number}&type=${item.type}`;
      } else {
        this.$router.push(
          `/orderDetail?order_number=${item.order_number}&type=${item.type}`
        );
      }
    },
  },
  watch: {
    active() {
      this.fetchLIst();
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  background-color: #f8f8f8;
}

.order-tab {
  /deep/ .van-tabs__wrap {
    height: 40px;
  }
  /deep/ .van-tab__text {
    color: #333;
  }
  /deep/ .van-tab--active {
    .van-tab__text {
      color: #ff5001;
      font-weight: bold;
    }
  }
  /deep/ .van-tabs__line {
    background-color: #ff5001;
    width: 16px;
  }
}

.orders {
  padding: 12px 14px;
}
.order-name {
  color: #000;
  font-size: 13px;
  .arrow-icon {
    margin-left: -2px;
  }
}

.order-item {
  background: #fff;
  padding: 16px 16px 20px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.order-top {
  height: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.order-status {
  color: #999;
  flex-shrink: 0;
  font-size: 12px;
}
.order-info {
  display: flex;
}
.order-image {
  flex-basis: 50px;
  height: 50px;
  border: 1px solid #eaeaea;
  border-radius: 11px;
  flex-shrink: 0;
  margin-right: 12px;
  overflow: hidden;
}
.order-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .order-desc {
    line-height: 20px;
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .order-price {
    height: 25px;
    font-size: 18px;
    display: flex;
    align-items: center;
    sub {
      font-size: 10px;
      margin-right: 4px;
    }
    .order-time {
      margin-left: auto;
      color: #999;
      font-size: 11px;
      margin-top: 9px;
    }
  }
}
.empty {
  margin-top: 100px;
  /deep/ .van-empty__image {
    width: 70px;
    height: 63px;
  }
  .van-empty__description {
    margin-top: 22px;
  }
}
</style>