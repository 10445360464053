var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fetching)?_c('section',{staticClass:"keep"},[(_vm.$getConfigValue('INDEX_NOTICE'))?_c('van-notice-bar',{staticClass:"notice-bar",attrs:{"left-icon":"volume-o","mode":"closeable","text":_vm.$getConfigValue('INDEX_NOTICE')}}):_vm._e(),_c('section',{staticClass:"page-header"},[_c('div',{class:("page-header-inner " + (_vm.is_labeled && 'page-header-inner-label'))},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'member_switch_account_click' }),expression:"{ event_id: 'member_switch_account_click' }"}],staticClass:"logout-account",on:{"click":_vm.showLogout}},[_vm._v(" 切换账号 ")]),(!_vm.is_labeled)?_c('div',{staticClass:"page-header-desc"},[_vm._v(" 每天可解锁使用1项权益 ")]):_c('div',{staticClass:"page-header-desc-isLabeled"},[_c('p',[_vm._v("每天可解锁使用1项权益")]),_c('p',[_vm._v("视听特权、商超购物、咖啡特权需7天内领取")])]),_c('div',{staticClass:"page-header-expired"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.card.card_expiry_date,"YYYY-MM-DD"))+" 到期 ")]),_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'member_renew_click' }),expression:"{ event_id: 'member_renew_click' }"}],staticClass:"renew",attrs:{"round":""},on:{"click":_vm.renew}},[_vm._v("立即续费")])],1)]),_c('van-sticky',{staticClass:"tab-sticky",attrs:{"offset-bottom":-50}},[_c('ul',{staticClass:"keep-tab"},_vm._l((_vm.catesgorys),function(item,index){return _c('li',{key:item.desc,class:{ 'keep-tab-item': true, active: _vm.tabIndex === index },on:{"click":function($event){return _vm.scrollToFloor(index)}}},[_vm._v(" "+_vm._s(item.desc)+" ")])}),0)]),(_vm.card.card_status === 'active')?_c('section',{staticClass:"page-benefit"},[(_vm.keepActivity.prizeEntryImage && _vm.showActivityEntry)?_c('router-link',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'luckdraw_index_entry',
      }),expression:"{\n        event_id: 'luckdraw_index_entry',\n      }"}],class:['activity-entry', _vm.activityTimes === 0 ? 'no-times' : ''],style:(("background-image:url(" + (_vm.keepActivity.prizeEntryImage) + ")")),attrs:{"to":"/luckdraw"}},[_c('div',{staticClass:"activity-times"},[_vm._v("剩"+_vm._s(_vm.activityTimes)+"次")])]):_vm._e(),_vm._l((_vm.catesgorys),function(item){return _c('div',{key:item.name,class:("page-benefit-item page-benefit-" + (item.display_code))},[_c('div',{staticClass:"page-benefit-item-inner"},[_c('div',{staticClass:"benefit-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:("page-benefit-list-wrap page-benefit-" + (item.display_code))},[_c('div',{staticClass:"page-benefit-list"},_vm._l((item.list),function(spu,idx){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
                event_id: 'benefit_list_click',
                sequence: idx + 1,
                spu_id: spu.id,
                spu_name: spu.desc,
                benefit_type: spu.benefit.type,
                namespace: _vm.namespace,
              }),expression:"{\n                event_id: 'benefit_list_click',\n                sequence: idx + 1,\n                spu_id: spu.id,\n                spu_name: spu.desc,\n                benefit_type: spu.benefit.type,\n                namespace,\n              }"}],key:spu.product_code,staticClass:"page-benefit-spu"},[_c('div',{staticClass:"page-spu-inner"},[_c('van-image',{staticClass:"page-benefit-image",attrs:{"lazy-load":"","fit":"cover","src":spu.display_img_url}}),(item.display_code !== 'keepvip')?_c('div',{staticClass:"page-benefit-wrap"},[_c('div',{staticClass:"page-benefit-desc"},[_vm._v(" 剩"+_vm._s(spu.benefit.amount + spu.benefit.amount_reserved)+"次 ")]),_c('van-button',{class:[
                      'page-benefit-btn',
                      _vm.isDisabled(spu.benefit) ? 'disabled' : '' ],attrs:{"round":"","block":""},on:{"click":function($event){return _vm.jump(spu.benefit)}}},[_vm._v(" "+_vm._s(_vm.getBenefitText(spu.benefit))+" ")]),(
                      spu.benefit.get_start_time &&
                      spu.benefit.get_end_time &&
                      spu.benefit.amount <= 0 &&
                      spu.benefit.amount_reserved > 0
                    )?_c('div',{staticClass:"benefit-text"},[_vm._v(" "+_vm._s(_vm.nextDay( spu.benefit.get_start_time, spu.benefit.get_end_time ))+"可用 ")]):_c('div',{staticClass:"benefit-text",domProps:{"innerHTML":_vm._s(spu.desc)}}),(_vm.is_labeled)?_c('div',{staticClass:"benefit-selling-point"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e()],1):_c('div',{staticClass:"keep-click-el",on:{"click":function($event){return _vm.jump(spu.benefit)}}})],1)])}),0)])])])})],2):(_vm.card.card_status === 'expired')?_c('div',{staticClass:"card-past"},[_c('van-image',{attrs:{"src":require('../../assets/images/yh-empty.png')}}),_c('p',[_vm._v("您的权益已过期")])],1):(_vm.card.card_status === 'refund')?_c('div',{staticClass:"card-past"},[_c('van-image',{attrs:{"src":require('../../assets/images/yh-empty.png')}}),_c('p',[_vm._v("您的权益已退款")])],1):_vm._e(),_c('section',{staticClass:"page-footer"},[_c('router-link',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'member_to_order_click' }),expression:"{ event_id: 'member_to_order_click' }"}],staticClass:"order",attrs:{"to":"/order"}},[_c('svg-icon',{attrs:{"icon-class":"keep-order"}}),_vm._v("我的订单")],1),_c('router-link',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'member_to_service_click' }),expression:"{ event_id: 'member_to_service_click' }"}],staticClass:"service",attrs:{"to":"/service"}},[_c('svg-icon',{attrs:{"icon-class":"keep-service"}}),_vm._v("在线客服")],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }