<template>
  <div class="home" v-if="!fetching">
    <div class="header">
      <div class="benefit-expiry">
        有效期至 {{ card_info.card_expiry_date | formatDate("YYYY.MM.DD") }}
        <router-link
          class="service"
          v-ans="{ event_id: 'member_to_service_click' }"
          to="/service"
          >客服</router-link
        >
        <div class="line"></div>
        <router-link
          class="order"
          to="/order"
          v-ans="{ event_id: 'member_to_order_click' }"
          >领取记录 <van-icon class="arrow" name="arrow"
        /></router-link>
      </div>
    </div>
    <div class="benefit-body" v-if="card_info.card_status === 'active'">
      <div
        class="benefit-btn"
        @click="$router.push(`/benefitDetail?benefit_type=${benefit.type}`)"
      ></div>
    </div>
    <div v-if="card_info.card_status === 'expired'" class="card-past">
      您的权益已过期
    </div>
    <div v-if="card_info.card_status === 'refund'" class="card-past">
      您的权益已退款
    </div>
  </div>
</template>

<script>
import { getUserBenefit } from "@/apis/home";
import { mapMutations, mapState } from "vuex";

export default {
  name: "index",
  components: {},
  data() {
    return {
      is_prod: process.env.VUE_APP_RUNTIME_ENV === "production",
      card_info: {
        current_cycle_expired_date: "",
        card_expiry_date: "",
        card_status: "expired",
      },
      benefit: {},
      fetching: true,
    };
  },

  methods: {
    ...mapMutations(["changeState"]),
    async jump(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const res = await getUserBenefit();
      if (res.data.benefit_list.length > 0) {
        const benefit = res.data.benefit_list[0];
        this.benefit = benefit;
      }
      this.card_info = res.data;
    } finally {
      this.fetching = false;
      loading.clear();
    }
  },
  computed: {
    ...mapState([
      "namespace",
      "namespaceInfo",
      "userInfo",
      "benefit_source",
      "package_name",
    ]),
  },
};
</script>
<style scoped lang="less">
.home {
  min-height: 100vh;
  background: #594af2;
  padding: 16px 12px;
}

.header {
  height: 162px;
  background: url("~@/assets/images/lniao_header_bg.png") no-repeat top/100%;
  margin-bottom: 12px;
  padding-top: 103px;
  box-sizing: border-box;
  color: #fff;
  font-size: 11px;
  a {
    color: inherit;
  }
}

.benefit-expiry {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 11px;
  background: rgba(#fee7ef, 0.3);
  padding: 0 14px;
  margin: 0 8px;
}

.service {
  margin-left: auto;
}

.line {
  width: 1px;
  height: 10px;
  background: #fff;
  margin: 0 10px;
}
.arrow {
  vertical-align: -1px;
}

.benefit-body {
  height: 520px;
  background: url("~@/assets/images/lniao_benefit_bg.png") no-repeat top/100%;
  padding-top: 397px;
  box-sizing: border-box;
}

.benefit-btn {
  width: 218px;
  height: 52px;
  margin: 0 auto 10px;
  background: url("~@/assets/images/lniao_benefit_btn_bg.png") no-repeat
    top/100%;
}

.benefit-tips {
  color: #333;
  text-align: center;

  span {
    color: #ff5900;
  }
}

.card-past {
  text-align: center;
  height: 195px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding-top: 50px;
  box-sizing: border-box;
}
</style>