<template>
  <div>
    <van-notice-bar
      v-if="is_labeled"
      left-icon="volume-o"
      mode="closeable"
      class="notice-bar"
      :text="$getConfigValue('INDEX_NOTICE_LABEL')"
    />
    <div class="keep-unlogin" v-if="!spining">
      <div
        class="keep-header"
        :style="`background-image: url(${headerBackground})`"
      >
        <div
          class="to-home"
          v-ans="{ event_id: 'vistor_click_bought_top' }"
          @click="toHome"
        >
          点击使用权益
        </div>
        <div
          v-if="isLogin"
          class="logout-account"
          @click="showLogout"
          v-ans="{ event_id: 'switch_account_click' }"
        >
          切换账号
        </div>
        <!-- 加赠同程白金卡 -->
        <!-- <div class="header-count" v-if="!keepActivity.showSellCount">
        {{ sellText }}
        <van-count-down class="count-down" :time="countup">
          <template #default="timeData">
            <span class="block">{{ timeData.hours | zero }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes | zero }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds | zero }}</span>
          </template>
        </van-count-down>
      </div> -->
      </div>
      <van-image class="keep-package-desc" :src="packageDescImage"></van-image>
      <div class="keep-packages">
        <div
          v-for="(item, index) in packages"
          @click="currentPackageIndex = index"
          :class="{
            'keep-package-item': true,
            [`keep-package-${item.item_code}`]: true,
            active: currentPackageIndex === index,
          }"
          v-ans="{
            event_id: 'package_item_click',
            name: item.name,
            type: item.type,
            item_code: item.type,
          }"
          :key="index"
        >
          <div class="keep-package-inner">
            <div
              v-if="item.tag || $getConfigValue(`PACKAGE_TAG_${item.type}`)"
              class="pacakage-tag"
            >
              {{
                getSellText(
                  item,
                  item.tag || $getConfigValue(`PACKAGE_TAG_${item.type}`)
                )
              }}
            </div>
            <div class="keep-package-price">
              <div class="package-name">{{ item.name | splitName }}</div>
              <div class="package-amount">
                <span class="sub">¥ </span>
                <span class="price">{{ item.price }}</span>
              </div>
              <div class="package-savemoney">
                <span class="face-value">¥{{ item.face_value }}</span>
                <span class="save-money"
                  >立省{{ item.face_value | decimal(item.price, "minus") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="package-methods">
        <div
          v-for="item in filterMethods"
          :key="item.channel"
          @click="currentChannel = item"
          v-ans="{
            event_id: 'method_item_click',
            name: item.name,
            channel: item.channel,
          }"
          :class="{
            'method-item': true,
            actived: currentChannel === item,
          }"
        >
          <div class="method-inner">
            <van-image class="method-icon" :src="item.icon" />
            <div class="method-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div
        class="open-package fixed-used animate-run"
        v-ans="{
          event_id: 'vistor_click_middle_apply',
          package_name: currentPackage.name,
          package_type: currentPackage.type,
          channel: currentChannel.channel,
        }"
        @click="open(packages[currentPackageIndex])"
      >
        <div class="footer-price">
          <small>¥</small>{{ currentPackage.price }}
          <span class="footer-facevalue">¥{{ currentPackage.face_value }}</span>
        </div>
        <div class="footer-text">立即开通</div>
      </div>
      <div
        v-if="currentChannel.channel === alipayGoConfig.channel"
        class="renew-package-text"
      >
        {{ alipayGoConfig.desc }}
      </div>
      <div v-else-if="currentPackage.isRenew" class="renew-package-text">
        次{{ currentPackage.renewPackage.period_unit }}起按{{
          currentPackage.renewPackage.normal_price
        }}元/{{
          currentPackage.renewPackage.period_unit
        }}自动续费，可在支付宝中随时关闭。
        <span v-if="keepEntry">Keep 会员将直充到你当前在app登录的账户中</span>
      </div>
      <div
        class="keep-agreement"
        v-if="currentChannel.channel !== alipayGoConfig.channel"
      >
        <van-checkbox
          v-ans="{ event_id: 'vistor_click_agreement' }"
          v-model="checked"
          icon-size="16px"
          class="agreement-checked"
        >
          购买即代表接受<span @click="keepAgreement" class="link"
            >《Keep付费会员用户服务协议》</span
          >
          &amp;<span @click="oakAgreement" class="link">《橡树服务协议》</span>
        </van-checkbox>
      </div>
      <div class="keep-benefits">
        <div
          :class="['benefit-item', `benefit-item-${item.display_code}`]"
          v-for="item in catesgoryBenefit"
          :key="item.display_code"
        >
          <div
            class="benefit-top"
            v-if="item.display_code.startsWith('oakvip_benefit')"
          >
            <van-image
              lazy-load
              :src="item.display_img_url"
              class="benefit-top-left"
            ></van-image>
          </div>
          <ul :class="['benefit-spu', `benefit-spu-${item.display_code}`]">
            <li
              :class="['benefit-spu-item', `benefit-spu-${spu.product_code}`]"
              v-for="spu in item.list"
              :key="spu.id"
            >
              <div class="benefit-spu-inner">
                <div
                  class="benefit-spu-top-label"
                  :class="
                    (spu.display_code === 'train' ||
                      spu.display_code === 'hotel') &&
                    `benefit-spu-top`
                  "
                  v-if="item.display_code.startsWith('oakvip_benefit')"
                >
                  <span class="benefit-spu-name">{{ spu.desc }}</span>
                  <span v-if="is_labeled" class="benefit-spu-selling">{{
                    spu.selling_points
                  }}</span>
                  <a
                    v-if="spu.display_code === 'train'"
                    href="https://network.dragonpass.com.cn/airport/list?loungeType=lounge"
                    class="benefit-spu-right"
                    >使用城市 ></a
                  >
                  <router-link
                    v-else-if="spu.display_code === 'hotel'"
                    to="/hotel/list?benefitType=hotel"
                    class="benefit-spu-right"
                    >查看更多 ></router-link
                  >
                </div>
                <van-image
                  class="benefit-spu-img"
                  lazy-load
                  fit="cover"
                  :src="spu.display_img_url"
                />
              </div>
            </li>
          </ul>
        </div>
        <div
          v-ans="{ event_id: 'vistor_click_rule' }"
          :class="['fold-btn', isFold && 'isFold']"
          @click="isFold = !isFold"
        >
          GoGo 大会员活动规则
          <svg-icon class="arrow" iconClass="arrow2" />
        </div>
        <div :class="['keep-rule', isFold && 'isFold']">
          <div v-html="is_labeled ? ruleConfig : namespaceInfo.rule" />
        </div>
      </div>
      <div
        :style="`display: ${showButton ? 'block' : 'none'}`"
        @click="totop"
        class="totop"
      ></div>
      <div
        v-if="normalMember"
        @click="toHome"
        class="fixed-contain normal-member"
        v-ans="{ event_id: 'vistor_click_bought_bottom' }"
      >
        <div class="fixed-used">点击使用权益</div>
      </div>
      <div v-if="showButton && !normalMember" class="fixed-contain">
        <div class="footer-package-methods package-methods">
          <div
            v-for="item in filterMethods"
            :key="item.channel"
            @click="currentChannel = item.channel"
            v-ans="{
              event_id: 'method_bottom_click',
              name: item.name,
              channel: item.channel,
            }"
            :class="{
              'method-item': true,
              actived: currentChannel === item.channel,
            }"
          >
            <div class="method-inner">
              <van-image class="method-icon" :src="item.icon" />
              <div class="method-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div
          class="fixed-used animate-run"
          v-ans="{
            event_id: 'vistor_click_bottom_apply',
            package_name: currentPackage.name,
            package_type: currentPackage.type,
            channel: currentChannel.channel,
          }"
          @click="open(packages[currentPackageIndex])"
        >
          <div class="footer-price">
            <small>¥</small>{{ currentPackage.price }}
            <span class="footer-facevalue"
              >¥{{ currentPackage.face_value }}</span
            >
          </div>
          <div class="footer-text">立即开通</div>
        </div>
        <div class="footer-count" v-if="!keepActivity.showSellCount">
          {{ sellText }}
          <van-count-down class="count-down" :time="countup">
            <template #default="timeData">
              <span class="block">{{ timeData.hours | zero }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes | zero }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds | zero }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="height120"></div>
      <van-popup
        closeable
        position="bottom"
        round
        class="alipay-go-popup"
        @click-overlay="alipayPopup.show = false"
        @click-close-icon="alipayPopup.show = false"
        @close="alipayPopup.show = false"
        :value="alipayPopup.show"
      >
        <div class="title">{{ alipayGoConfig.title }}</div>
        <div class="content">
          <van-image class="image" :src="alipayGoConfig.descImage" lazy-load />
          <div class="tips">
            芝麻分>=550分用户可购买，按照支付宝支付设置顺序扣款。守约期内提前退出芝麻GO任务，则需将已享受的优惠退还（首月已优惠金额及后续每月已优惠金额的累计）。
          </div>
          <div class="package-methods">
            <div
              :class="{
                'method-item': true,
                actived: true,
              }"
            >
              <div class="method-inner">
                <van-image class="method-icon" :src="currentChannel.icon" />
                <div class="method-name">{{ currentChannel.name }}</div>
              </div>
            </div>
          </div>
          <div class="fixed-used animate-run" @click="alipayGoBuy">
            <div class="footer-price">
              <small>¥</small>{{ currentPackage.price }}
              <span class="footer-facevalue"
                >¥{{ currentPackage.face_value }}</span
              >
            </div>
            <div class="footer-text">立即开通</div>
          </div>
          <van-checkbox
            v-model="checked"
            icon-size="16px"
            class="agreement-checked"
          >
            购买即代表接受<span @click="keepAgreement" class="link"
              >《Keep付费会员用户服务协议》</span
            >
            &amp;<span @click="oakAgreement" class="link"
              >《橡树服务协议》</span
            >
          </van-checkbox>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import animateScrollTo from "animated-scroll-to";
import { NoticeBar } from "vant";

import { geetest_appid } from "@/config";
import { getNetworkType, jsonSafeParse, storage } from "@/utils";
import { queryFirstPeriod, queryLoopData, queryPeriodSku } from "@/apis/home";
import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
  getPaymethod,
} from "@/apis/product";
import ans from "@/utils/ans";
import loginKeepDialog from "@/components/loginKeepDialog";
import { toPayV2 } from "@/helper/pay";
import qs from "qs";
const baseDate = dayjs("2022-1-26");

export default {
  components: {
    [NoticeBar.name]: NoticeBar,
  },
  props: {},
  data() {
    return {
      isFold: true,
      methods: [],
      checked: false,
      packages: [],
      loops: [],
      phone: "",
      verifycode: "",
      package_enum: {
        KE12: {
          face_real_value: 276,
          text: "放弃会员，一年将损失",
        },
        KE06: {
          face_real_value: 138,
          text: "放弃会员，半年将损失",
        },
        KE03: {
          face_real_value: 76,
          text: "放弃会员，三个月将损失",
        },
        KE01: {
          face_real_value: 29,
          text: "放弃会员，一个月将损失",
        },
      },
      session_code: "",
      count: 60,
      currentPackage: {},
      currentPackageIndex: 0,
      currentChannel: {},
      spining: true,
      showModal: false,
      showButton: false,
      usedNumber: 61 + dayjs().diff(baseDate, "day"),
      filterMethods: [],
      login_phone_number: storage.getItem("login_phone_number") || "",
      catesgoryList: [],
      alipayGoConfig: jsonSafeParse(this.$getConfigValue("ALIPAY_GO_3MONTH")),
      alipayPopup: {
        show: false,
      },
      keepEntry:
        !!this.$route.query.once || !!sessionStorage.getItem("keepEntry"),
      ruleConfig: "",
    };
  },
  filters: {
    splitName(str) {
      if (typeof str !== "string") {
        return str;
      }
      if (str.includes("·")) {
        return str.split("·")[1];
      }
      return str;
    },
  },
  computed: {
    ...mapGetters(["getConfigValue"]),
    ...mapState([
      "isWebchat",
      "source",
      "namespace",
      "namespaceInfo",
      "token",
      "userInfo",
      "showKeepLogin",
      "isMember",
      "userLabel",
    ]),
    is_labeled() {
      return (
        this.userLabel?.includes("keep新用户") ||
        this.userLabel?.includes("keep回购用户")
      );
    },
    // 获取header背景图
    headerBackground() {
      // 旧图 https://common-h5-dev.xiangshuheika.com/static/upload/20236272b6fc102708d4b608d918a07c2493dc8.png
      const category = this.catesgoryList.find(
        (item) => item.display_code === "header"
      );
      return category?.display_img_url;
    },
    // 获取分类
    catesgoryBenefit() {
      const copyList = JSON.parse(JSON.stringify(this.catesgoryList));
      copyList.forEach((item) => {
        if (item.display_code === "keep_oakvip") {
          item.list = item.list.filter(
            (r) => r.display_code === this.currentPackage.item_code
          );
        }
      });
      const category = copyList.filter((r) => {
        if (["banner", "header", "member_package"].includes(r.display_code)) {
          return false;
        }
        if (r.display_code.startsWith("oakvip_benefit")) {
          return (
            r.display_code == `oakvip_benefit_${this.currentPackage.item_code}`
          );
        }
        return true;
      });

      return category;
    },
    packageDescImage() {
      const category = this.catesgoryList.find(
        (item) => item.display_code === "member_package"
      );
      if (!category) {
        return "";
      }
      const item = category.list.find(
        (item) => item.display_code === this.currentPackage.item_code
      );
      if (
        this.currentPackage.name === "连续包季" ||
        this.currentPackage.item_code === "KE06"
      )
        return item.icon_img_url;
      return item?.display_img_url;
    },
    normalMember: {
      get() {
        return this.isMember;
      },
      set(isMember) {
        this.changeState({ isMember });
      },
    },
    countup() {
      return dayjs().endOf("day").diff(dayjs(), "second") * 1000;
    },
    sellText() {
      const item = this.packages[0];
      const t = this.$getConfigValue("SELL_TEXT");
      return this.getSellText(item, t);
    },
    isLogin() {
      return !!this.token;
    },
    getExtraPhone() {
      const phone_number = this.login_phone_number;
      return phone_number.slice(0, 3) + "****" + phone_number.slice(-4);
    },
    keepActivity() {
      const activity = this.$getConfigValue("KEEP_MEMBER_OPEN_ACTIVITY");
      if (!activity) {
        return {};
      }
      try {
        return JSON.parse(activity);
      } catch (e) {
        console.log(e);
        return {};
      }
    },
  },
  async mounted() {
    this.is_labeled &&
      ans.pageView("keep_grayscale_visitor", {
        namespace: this.namespace,
      });
    if (this.$route.query.once) {
      sessionStorage.setItem("keepEntry", 1);
    }
    document.addEventListener("scroll", this.onscroll, false);
    await this.fetchPackage();
    await this.fetchCategory();
    const nettype = getNetworkType();
    if (nettype === "wifi") {
      // 可选方案：
      // 1: 提示用户关闭wifi 进行体验
      // 2: 直接降级走其他验证形式
    } else {
      // stpe3: 初始化H5一键登录
      this.initOlinstance = this.initGol();
    }

    if (this.showKeepLogin) {
      this.changeState({ showKeepLogin: false });
      if (this.initOlinstance) {
        this.loading = this.$loading("加载中...", { forbidClick: false });
        ans.track("vistor_click_quick_login", { namespace: this.namespace });
        this.initOlinstance.gateway();
      } else {
        this.showLogin();
      }
    }
    this.ruleConfig = this.$getConfigValue("KEEP_RULE_CONFIG");
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["onePassLogin"]),
    getSellText(item, text) {
      if (!item || !text) {
        return "";
      }
      const discount = Math.ceil((item.price / item.face_value) * 10);
      return text.replace(/{discount}/g, discount);
    },
    showLogin() {
      loginKeepDialog
        .show({
          ns: this.namespace,
          agreement: ` 购买则视为同意<a
          href="https://mo.gotokeep.com/prime/vip/agreement"
          class="login-agreement-text"
          >《keep付费会员用户服务协议》</a
        >和<a
          href="https://cdn.xiangshuheika.com/h5/agreement/keep.html"
          class="login-agreement-text"
          >《橡树服务协议》</a
        >`,
        })
        .then((res) => {
          // 解决isMember信息不会立刻更新
          this.normalMember = res.is_member;
          this.login_phone_number = res.login_phone_number;
          ans.track("vistor_click_login_success", {
            namespace: this.namespace,
          });
          this.loginSuccess(res);
        });
      ans.track("vistor_click_verify_login", {
        namespace: this.namespace,
        event_type: "EXP",
      });
    },
    initGol() {
      let olinstance = new window.GOL({
        app_id: geetest_appid,
        app: "GoGo大会员",
        logo: "https://cdn.xiangshuheika.com/static/keep/onelogin-logo.png",
        product: "popup",
        logoWidth: "60px",
        buttonBackgroundActive: "#FF5001",
      });
      // step4: 成功失败回调
      olinstance
        .onTokenFail((e) => {
          this.loading && this.loading.clear();
          // 获取token失败 走降级处理 走短信或者其他登录方式 用户可根据业务做不同判断
          console.log("授权错误", e);
          // 30001表示关闭授权窗口
          if (e.code !== 30001) {
            this.initOlinstance = null;
          }
          this.showLogin();
        })
        .onTokenSuccess(async (e) => {
          console.log(e); // e：获取token成功后返回object包含： {token: '', phone: '', process_id: '', auth_code: ''//电信授权页面暂不支持以下授权页面自定义参数}
          const data = {
            app_id: geetest_appid,
            ...e, // 获取token成功后的数据 包含了 token, phoen ,process_id,auth_code(电信)
          };
          try {
            const userInfo = await this.onePassLogin({
              platform: "route",
              namespace: this.namespace,
              source: this.$route.query.source || "default",
              hideMsg: true,
              ...data,
            });
            this.login_phone_number = userInfo.login_phone_number;
            ans.track("vistor_click_quick_login_success", {
              namespace: this.namespace,
            });
            this.loading && this.loading.clear();
            this.$toast("登录成功");
            this.loginSuccess(userInfo);
          } catch (e) {
            console.log(e, "error");
            this.loading && this.loading.clear();
            this.$toast("登录失败，请使用手机验证码登录");
            setTimeout(() => {
              this.showLogin();
            }, 800);
          }
        });
      return olinstance;
    },
    keepAgreement() {
      location.href = "https://mo.gotokeep.com/prime/vip/agreement";
    },
    oakAgreement() {
      location.href = "https://cdn.xiangshuheika.com/h5/agreement/keep.html";
    },
    onscroll() {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 500) {
        if (!this.showButton) {
          this.showButton = true;
        }
      } else {
        if (this.showButton) {
          this.showButton = false;
        }
      }
    },

    async fetchCategory() {
      const catesgory = await getIndexCategory({
        namespace: this.namespace,
        opened_page: "visitor",
        source: this.source,
      });
      const catesgorys = catesgory.data;
      const productList = await getIndexClassList({
        title: catesgorys.map((r) => r.name).join(","),
        opened_page: "visitor",
        namespace: this.namespace,
      });
      catesgorys.forEach((item) => {
        item.list = productList.data[item.name];
      });
      this.catesgoryList = catesgorys;
    },

    async fetchPackage() {
      const loading = this.$loading();
      try {
        const [methods, res, loopdata] = await Promise.all([
          getPaymethod({
            namespace: this.namespace,
            product_type: this.namespace.toUpperCase(),
            source: "default",
            is_vip: "T",
          }),
          getPackageList({ namespace: this.namespace, source: "default" }),
          queryLoopData({ activity_type: "keep_visitor" }),
        ]);
        const packages = res.data;
        this.loops = loopdata.data.data_list;

        this.methods = [...methods];
        this.packages = packages;
        await this.fetchPeriod(methods, packages);
      } finally {
        this.spining = false;
        loading.clear();
      }
    },
    async fetchPeriod(methods, packages) {
      const renewPackages = await queryPeriodSku();
      if (this.alipayGoConfig.name) {
        renewPackages.data.push({
          item_code: this.alipayGoConfig.item_code,
          normal_price: this.alipayGoConfig.normal_price,
          first_price: this.alipayGoConfig.first_price,
          channel: this.alipayGoConfig.channel,
          name: this.alipayGoConfig.name,
          period_unit: this.alipayGoConfig.period_unit,
          tag: this.alipayGoConfig.tag,
          order: this.alipayGoConfig.order,
        });
      }
      // 过滤支付方式跟套餐
      const renews = renewPackages.data.filter(
        (r) =>
          methods.some((item) => r.channel === item.channel) &&
          packages.some((o) => o.item_code === r.item_code)
      );
      let firstPersigns = null;
      if (this.isLogin) {
        const res = await queryFirstPeriod({
          item_code_list: renews.map((r) => r.item_code).join("~"),
        });
        firstPersigns = res.data;
      }
      // 如果用户是首次签约，就使用first_price 否则按normal_price给用户续费
      if (firstPersigns) {
        renews.forEach((item) => {
          const firstPersign =
            firstPersigns.find((r) => r.item_code === item.item_code) || {};
          item.is_first_pay = firstPersign.is_first_pay;
          item.first_price = firstPersign.is_first_pay
            ? item.first_price
            : item.normal_price;
        });
      }

      let i = 0;
      while (i < packages.length) {
        const item = packages[i];
        const renew = renews.find((r) => r.item_code === item.item_code);
        if (renew) {
          packages.splice(i, 0, {
            ...item,
            ...renew,
            card_type_cn: item.name,
            renewPackage: { ...renew },
            price: renew.first_price,
            package_price: item.price,
            isRenew: true,
          });
          i++;
        }
        i++;
      }
      packages.sort((a, b) => a.order - b.order);
      this.packages = [...packages];
      this.currentPackage = this.packages[0];
    },

    showDialog() {
      this.showModal = true;
      ans.track("vistor_click_retain", {
        namespace: this.namespace,
        event_type: "EXP",
      });
    },

    // 退出登录
    showLogout() {
      if (this.keepEntry) {
        this.$alert({
          title: "温馨提示",
          confirmButtonText: "好的",
          message: "请在app-头像-设置-账号与安全-退出登录然后切换账号",
        });
      } else {
        this.$confirm({
          message: `<div class="logout-confirm">
            当前已登录账号
          </div>
          <div class="logout-confirm-phone">
            ${this.getExtraPhone}
          </div>`,
          className: "logout-confirm",
          confirmButtonText: "退出并重新登录",
          cancelButtonText: "取消",
        }).then(() => {
          storage.removeItem("token");
          storage.removeItem("login_phone_number");
          this.login_phone_number = "";
          this.normalMember = false;
          this.changeState({
            isMember: false,
            memberInfo: {},
            userInfo: {},
            token: "",
            ptoken: "",
          });
          if (this.initOlinstance) {
            this.loading = this.$loading("加载中...", { forbidClick: false });
            ans.track("vistor_click_quick_login", {
              namespace: this.namespace,
            });
            this.initOlinstance.gateway();
          } else {
            this.showLogin();
          }
        });
      }
    },
    toHome() {
      this.clickType = "tohome";
      if (!this.currentPackage.name) {
        this.currentPackage = this.packages[this.currentPackageIndex];
      }
      if (storage.getItem("token")) {
        if (this.normalMember) {
          this.$router.push("/index");
        } else {
          // 限时开通会员弹窗
          this.buy();
        }
      } else {
        // 显示登录弹窗
        if (this.keepEntry) {
          this.$alert({
            title: "提示",
            message: "当前页面已过期，请退出当前页面重新进入",
          });
          return;
        }
        if (this.initOlinstance) {
          this.loading = this.$loading("加载中...", { forbidClick: false });
          ans.track("vistor_click_quick_login", { namespace: this.namespace });
          this.initOlinstance.gateway();
        } else {
          this.showLogin();
        }
      }
    },

    async loginSuccess(userInfo) {
      if (userInfo.is_member) {
        this.$router.push("/index");
        return;
      }
      await this.fetchPeriod(this.methods, this.packages);
      if (this.normalMember) {
        return;
      }
      if (this.clickType === "open") {
        this.buy();
      } else if (this.clickType === "tohome") {
        this.toHome();
      }
    },

    totop() {
      animateScrollTo([null, 0], {
        elementToScroll: document.documentElement,
      });
      animateScrollTo([null, 0], {
        elementToScroll: document.body,
      });
    },

    open(item) {
      ans.track("vistor_click_pay", {
        item_code: this.currentPackage.item_code,
        channel: this.currentChannel.channel,
      });
      if (
        !this.checked &&
        this.currentChannel.channel !== this.alipayGoConfig.channel
      ) {
        this.$toast("请先勾选并同意协议");
        return;
      }
      this.clickType = "open";
      this.currentPackage = item;

      if (storage.getItem("token")) {
        // 限时开通会员弹窗
        this.buy();
      } else {
        if (this.keepEntry) {
          this.$alert({
            title: "提示",
            message: "当前页面已过期，请退出当前页面重新进入",
          });
          return;
        }
        // 显示登录弹窗
        if (this.initOlinstance) {
          this.loading = this.$loading("加载中...", { forbidClick: false });
          ans.track("vistor_click_quick_login", { namespace: this.namespace });
          this.initOlinstance.gateway();
        } else {
          this.showLogin();
        }
      }
    },

    alipayGoBuy() {
      if (!this.checked) {
        this.$toast("请先勾选并同意协议");
        return;
      }
      this.buy(true);
    },

    // isPopup 是否是弹窗按钮触发
    buy(isPopupClick) {
      // 非keep app 不允许支付
      if (this.isWebchat && this.source !== "default") {
        // 增加日志
        window.DATAFLUX_RUM && window.DATAFLUX_RUM.addError(this.token);
        this.$alert({
          title: "温馨提示",
          message: this.$getConfigValue("KEEP_APP_OUTER_OPEN_MEMBER"),
          confirmButtonText: "我知道了",
        });
        return;
      }
      if (!this.currentChannel.channel) {
        this.$toast("请选择支付方式");
        return;
      }
      if (
        this.currentChannel.channel === this.alipayGoConfig.channel &&
        !isPopupClick
      ) {
        this.alipayPopup.show = true;
        return;
      }

      const querysearch = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const params = {
        category_type: "vip",
        price: this.currentPackage.price * 100,
        path: encodeURIComponent("/payResult"),
      };
      if (Object.keys(querysearch).length) {
        params.utm_params = encodeURIComponent(JSON.stringify(querysearch));
      }
      const { currentPackage, currentChannel } = this;
      toPayV2(
        { ...currentPackage, name: currentPackage.orderName },
        currentPackage.isRenew ? "alipay_period" : currentChannel.channel,
        {
          order_source: encodeURIComponent("GoGo大会员"),
          activity: currentPackage.item_code,
          partner_jump_url: encodeURIComponent(
            window.location.origin +
              `/keepredirect.html?${qs.stringify(params)}`
          ),
          ...querysearch,
          price: currentPackage.price * 100,
          showSignResult: 1,
          partner_url: encodeURIComponent(location.href),
        }
      );
    },

    tohotel() {
      this.$router.push("/hotel/list?benefitType=hotel");
    },

    toviproomlink() {
      location.href =
        "http://network.dragonpass.com.cn/airport/list?agentId=14201&type=gt";
    },
  },
  beforeDestroy() {
    this.initOlinstance && this.initOlinstance.destory();
  },
  watch: {
    currentPackageIndex() {
      this.currentPackage = this.packages[this.currentPackageIndex];
    },
    currentPackage(val) {
      if (val.isRenew) {
        const methods = this.methods;
        this.filterMethods = methods.filter(
          (r) => r.channel === val.renewPackage.channel
        );
      } else {
        this.filterMethods = [...this.methods]
          .filter((item) => item.channel !== this.alipayGoConfig.channel)
          .filter((item) => item.channel !== this.alipayGoConfig.channel);
      }
    },
    filterMethods(val) {
      if (!val.some((r) => r.channel === this.currentChannel)) {
        this.currentChannel = val.length ? val[0] : {};
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice-bar {
  height: 24px;
  background: #ff4f00;
  font-size: 12px;
  color: #fff;
}
.keep-unlogin {
  position: relative;
  font-size: 14px;
  background: linear-gradient(#ff934b 0%, #fa5923 20%, #fa5923 100%);
  min-height: 100vh;
  /deep/ .van-image {
    [lazy="loading"] {
      max-height: 100px;
    }
  }
}
.keep-header {
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
}

.keep-package-desc {
  height: 110px;
  margin: 0 16px 3px;
}

.to-home {
  position: absolute;
  right: 0;
  top: 16px;
  font-size: 12px;
  color: #fff;
  background: #fa5923;
  border-radius: 5px 0px 0px 5px;
  line-height: 24px;
  opacity: 0.7;
  width: 90px;
  text-align: center;
  box-sizing: border-box;
}

.keep-packages {
  margin-left: 16px;
  height: 143px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}

.pacakage-tag {
  position: absolute;
  right: 0;
  top: 3px;
  height: 21px;
  line-height: 21px;
  background: #d8d8d8 linear-gradient(136deg, #fd6e07 0%, #f71b00 100%);
  border-radius: 0px 8px 0px 8px;
  color: #fff;
  padding: 0 4px;
  // font-size: 12px;
  font-size: 10px;
}
.keep-package-item {
  flex-shrink: 0;
  position: relative;
  padding-top: 12px;
  margin-right: 8px;
  height: 100%;
  box-sizing: border-box;
  flex-basis: 110px;
  &.active {
    background: url("../../assets/images/keep_package_active.png");
    background-size: 100%;
    background-repeat: no-repeat;
    .package-amount,
    .package-name {
      color: #ff5001;
    }
    .keep-package-inner {
      background: none;
    }
  }
}
.keep-package-inner {
  height: 132px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  padding: 20px 12px 16px;
}
.package-amount {
  text-align: center;
  margin-right: 4px;
}
.price {
  line-height: 45px;
  font-size: 32px;
}
.face-value {
  text-decoration: line-through;
}

.save-money {
  color: #ff5001;
}
.keep-package-button {
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  height: 26px;
  border: none;
  background: linear-gradient(136deg, #ff6a00 0%, #f71b00 100%);
  margin-top: 8px;
}
.package-name {
  font-size: 14px;
  line-height: 20px;
  color: #222;
  height: 20px;
  text-align: center;
  font-weight: bold;
}
.package-savemoney {
  font-size: 12px;
  display: flex;
  height: 20px;
  line-height: 20px;
  margin-top: 4px;
  justify-content: space-between;
  font-size: 10px;
}

.animate-run {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 26px;
    height: 100px;
    opacity: 1;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );
    position: absolute;
    left: 0;
    top: -20px;
    transform: rotate(30deg);
    animation: run 1s infinite;
    z-index: 100;
  }
}

.keep-agreement {
  font-size: 10px;
  padding: 0 16px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  .link {
    color: #fff000;
  }
}

.keep-benefits {
  padding: 0 16px;
}

.benefit-item {
  margin-bottom: 24px;
}

.benefit-spu-item {
  & + .benefit-spu-item {
    margin-top: 16px;
  }
}

.benefit-item-oakvip_benefit_KE01,
.benefit-item-oakvip_benefit_KE03,
.benefit-item-oakvip_benefit_KE06,
.benefit-item-oakvip_benefit_KE012 {
  .benefit-spu {
    background: #fff;
    padding: 16px;

    border-radius: 8px;
  }
}

.benefit-top-left {
  margin-bottom: 16px;
}

.benefit-spu-right {
  font-size: 12px;
  color: #666;
}

.benefit-spu-top-label {
  line-height: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  .benefit-spu-name {
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }
  .benefit-spu-selling {
    font-weight: 400;
    font-size: 11px;
    color: #5b5b5b;
    line-height: 16px;
    margin-left: 7px;
  }
}

.benefit-spu-top {
  line-height: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .benefit-spu-name {
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }
}

.auto-image {
  height: 17px;
  text-align: center;
  margin-bottom: 15px;
  .keep-benefit-image {
    height: 100%;
    img {
      width: auto;
    }
  }
}

.keep-rule {
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-size: 12px;
  line-height: 1.5;
  color: #ffe9d5;
  padding: 15px 12px;
  &.isFold {
    display: none;
  }
  /deep/ font {
    color: #ffe9d5 !important;
  }
}

.height120 {
  height: 220px;
}

.fold-btn {
  height: 40px;
  line-height: 40px;
  margin: 24px auto 8px;
  color: #ffe9d5;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  border: 1px solid #ffffff;
  .arrow {
    color: #ffe9d5;
    transform: rotate(-90deg);
    margin-left: 3px;
    font-size: 18px;
    background-size: 100%;
  }
  &.isFold {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

.totop {
  position: fixed;
  width: 50px;
  height: 50px;
  background: url(../../assets/images/totop.png) no-repeat;
  background-size: 100%;
  right: 10px;
  bottom: 40%;
  display: none;
}

.fixed-contain {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 12px 16px;
  background: #fff;

  border-radius: 8px 8px 0px 0px;
}
.normal-member {
  padding: 0;
  .fixed-used {
    height: 40px;
    line-height: 40px;
  }
  & + .height120 {
    height: 80px;
  }
}
.fixed-used {
  display: flex;
  align-items: center;
  text-align: center;
  height: 54px;
  border-radius: 27px;
  background: linear-gradient(136deg, #ff6a00 0%, #f71b00 100%);
  text-align: center;
  color: #fff;
  margin: 10px 16px;
  line-height: 54px;
  font-size: 16px;
  color: #fff;
  justify-content: center;
  &.open-package {
    background: linear-gradient(180deg, #ffffff 0%, #ffc28c 100%);
    .footer-price {
      color: #f82000;
    }
  }
}

.footer-price {
  flex: 3;
  font-size: 22px;
  letter-spacing: 1px;
  small {
    font-size: 14px;
    margin-right: 2px;
  }
}
.footer-facevalue {
  margin-left: 7px;
  font-size: 12px;
  letter-spacing: 0;
  text-decoration: line-through;
  opacity: 0.9;
}
.footer-text {
  flex: 2;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    width: 110%;
    height: 110%;
    position: absolute;
    left: 0;
    top: -10%;
    transform: skew(-20deg);
    background: #222;
    z-index: -1;
  }
}

.user-number {
  text-align: center;
  color: #999;
  font-size: 16px;
  width: fit-content;
  margin: 20px auto 10px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -20px;
    width: 15px;
    height: 1px;
    top: 12px;
    background: #999;
  }
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    right: -20px;
    width: 15px;
    height: 1px;
    background: #999;
  }
}

.auto-image.rule-title-image {
  height: 30px;
  margin-left: -15px;
}

.at-modal .at-modal__container {
  width: calc(90% - 25px);
}

.header-count {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 32px;
  top: 88px;
}

.count-down {
  display: inline-block;
  color: inherit;
  font-size: inherit;
}

.block {
  display: inline-block;
  width: 15px;
  text-align: center;
}

.logout-account {
  position: absolute;
  top: 16px;
  left: 0;
  height: 24px;
  width: 68px;
  text-indent: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 0 26px 26px 0;
  background-image: url("../../assets/images/logout.png");
  background-size: 8px;
  background-position: 5px center;
  background-repeat: no-repeat;
  font-size: 12px;
  line-height: 24px;
}

.open-member-prize {
  margin-bottom: 12px;
  /deep/ img {
    max-width: 100%;
    height: auto;
  }
}

.footer-count {
  position: absolute;
  right: 16px;
  bottom: 66px;
  z-index: 10;
  height: 22px;
  background: #fa5923;
  border-radius: 12px 12px 12px 0px;
  padding: 0 8px;
  text-align: center;
  color: #fff;
}

.package-methods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 13px;
  margin: 16px 0px 0;
  &.footer-package-methods {
    margin-bottom: 20px;
    .method-inner {
      border: 1px solid #999;
    }
    .actived {
      .method-inner {
        border-color: #f82000;
      }
    }
  }
}

.method-item {
  height: 28px;
  line-height: 28px;
  box-sizing: border-box;
  flex-basis: 50%;
  padding: 0 3px;
  margin-bottom: 6px;
  .method-inner {
    border-radius: 4px;
    background: #fff;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  &.actived {
    .method-inner {
      background: linear-gradient(180deg, #ffffff 0%, #ffe8d4 100%);
      border-radius: 4px;
    }
  }
  .method-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    display: block;
  }
  .method-name {
    font-size: 12px;
    color: #333;
  }
}

.renew-package-text {
  padding: 0 16px;
  color: #fff;
  font-size: 10px;
  line-height: 1.5;
  margin-top: 10px;
}

.alipay-go-popup {
  padding: 16px 16px 12px;
  box-sizing: border-box;
  .title {
    line-height: 23px;
    font-size: 16px;
    text-align: center;
    color: #333;
    margin-bottom: 17px;
  }
  .image {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
  }
  .tips {
    color: #999;
    font-size: 10px;
    line-height: 17px;
    margin-bottom: 14px;
  }
  .keep-agreement {
    color: #999;
    .link {
      color: #0091ff;
    }
  }
  .method-inner {
    border: 1px solid #f82000;
  }
}
.agreement-checked {
  /deep/ .van-checkbox__icon--checked {
    .van-icon-success {
      border: none;
      background: url("~@/assets/images/keep_checked-white.svg") no-repeat
        center/100%;
      &::before {
        content: none;
      }
    }
  }
  /deep/ .van-checkbox__label {
    font-size: 10px;
    color: #fff;
  }
}

.alipay-go-popup {
  /deep/ .van-checkbox__icon--checked {
    .van-icon-success {
      background: url("~@/assets/images/keep_checked.svg") no-repeat center/100%;
    }
  }
  /deep/ .van-checkbox__label {
    color: #999 !important;
  }
}
</style>