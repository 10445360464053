var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"aftermarket-select"},[_c('div',{staticClass:"aftermarket-card aftermarket-radio"},[_c('div',{staticClass:"aftermarket-select-title"},[_vm._v("售后类型")]),_c('van-radio-group',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('van-cell',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_aftersale_click_change',
          order_number: _vm.$route.query.order_number,
        }),expression:"{\n          event_id: 'benefit_aftersale_click_change',\n          order_number: $route.query.order_number,\n        }"}],attrs:{"clickable":"","label":"更新券码，您可正常使用"},on:{"click":function($event){_vm.type = 'update_coupon'}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('van-radio',{attrs:{"checked-color":"#00B25A","icon-size":16,"name":"update_coupon"}},[_vm._v("我要换券")])]},proxy:true}])}),_c('van-cell',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_aftersale_click_refund',
          order_number: _vm.$route.query.order_number,
        }),expression:"{\n          event_id: 'benefit_aftersale_click_refund',\n          order_number: $route.query.order_number,\n        }"}],attrs:{"clickable":"","label":"券码回收，商家退款"},on:{"click":function($event){_vm.type = 'refund'}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('van-radio',{attrs:{"checked-color":"#00B25A","icon-size":16,"name":"refund"}},[_vm._v("我要退款")])]},proxy:true}])})],1)],1),(_vm.order.instruction_img_url)?_c('div',{staticClass:"aftermarket-card radio"},[_c('div',{staticClass:"aftermarket-select-title"},[_vm._v("使用指南")]),_c('p',{staticClass:"tips"},[_vm._v("为提高审核通过率，请核实您是否按照如下流程使用权益")]),_c('img',{staticClass:"benefit-img",attrs:{"src":_vm.order.instruction_img_url}})]):_vm._e(),_c('section',{staticClass:"footer"},[_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'page_aftersale_list_submit1',
        order_number: _vm.$route.query.order_number,
      }),expression:"{\n        event_id: 'page_aftersale_list_submit1',\n        order_number: $route.query.order_number,\n      }"}],staticClass:"footer-btn",attrs:{"round":"","block":""},on:{"click":_vm.nextStep}},[_vm._v("仍然无法使用，下一步")]),_c('p',{staticClass:"foot-tips"},[_vm._v("在工作时间8:00-20:00内，预计10分钟内处理完成")]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'page_aftersale_service_click' }),expression:"{ event_id: 'page_aftersale_service_click' }"}],staticClass:"foot-sub-tips"},[_vm._v(" 此次售后仅适用于券码不能使用的情景"),_c('br'),_vm._v("其它问题点击这里进入"),_c('a',{staticClass:"link",on:{"click":_vm.toService}},[_vm._v("人工客服")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }