var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-popup',{class:[_vm.className, (_vm.namespace + "-login-dialog")],attrs:{"closeable":"","position":"bottom","round":"","value":_vm.value},on:{"click-overlay":_vm.close,"click-close-icon":_vm.close,"close":_vm.close}},[_c('div',{staticClass:"login-header"},[_c('div',{class:{ 'login-title': true }},[_vm._v(_vm._s(_vm.title || "登录"))]),(_vm.desc)?_c('div',{staticClass:"login-desc2"},[_vm._v(_vm._s(_vm.desc))]):_vm._e()]),_c('van-form',{staticClass:"login-form",attrs:{"show-error":false,"show-error-message":false},on:{"submit":_vm.beforeSubmit,"failed":_vm.onFailed}},[_c('div',{staticClass:"get-code"},[_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入手机号","rules":[
          {
            required: true,
            message: '请输入手机号',
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的11位手机号',
          } ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}})],1),_c('div',{staticClass:"get-code"},[_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入验证码","rules":[{ required: true, message: '请输入验证码' }]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}}),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'validate_code_click', from: _vm.from, type: 'login' }),expression:"{ event_id: 'validate_code_click', from, type: 'login' }"}],class:{ code: true, countDown: _vm.countDown > 0 },on:{"click":_vm.getVerifyCode}},[_vm._v(" "+_vm._s(_vm.countDown > 0 ? (_vm.countDown + "s") : "获取验证码")+" ")])],1),_c('div',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"form-submit"},[_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'login_btn_click', from: _vm.from }),expression:"{ event_id: 'login_btn_click', from }"}],attrs:{"round":"","size":"large","block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.btnText || "立即登录"))])],1),(_vm.agreement)?_c('div',{staticClass:"login-agreement",domProps:{"innerHTML":_vm._s(_vm.agreement)}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }