// 行为校验
import Vue from "vue";

import { geeTestUpload } from "@/apis/home";
import { jsonSafeParse } from "@/utils";
import ans from './ans'

const vm = Vue.prototype;

/**
 * type:
 * join_activity 参加活动
 * sec_kill 秒杀
 * buy_benefit 购买权益
 * member_login 登陆
 * activate_benefit 激活权益
 */

class Geetest {
  constructor({ type }) {
    this.captchaInstance = null;
    this.phone = undefined;
    this.type = type;
    this.hide = vm.$getConfigValue("GEETEST_HIDE");

    this.resolve = undefined;
    this.reject = undefined;

    const idConfig = jsonSafeParse(vm.$getConfigValue("GEETEST_ID_CONFIG"));
    this.captchaId = idConfig?.[this.type];
    this.need_verify_scene = vm.$$store.state.need_verify_scene || [];

    if (!this.captchaId) return;
    window.initGeetest4(
      {
        captchaId: this.captchaId,
        product: "bind",
        riskType: "match",
        hideSuccess: this.hide
      },
      captcha => {
        this.captchaInstance = captcha;
        captcha.onSuccess(async () => {
          try {
            const namespace = vm.$$store.state.namespace;
            const user_id = vm.$$store.state.userInfo.id;
            const verifyData = {
              ...captcha.getValidate(),
              captcha_id: undefined,
              scene: this.type
            };
            const params = user_id
              ? { ...verifyData, user_id }
              : {
                ...verifyData,
                namespace,
                phone_number: this.phone
              };
            await geeTestUpload(params);
            ans.track('show_geetest_success', {
              scene: this.type
            })
            captcha.reset();
            this.resolve();
          } catch (error) {
            console.log(error);
            captcha.reset();
            this.reject();
          }
        });
        captcha.onError(e => {
          console.log(e);
        });
      }
    );
  }

  startCheck({ phone }) {
    ans.track('show_geetest', {
      scene: this.type
    })
    return new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
      phone && (this.phone = phone);
      const showVerify = this.need_verify_scene?.includes(this.type);

      if (!showVerify) {
        resolve();
        return;
      }
      this.captchaInstance?.showCaptcha();
    });
  }

  destroy() {
    this.captchaInstance?.destroy();
  }
}

export default Geetest;
