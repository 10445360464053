<template>
  <div v-if="!loading">
    <div v-if="!activityList.length" class="activity-empty">
      <van-image
        class="empty-image"
        fit="cover"
        lazy-load
        :src="require('../../assets/images/activity/empty3.png')"
      />
      <div class="empty-text">
        <p>
          <span>{{ detail.activity_preface || "每周定期" }}</span> 秒杀活动开启
        </p>
        <p>请届时再来，你可先返回使用其它会员权益</p>
      </div>
    </div>
    <main v-else class="seckill">
      <div
        class="activity-bg"
        :style="`background-image: url(${activity.header_image_url})`"
      >
        <div class="activity-rule" @click="showRule">规则</div>
        <div class="activity-slogan">
          <div class="activity-slogan-name">{{ detail.activity_name }}</div>
          <div class="activity-slogan-tag"></div>
        </div>
        <div class="activity-product">
          <product-item
            tag="本场爆品"
            :statueClassEnum="statueClassEnum"
            :detail="detail"
            :namespace="namespace"
            :activityQueryName="activityQueryName"
            :activity="activity"
            :item="hotProduct"
            :toDetail="toDetail"
          />
        </div>
        <div class="activity-time">
          <div class="activity-count-down" v-if="activity.status !== 'end'">
            <div class="activity-count-title">{{ activityName }}</div>
            <van-count-down
              class="count-down"
              @finish="finish"
              :time="getCountTime"
            >
              <template #default="timeData">
                <template v-if="timeData.days > 0">
                  <span class="block">{{ timeData.days }}天</span>
                  <span class="colon">:</span>
                </template>
                <span class="block">{{ timeData.hours | zero }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes | zero }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds | zero }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
      <van-tabs v-model="active" swipeable animated :line-width="60">
        <van-tab v-for="activity in activityList" :key="activity.id">
          <template #title>
            <div class="activity-time">
              <div class="time">{{ time(activity.start_time) }}</div>
            </div>
            <div class="activity-status">
              <div class="tip" :class="statueClassEnum[activity.status].class">
                {{ statueClassEnum[activity.status].text }}
              </div>
              {{ statusEnum[activity.status].statusName }}
            </div>
          </template>
          <div class="products">
            <!-- 卡片 -->
            <product-item
              :activityQueryName="activityQueryName"
              :tag="detail.activity_name"
              :statueClassEnum="statueClassEnum"
              :detail="detail"
              :namespace="namespace"
              :activity="activity"
              :item="item"
              :toDetail="toDetail"
              v-for="(item, index) in normalProduct"
              :key="index"
            />
          </div>
        </van-tab>
      </van-tabs>
    </main>
    <div
      class="to-order-btn"
      v-ans="{
        event_id: 'benefit_detail_click_button2',
        namespace,
        source,
      }"
      @click="handleToOrder"
    ></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getSeckillInfo, querySeckillStock } from "@/apis/home";
import { ACTIVITY_ENUM } from "@/constants";
import { mapState } from "vuex";
import { storage } from "@/utils";
import Decimal from "decimal.js";
import productItem from "./product-item.vue";
import { getMasterToken } from "@/apis";
import { commonH5 } from "@/config";

export default {
  components: {
    productItem,
  },
  data() {
    return {
      active: 0,
      statusEnum: ACTIVITY_ENUM,
      activityList: [],
      loading: false,
      detail: {},
      statueClassEnum: ACTIVITY_ENUM,
    };
  },
  computed: {
    hotProduct() {
      return this.activity.sku_list.reduce((cheapest, current) => {
        return current.activity_price < cheapest.activity_price
          ? current
          : cheapest;
      });
    },
    normalProduct() {
      return this.activity.sku_list.filter(
        (item) => item.activity_price != this.hotProduct.activity_price
      );
    },
    // 获取倒计时
    ...mapState(["userInfo", "namespace", "source"]),
    getCountTime() {
      let time;
      if (this.activity.status === "start") {
        time = this.activityList[this.active].end_time;
      } else {
        time = this.activityList[this.active].start_time;
      }
      const cur = dayjs().valueOf();
      return dayjs(time).valueOf() - cur;
    },
    activity() {
      return this.activityList[this.active];
    },
    activityName() {
      return ACTIVITY_ENUM[this.activity.status].activityText;
    },
    activityQueryName() {
      const namespace = storage.getItem("namespace");
      // 判断新人秒杀活动
      if (this.$route.query.activitySource === "new_user") {
        return namespace + "_seckill_new_user";
      }
      return namespace + "_seckill";
    },

    getMinFontSize() {
      return function (item) {
        if (
          new Decimal(item.face_value).minus(item.activity_price).toString()
            .length >= 5
        )
          return "mini-font-size";
        return "";
      };
    },
  },
  async mounted() {
    this.loading = true;
    const loading = this.$loading();
    try {
      // 获取活动信息
      const res = await getSeckillInfo({
        activity_name: this.activityQueryName,
      });
      this.detail = res.data;
      // 只显示48h以内的活动
      this.activityList = res.data.session_info.slice(0, 3).map((item) => {
        item.status = this.getStatus(item.start_time, item.end_time);
        return item;
      });
      const idx = this.activityList.findIndex(
        (r) => r.status === "start" || r.status === "unstart"
      );
      this.active = idx > -1 ? idx : 0;
      if (
        this.activityList[this.active] &&
        this.activityList[this.active].status === "start"
      ) {
        await this.queryStock(this.active, true);
      }
      this.timer = setInterval(() => {
        this.changeStatus();
      }, 1000);
    } finally {
      this.loading = false;
      loading.clear();
    }
  },

  methods: {
    async handleToOrder() {
      if (this.userInfo.is_logic) {
        const res = await getMasterToken();
        const masterUrl = `${commonH5}/order/activity?user_token=${res.data.token}&namespace=${this.namespace}`;
        window.location.href = masterUrl;
        return;
      }
      this.$router.push("/order/activity");
    },
    formatDate(date) {
      return dayjs(date).locale("zh").calendar(null, {
        lastWeek: "MM.DD HH:mm",
        lastDay: "[昨天] HH:mm",
        sameDay: "HH:mm",
        nextDay: "[明天] HH:mm",
        nextWeek: "ddd HH:mm",
        sameElse: "MM.DD HH:mm",
      });
    },
    time(date) {
      return this.formatDate(date).split(" ")[1];
    },
    changeStatus() {
      this.activityList = this.activityList.map((item) => {
        item.status = this.getStatus(item.start_time, item.end_time);
        return item;
      });
    },

    getStatus(start_time, end_time) {
      let status;
      if (dayjs().isBefore(dayjs(start_time))) {
        status = "unstart";
      } else if (dayjs().isAfter(dayjs(end_time))) {
        status = "end";
      } else {
        status = "start";
      }
      return status;
    },
    finish() {
      if (this.getCountTime < 0) {
        return;
      }
      this.changeStatus();
    },
    showRule() {
      this.$alert({
        title: "活动规则",
        message: this.detail.rules,
        confirmButtonText: "我知道了",
        className: "seckill-modal-rule",
      });
    },
    // 查询库存
    async queryStock(active, isLoop) {
      clearTimeout(this.stockTimer);
      const item_codes = this.activity.sku_list
        .map((r) => r.item_code)
        .join("~");
      if (!item_codes || !item_codes.length) {
        return;
      }
      const res = await querySeckillStock({
        session_id: this.activity.start_time,
        activity_name: this.activityQueryName,
      });
      // 处理item_code只有一个导致后端返回数据格式不一致的问题
      let stockList = Object.entries(res.data).map((item) => ({
        item_code: item[0],
        data: { ...item[1], item_code: item[0] },
      }));
      if (active !== this.active) {
        return;
      }
      if (this.activity.status === "start") {
        this.activity.sku_list.forEach((item) => {
          item.stock =
            (stockList.find((r) => r.item_code === item.item_code) || {})
              .data || {};
        });
        if (isLoop) {
          this.stockTimer = setTimeout(() => {
            this.queryStock(active, isLoop);
          }, 4000);
        }
      }
    },
    async toDetail(item, startTime, session_name) {
      this.$router.push(
        `/mdetail?item_code=${item.item_code}&startTime=${startTime}&activityName=${this.activityQueryName}&session_id=${session_name}`
      );
    },
  },

  watch: {
    active(val) {
      const activity = this.activityList[val];
      clearTimeout(this.stockTimer);
      if (activity.status === "start") {
        // 轮询库存
        this.queryStock(val, true);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.stockTimer);
  },
};
</script>

<style lang="less" scoped>
.mini-font-size {
  font-size: 12px !important;
}
.seckill {
  background: #f8f8f8;
  /deep/ .van-tab__text--ellipsis {
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 20px;
    margin-top: 15px;
  }

  /deep/ .van-tabs {
    margin-top: -54px;
  }
  /deep/ .van-tabs__nav--line {
    padding-bottom: 0;
  }
  /deep/ .van-tabs__nav {
    background: #e7e6e6;
    border-radius: 16px 16px 0 0;
  }
  /deep/ .van-tab--active {
    position: relative;
    color: #ff5001;
    &::after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 0px;
      right: -16px;
      width: 16px;
      height: 16px;
      background: radial-gradient(circle at 100% 0%, transparent 16px, #fff 0);
    }
    &:nth-child(2) {
      &::after {
        right: inherit;
        left: -16px;
        background: radial-gradient(circle at 0% 0%, transparent 16px, #fff 0);
      }
    }
    &::before {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      height: 61px;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, #fff 0%, #fff 16px, #ffd6d6);
      z-index: -1;
      border-radius: 16px 16px 0 0;
    }
  }
  /deep/ .van-tab {
    border-radius: 16px 16px 0 0 !important;
    z-index: 1;
    align-items: flex-start;
  }
}
.activity-bg {
  height: 301px;
  padding: 16px 12px 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
}
.activity-rule {
  position: absolute;
  right: 0;
  top: 15px;
  padding: 0 3px 0 9px;
  background: #fde0df;
  color: #fff;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  line-height: 16px;
  color: #8c3b18;
  border-radius: 9999px 0 0 9999px;
}

.activity-slogan {
  display: flex;
  margin-bottom: 16px;
  .activity-slogan-name {
    height: 19px;
    line-height: 19px;
    border-radius: 10px 0px 10px 0px;
    color: #f94612;
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    background: #fff;
    padding: 0 7px;
  }
  .activity-slogan-tag {
    width: 80px;
    height: 19px;
    background: url(~@/assets/images/seckill_tag.png) no-repeat;
    background-size: 100%;
  }
}
.activity-count-down {
  display: flex;
  align-items: center;
  padding: 15px 0 5px;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 21px;
  color: #fff;
  .block {
    padding: 1px 3px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    font-family: Arial, Arial;
    color: #fb302d;
    font-weight: bold;
  }
}

.activity-count-title {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  line-height: 22px;
  margin-right: 5px;
}
.count-down {
  border-radius: 9px;
  margin-left: 5px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
}
.colon {
  font-weight: bold;

  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  color: #fff;
  margin: 0 4px;
}

.products {
  margin: 16px 16px 20px;
}

.activity-bg-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.activity-time {
  line-height: 25px;
  font-size: 15px;
  > .day {
    height: 18px;
    background: #ff5001;
    border-radius: 4px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    margin-right: 6px;
  }
  > .time {
    font-size: 18px;
    font-weight: normal;
    line-height: 21px;
    margin-right: 27px;
    font-weight: bold;
  }
}

.activity-status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  .tip {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 17px;
    text-align: center;
    margin-right: 8px;
    &.start {
      background: linear-gradient(180deg, #ff8802 0%, #ff5001 100%);
    }
    &.unstart {
      background: linear-gradient(180deg, #8fc490 0%, #35924b 100%);
    }
    &.end {
      font-size: 8px;
      background: linear-gradient(180deg, #ffffff 0%, #afafaf 100%);
    }
  }
  &.disabled {
    background-color: #999999;
  }
}
/deep/ .van-tabs__wrap {
  height: 54px;
  overflow: inherit;
}
/deep/ .van-tabs__line {
  display: none;
  height: 2px;
  background-color: #ff5001;
}
</style>

<style lang="less">
.seckill-modal-rule {
  .van-dialog__content {
    padding-bottom: 20px;
  }
  .van-dialog__message {
    max-height: 180px;
    overflow: auto;
    text-align: left;
  }
}
.activity-empty {
  text-align: center;
}
.empty-image {
  width: 100px;
  margin: 123px auto 0;
}
.empty-text {
  font-size: 14px;
  color: #333;
  line-height: 28px;
  margin-top: 32px;
  span {
    color: #ff5001;
    font-weight: bold;
  }
}

.activity-product {
  .product-item {
    padding-top: 10px;
    padding-bottom: 13px;
  }
  .product-tag {
    height: 21px;
    line-height: 21px;
    background: linear-gradient(123deg, #fe7909 0%, #fb311c 100%);
  }
}

.to-order-btn {
  position: fixed;
  width: 36px;
  height: 40px;
  right: 0;
  top: 472px;
  z-index: 19;
  background: url("../../assets/images/benefit_order_bg.png") no-repeat
    center/100%;
}
</style>