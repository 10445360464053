<template>
  <section class="order">
    <div v-if="userInfo.is_logic" class="order-type-tab">
      <div
        @click="changeOrderType('member')"
        :class="[
          'order-type-item',
          orderType === 'member' && 'order-type-item-active',
        ]"
      >
        会员订单
      </div>
      <div
        @click="changeOrderType('activity')"
        :class="[
          'order-type-item',
          orderType === 'activity' && 'order-type-item-active',
        ]"
      >
        活动订单
      </div>
    </div>
    <template v-if="orderType === 'member'">
      <div class="order-tab">
        <van-tabs v-model="active">
          <van-tab title="全部">
            <template #title>
              <span
                v-ans="{
                  event_id: 'order_list_click_tab',
                  tab_name: '全部',
                }"
                >全部</span
              >
            </template>
          </van-tab>
          <van-tab title="已完成">
            <template #title>
              <span
                v-ans="{
                  event_id: 'order_list_click_tab',
                  tab_name: '已完成',
                }"
                >已完成</span
              >
            </template>
          </van-tab>
          <van-tab title="已取消">
            <template #title>
              <span
                v-ans="{
                  event_id: 'order_list_click_tab',
                  tab_name: '已取消',
                }"
                >已取消</span
              >
            </template>
          </van-tab>
        </van-tabs>
      </div>

      <div v-if="list">
        <div class="orders" v-if="list.length">
          <div class="order-item" v-for="item in list" :key="item.order_number">
            <div class="order-top">
              <div class="order-name ellipsis">
                {{ item.type === "hotel" ? "5-8折住酒店" : item.name }}
              </div>
              <div class="order-status">{{ getStateText(item) }}</div>
            </div>
            <van-divider
              :style="{ borderColor: '#F5F5F5', padding: '10px 0', margin: 0 }"
            />
            <div
              class="order-info"
              @click="toDetail(item)"
              v-ans="{ event_id: 'order_list_jump_detail_click' }"
            >
              <van-image
                class="order-image"
                lazy-load
                fit="contain"
                :src="getImageUrl(item)"
                :data-src="getImageUrl(item)"
              />
              <div class="order-content">
                <div class="order-desc">
                  {{ item.type === "hotel" ? "预订" : ""
                  }}{{ item.desc || item.name }}
                </div>
                <div class="order-price">
                  <sub>¥</sub
                  >{{
                    item.type === "ssaw" || item.type === "healthcare"
                      ? "免费领取"
                      : item.amount
                  }}
                  <span class="order-time">{{
                    item.t_created | formatDate("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        class="empty"
        v-if="empty"
        :image="require('@/assets/images/order_empty.svg')"
        description="暂无最近订单"
      >
        <div
          v-if="more === 'history'"
          class="more-text"
          @click="fetchMoreOrder"
        >
          点击查看历史订单<i class="down" />
        </div>
      </van-empty>
      <template v-else>
        <div
          v-if="more === 'history'"
          class="more-text"
          @click="fetchMoreOrder"
        >
          点击查看历史订单<i class="down" />
        </div>
        <div v-if="more === 'no'" class="more-text">没有更多了</div>
      </template>
      <div class="xsoak-btn-wrap" v-if="s_t">
        <div class="xsoak-btn" @click="toXsoak">查看省钱权益订单></div>
      </div>
      <div class="xsoak-height" v-if="s_t"></div>
    </template>
    <template v-else>
      <iframe class="activity-iframe" :src="masterUrl"></iframe>
    </template>
  </section>
</template>

<script>
import { getMasterToken, getOrder, hasMoreOrder } from "@/apis";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { commonH5, xsoak_domain } from "@/config";
import { getUserBenefit } from "@/apis/home";
import * as pickBy from "lodash.pickby";

const activeEnum = {
  all: 0,
  success: 1,
  canceled: 2,
  0: "all",
  1: "success",
  2: "canceled",
};

export default {
  data() {
    return {
      list: null,
      active: 0,
      benefitList: [],
      more: 0,
      empty: false,
      orderType: "member",
      masterUrl: "",
    };
  },
  computed: {
    ...mapGetters(["namespaceGroup"]),
    ...mapState(["s_t", "all_project", "userInfo", "namespace"]),
  },
  created() {
    const { status } = this.$route.query;
    this.active = activeEnum[status] || 0;
  },
  async mounted() {
    const [benefitRes] = await Promise.all([
      getUserBenefit(),
      this.fetchLIst(),
    ]);
    this.benefitList = benefitRes.data.benefit_list || [];
  },
  watch: {
    active() {
      this.more = "";
      this.fetchLIst();
    },
  },
  methods: {
    async changeOrderType(type) {
      if (type === "activity" && !this.masterUrl) {
        const res = await getMasterToken();
        this.masterUrl = `${commonH5}/order/activity?user_token=${res.data.token}&namespace=${this.namespace}`;
      }
      this.orderType = type;
    },
    fetchMoreOrder() {
      this.fetchLIst(true);
    },
    showBenefitEntry(type) {
      return this.benefitList.some((item) => item.type === type);
    },
    async fetchLIst(more) {
      const loading = this.$loading();
      const statusEnum = {
        all: "success,canceled,confirming,refund,paid",
        success: "success,confirming",
        canceled: "canceled",
      };
      // 桔多多酒店增加未支付订单
      if (this.$getConfigValue("HOTEL_SINGLE_BENEFIT")) {
        statusEnum.all += ",unpaid";
      }
      // 显示失败订单
      if (this.$getConfigValue("SHOW_FAILED_ORDER")) {
        statusEnum.all += ",failed";
      }

      let opts = pickBy(
        {
          source: "网络支付",
          confirmation: this.$route.query.order_confirmation, // 桔多多增加字段过滤
          status: statusEnum[activeEnum[this.active]],
        },
        (v) => !!v
      );
      if (this.$getConfigValue("SHOW_NEW_ORDER_LIST")) {
        Object.assign(opts, {
          created_day: more ? this.$getConfigValue("SHOW_ORDER_MAX_DAY") : 90,
          created_day_gt: more ? 90 : undefined,
          is_filter_expired_member_order: true,
        });
      }
      if (this.all_project) {
        opts.all_project = this.all_project;
      }
      if (this.namespaceGroup === "chongtian") {
        opts.source = "网络支付,api";
      }
      const res = await getOrder(opts);
      if (!more && this.$getConfigValue("SHOW_NEW_ORDER_LIST")) {
        const exisxRes = await hasMoreOrder({
          ...opts,
          created_day: this.$getConfigValue("SHOW_ORDER_MAX_DAY"),
          created_day_gt: 90,
        });
        this.more = exisxRes.exists ? "history" : "no";
      }
      const listData = res.data.res_list.map((item) => ({
        ...item,
        payInfo: JSON.parse(item.pay_info),
      }));
      if (!more) {
        this.list = listData;
        this.empty = !this.list.length;
      } else {
        this.more = "no";
        this.empty = false;
        this.list = this.list.concat(listData);
      }
      loading.clear();
    },
    getImageUrl(item) {
      if (item.payInfo?.image) {
        return item.payInfo?.image;
      }
      let iconname = item.payInfo.detail_type || item.type;
      if (item.type === "atour") {
        iconname = item.type;
      }
      return `https://cdn.xiangshuheika.com/static/${this.namespaceGroup}/${iconname}.png`;
    },
    getStateText(item) {
      const state_text = {
        success: "已成功",
        confirming: "已支付，确认中",
        unpaid: "待支付",
        canceled: "已取消",
        refund: "已退款",
        paid: "已支付",
        failed: "已失败",
      };
      if (!this.userInfo.is_member) {
        state_text.confirming = "待领取";
        state_text.canceled = "已失效";
      }
      return state_text[item.status];
    },
    toDetail(item) {
      // 从iframe内跳出
      if (window.self !== window.top) {
        top.location.href =
          location.origin +
          location.pathname +
          `#/orderDetail?order_number=${item.order_number}&type=${item.type}`;
      } else {
        this.$router.push(
          `/orderDetail?order_number=${item.order_number}&type=${item.type}`
        );
      }
    },
    toXsoak() {
      let url = `/order?user_token=${this.s_t}`;
      if (this.all_project) {
        url = url + `&all_project=${this.all_project}`;
      }
      window.location.href = `${xsoak_domain}${url}`;
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  background-color: #f8f8f8;
}
.activity-iframe {
  border: none;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 40px);
}
.order-type-tab {
  height: 40px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  font-size: 14px;
  .order-type-item {
    flex: 1;
    line-height: 40px;
    text-align: center;
    &.order-type-item-active {
      font-weight: bold;
      color: #ff5001;
      background-color: #fff;
      border-radius: 20px 20px 0 0;
    }
  }
}
.order-tab {
  /deep/ .van-tabs__wrap {
    height: 40px;
  }
  /deep/ .van-tab__text {
    color: #333;
  }
  /deep/ .van-tab--active {
    .van-tab__text {
      color: #ff5001;
      font-weight: bold;
    }
  }
  /deep/ .van-tabs__line {
    background-color: #ff5001;
    width: 16px;
  }
}

.orders {
  padding: 12px 14px;
}
.order-name {
  color: #000;
  font-size: 13px;
  .arrow-icon {
    margin-left: -2px;
  }
}

.order-item {
  background: #fff;
  padding: 16px 16px 20px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.order-top {
  height: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  flex: 1;
}

.order-status {
  color: #999;
  flex-shrink: 0;
  font-size: 12px;
}
.order-info {
  display: flex;
}
.order-image {
  flex-basis: 50px;
  height: 50px;
  border: 1px solid #eaeaea;
  border-radius: 11px;
  flex-shrink: 0;
  margin-right: 12px;
  overflow: hidden;
}
.order-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .order-desc {
    line-height: 20px;
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .order-price {
    height: 25px;
    font-size: 18px;
    display: flex;
    align-items: center;
    sub {
      font-size: 10px;
      margin-right: 4px;
    }
    .order-time {
      margin-left: auto;
      color: #999;
      font-size: 11px;
      margin-top: 9px;
    }
  }
}
.xsoak-btn-wrap {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 6px 15px;
}
.xsoak-btn {
  border: 1px solid #0091ff;
  border-radius: 4px;
  height: 49px;
  line-height: 49px;
  font-size: 16px;
  color: #0091ff;
}
.xsoak-height {
  height: 70px;
}

.more-text {
  line-height: 30px;
  margin: 8px auto 20px;
  color: #bbb;
  font-size: 12px;
  width: max-content;
  .down {
    display: inline-block;
    width: 8px;
    height: 6px;
    background: url("~@/assets/images/down.png") no-repeat center/100%;
    margin-left: 6px;
    vertical-align: 2px;
  }
}

.empty {
  margin-top: 100px;
  /deep/ .van-empty__image {
    width: 70px;
    height: 63px;
  }
  .van-empty__description {
    margin-top: 22px;
  }
}
</style>