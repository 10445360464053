<template>
  <div class="benefit-btn-wrap">
    <van-button
      v-for="(btn, index) in renderBtns"
      @click="btn.handler"
      :key="index"
      block
      type="default"
      v-bind="btn.params"
      :disabled="btn.disabled"
      v-ans="{
        free: isFreeBenefit,
        event_id: btn.event_id,
        benefit_type,
        spu_id: benefitInfo.type,
        spu_name: benefitInfo.name,
        recharge_account_type: selectSku.recharge_account_number,
        top_up_type: selectSku.top_up_type,
        item_code: selectSku.item_code,
      }"
      round
      >{{ btn.text }}</van-button
    >

    <atour v-if="showAtour" :refresh="refresh" :showAtour.sync="showAtour" />
    <airporttransfer
      :sku="selectSku"
      v-if="showAirport"
      :refresh="refresh"
      :showAirport.sync="showAirport"
    />
    <take-down-dialog ref="takedownRef" />
  </div>
</template>

<script>
import * as pickBy from "lodash.pickby";
import { activateBenefit, querySkuStock, updateUserPhone } from "@/apis/home";
import { viproom_list } from "@/config";
import throttle from "lodash.throttle";
import { mapActions, mapState } from "vuex";
import { jsonSafeParse, replaceDay, storage } from "@/utils";
import { queryH5JumpUrl } from "@/apis/product";
import { canAppoint, cleanTeethCanAppoint, getOrder } from "@/apis";
import TakeDownDialog from "@/components/TakeDownDialog";
import qs from "qs";
import Airporttransfer from "./airporttransfer";
import Atour from "./atour";
import ans from "@/utils/ans";

const hide_after_use = ["atour", "hotel", "ehotel", "buffet"];

export default {
  components: {
    TakeDownDialog,
    Airporttransfer,
    Atour,
  },
  props: {
    benefit_type: String,
    benefitInfo: Object,
    selectSku: Object,
    account: String,
    phone_account: String,
    faceValueStatus: Object,
    rechargeAccounts: Array,
    showAccount: Boolean,
    isp: Object,
    refresh: Function,
    disabled: Boolean,
    abnormal_account: Boolean,
    checkYioil: Function,
  },
  data() {
    return {
      showAtour: false,
      showAirport: false,
      akgbDisabled: false,
      cleanTeethDisabled: true,
      cleanTeethOrder: {},
    };
  },
  computed: {
    ...mapState([
      "namespace",
      "userInfo",
      "source",
      "namespaceInfo",
      "isMiniProgram",
      "benefit_source",
    ]),
    renderBtns() {
      return [
        {
          condition: !this.showCouponLinkBtn,
          event_id: "benefit_detail_click_button1",
          disabled: this.btnDisabled,
          handler: this.submit,
          text: this.getBtnText,
          params: {
            class: "benefit-btn",
          },
        },
        {
          condition: this.showCouponLinkBtn,
          event_id: "benefit_detail_click_button2",
          disabled: false,
          handler: this.jumpToCouponLink,
          text: "已领取，立即使用",
          params: {
            class: "benefit-btn",
          },
        },
        {
          condition: this.benefitInfo.type === "meituancashback",
          handler: () => this.$router.push("/cashBack"),
          text: "我的返现",
          disabled: false,
          event_id: "benefit_detail_click_button_cashback",
        },
        {
          condition: this.benefitInfo.type === "akgb",
          handler: () => this.$router.push("/akBook"),
          text: "立即使用",
          disabled: this.akgbDisabled,
          event_id: "benefit_detail_click_button3",
        },
        {
          condition: this.benefitInfo.type === "hljyk",
          handler: () =>
            this.$router.push(
              `/cleanTeeth?order_number=${this.cleanTeethOrder.order_number}`
            ),
          text: "立即使用",
          disabled: this.cleanTeethDisabled,
          event_id: "benefit_detail_click_button3",
        },
        {
          condition: !!this.benefitInfo.coupon_banner_url,
          handler: () =>
            this.$jumpUrl(this.benefitInfo.coupon_banner_url, "switchTab"),
          text: "查看领取记录",
          disabled: false,
          event_id: "benefit_detail_click_coupon",
        },
      ].filter((item) => item.condition);
    },
    // 账号充值类型为手机号的sku，自动填入权益手机号

    isFreeBenefit() {
      return this.selectSku.item_price === 0;
    },
    // 判断商品下架
    productOff() {
      // 判读是否话费,根据话费isp判断各个渠道是否下架
      // 如果查询到isp，则根据配置判断，没有的话就判断移动|联通|电信是否配置了下架
      const attributes = this.selectSku.attributes || {};
      if (
        attributes.is_phone === "1" &&
        this.isp?.phone_number === this.account
      ) {
        return this.faceValueStatus[this.selectSku.face_value] === 0;
      }
      // 库存状态上下架
      return this.selectSku.status === 0;
    },
    btnDisabled() {
      // 非会员，让点击按钮
      // 跳转h5的链接，不限制
      if (!this.userInfo.is_member || this.selectSku.top_up_type === 5) {
        return false;
      }
      // 禁用keep会员按钮
      if (this.benefit_type.includes("keepvip") && this.namespace === "keep") {
        return true;
      }

      // 没有点数，禁用按钮
      if (
        (this.benefitInfo.amount_available <= 0 &&
          !hide_after_use.includes(this.benefit_type)) ||
        this.benefitInfo.amount_reserved === undefined
      ) {
        return true;
      }
      return this.productOff;
    },
    showCouponLinkBtn() {
      const BENEFIT_DETAIL_PAGE_TO_USER_BENEFIT = this.$getConfigValue(
        "BENEFIT_DETAIL_PAGE_TO_USER_BENEFIT"
      );
      return (
        this.benefitInfo.amount_available <= 0 &&
        this.benefitInfo.amount_reserved <= 0 &&
        BENEFIT_DETAIL_PAGE_TO_USER_BENEFIT.includes(this.benefitInfo.type)
      );
    },
    // 按钮文案逻辑
    getBtnText() {
      const temp = jsonSafeParse(this.$getConfigValue("BENEFIT_SPECIAL_TEXT"));
      if (this.userInfo.is_member) {
        for (let k in temp) {
          if (this.benefitInfo.type?.startsWith(k) && temp[k]) {
            return temp[k];
          }
        }
        if (
          this.benefitInfo.amount_available <= 0 &&
          !hide_after_use.includes(this.benefit_type) &&
          this.benefitInfo.amount_reserved <= 0
        ) {
          return "本权益已领完";
        }
        // 月花卡显示文案  暂时写死，后期改成配置读取
        if (
          this.benefitInfo.amount_available <= 0 &&
          this.benefitInfo.amount_reserved > 0 &&
          !hide_after_use.includes(this.benefit_type)
        ) {
          //text_notify为本期权益使用完的字段
          if (this.benefitInfo.text_notify) {
            return this.benefitInfo.text_notify;
          }
          return "本期权益已使用，请下月再来";
        }

        if (this.productOff) {
          return "无货，请选择其他面额";
        }
        if (this.selectSku.item_price > 0) {
          return `立即购买（${this.selectSku.item_price}元）`;
        }
      }
      return "立即领取";
    },
  },
  async mounted() {
    if (this.benefit_type === "akgb") {
      //查询该用户是否可预约
      const res = await canAppoint();
      this.akgbDisabled = !res.data;
    } else if (this.benefit_type === "hljyk") {
      //查询该用户是否可预约洁牙
      const res = await cleanTeethCanAppoint({
        user_id: this.userInfo.id,
        namespace_name: this.namespace,
      });
      //查询全部洁牙订单，全部为已退款则不能预约
      const res2 = await getOrder({
        source: "网络支付",
        order_type: "hljyk",
      });
      this.cleanTeethOrder = res.data;
      if (res.data) {
        const hljyk =
          res2.data.res_list.filter(
            (r) => r.order_number === res.data.order_number
          )[0] || {};
        this.cleanTeethDisabled = hljyk.status !== "success";
      }
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    async jumpToCouponLink() {
      const loading = this.$loading();
      try {
        const orderList = await getOrder({
          status: "success",
          order_type: this.benefitInfo.type,
          category_type: "benefit",
        });
        const list = orderList.data.res_list;
        if (list.length) {
          const order = list[0];
          const pay_info = JSON.parse(order.pay_info);
          if (pay_info.coupon_format === "link") {
            location.href = pay_info.coupon_code;
            return;
          }
        }
        this.$jumpUrl("/pages/coupon/coupon");
      } finally {
        loading.clear();
      }
    },
    isYangmaodang() {
      const tag = this.userInfo.user_tags || [];
      return Object.keys(tag).some((item) => item.includes("yang_mao_dang"));
    },
    submit: throttle(
      async function () {
        if (!this.isYangmaodang() && !this.$refs.takedownRef.cantClose) {
          this.$refs.takedownRef.showTakedown();
          return;
        }
        document.body.scrollIntoView();
        if (!this.userInfo.is_member) {
          if (this.$getConfigValue("UN_INDEX")) {
            this.$alert({
              title: "温馨提示",
              message: "您还不是会员，开通会员即可享受特惠权益。",
              cancelButtonText: "我再想想",
              confirmButtonText: "去开通",
            }).then(() => {
              this.$jumpUrl(this.$getConfigValue("UN_INDEX"));
            });
          } else {
            this.$toast("您还不是会员，开通会员即可享受特惠权益。");
          }
          return;
        }
        const MALICIOUS_USERS = this.$getConfigValue("MALICIOUS_USERS") + "";
        if (MALICIOUS_USERS) {
          const users = MALICIOUS_USERS.split(",").filter(Boolean);
          if (users.includes(this.userInfo.id + "")) {
            this.$alert({
              title: "温馨提示",
              message: this.$getConfigValue("MALICIOUS_USER_ALERT_TEXT"),
            });
            return;
          }
        }
        if (
          this.benefitInfo.type === "hotel" ||
          this.benefitInfo.type === "ehotel"
        ) {
          this.$router.push(
            `/hotel/list?benefitType=${this.benefitInfo.type}&product_code=${this.selectSku.product_code}`
          );
          return;
        }
        if (this.benefitInfo.type === "buffet") {
          this.$router.push(
            `/buffet/list/?benefitType=${this.benefitInfo.type}`
          );
          return;
        }
        // 判断是否为团油或者易加油
        // 对加油链接进行处理
        if (this.benefitInfo.type === "yioil") {
          this.checkYioil();
          return;
        }
        // 校验点数
        if (this.showAccount && !this.account) {
          this.$toast("请输入充值账号");
          return;
        }

        // 校验输入的账号格式
        const rechargeConfig =
          this.selectSku.allowed_recharge_account_types.find(
            (item) =>
              item.account_type_number ===
              this.selectSku.recharge_account_number
          ) || {};
        if (rechargeConfig?.account_regex_rule) {
          if (
            !new RegExp(rechargeConfig?.account_regex_rule).test(this.account)
          ) {
            this.$toast(
              `您输入的${rechargeConfig.account_type_name}格式不正确，请核实后重新输入`
            );
            return;
          }
        }
        if (this.selectSku.need_real_name) {
          if (!this.userInfo.is_verified) {
            this.$alert({
              title: "温馨提示",
              message: "您还没有实名认证，请先认证",
              confirmButtonText: "去认证",
            }).then(() => {
              this.$router.push("/centification");
            });
            return;
          }
        }

        // 跳转到h5
        if (this.selectSku.top_up_type === 5) {
          const url =
            this.selectSku.activation_page ||
            this.selectSku.partner_jump_url ||
            "";
          if (!url) {
            const url = await queryH5JumpUrl({
              user_id: this.userInfo.id,
              generate_h5_code: this.selectSku.generate_h5,
              spu_id: this.selectSku.generate_h5_spu_id,
              phone_number: this.userInfo.user_phone,
            });
            location.href = url.data;
            return;
          }
          if (url.startsWith("/")) {
            this.$router.push(url);
          } else {
            location.href = url;
          }
          return;
        }

        // 判断是否亚朵
        if (this.benefitInfo.type === "atour") {
          this.showAtour = true;
          return;
        }
        // 判断是否接送机
        if (this.benefitInfo.type === "airporttransfer") {
          this.showAirport = true;
          return;
        }

        // 查询库存
        try {
          const stock = await querySkuStock({
            item_code: this.selectSku.item_code,
            user_id: this.userInfo.id,
            hideMsg: true,
          });
          if (
            stock.data.daily_stocks <= 0 ||
            stock.data.partner_stocks <= 0 ||
            stock.data.saleable_stocks <= 0 ||
            stock.data.user_daily_stocks <= 0 ||
            !stock.data.status
          ) {
            this.$toast("该商品暂时无货，请明天再来或咨询客服");
            return;
          }
        } catch (e) {
          console.log(e);
        }
        // 判断有没有可用的点数
        if (
          this.benefitInfo.amount_available <= 0 &&
          this.benefitInfo.amount_reserved
        ) {
          this.$alert({
            message: "您本月已用过此权益，请下个月再来",
            confirmButtonText: "好的",
          });
          return;
        }

        // 可用次数
        if (
          this.benefitInfo.amount_available <= 0 &&
          !this.benefitInfo.amount_reserved
        ) {
          this.$alert({
            message: "剩余可用次数不足，可前往订单查看已获取的权益或券码。",
            confirmButtonText: "去查看",
          }).then(() => {
            this.$router.push("/order");
          });
          return;
        }

        //需要购买的权益判断是否是非法权益
        if (
          this.selectSku.top_up_type === 1 ||
          this.selectSku.top_up_type === 3
        ) {
          const params = pickBy(
            {
              benefit_type: this.benefitInfo.type,
              benefit_source: this.benefit_source || "会员权益",
              is_zero_pay: true,
              order_name: this.selectSku.item_name,
              item_code: this.selectSku.item_code,
              top_up_type: this.selectSku.top_up_type,
              detail_type: viproom_list.includes(this.benefitInfo.type)
                ? undefined
                : this.selectSku.item_name,
              activation_platform: this.selectSku.activation_platform,
              phone_number: this.account,
              source: this.source,
              pre_activate: true,
            },
            (v) => v !== undefined
          );
          await activateBenefit(params);
        }
        //判断是否是异常账号
        if (this.abnormal_account) {
          await this.$alert({
            title: "温馨提示",
            message: `系统检测到手机号：<b>${this.phone_account}</b>可能为空号，是否继续？`,
            cancelButtonText: "我再想想",
            confirmButtonText: "确认号码",
          });
        }
        // if (this.selectSku.recharge_account_number === 2) {
        //   await this.$alert({
        //     title: "温馨提示",
        //     message: `确定充值到QQ号：<b>${this.phone_account}</b>吗？<br/>QQ号与手机号不互通，充值手机号权益无法到账，请确定是否继续？`,
        //     cancelButtonText: "我再想想",
        //     confirmButtonText: "确认号码",
        //   });
        // }
        if (this.selectSku.recharge_account_number === 6) {
          await this.$alert({
            title: "温馨提示",
            message: `确定充值到平台账号：<b>${this.phone_account}</b>吗？`,
            cancelButtonText: "我再想想",
            confirmButtonText: "确认号码",
          });
        }
        // 手机号充值增加额外提醒
        // 如果用户首次使用手机号充值，怎弹窗提醒用户，并且异步校验手机号是否能够充值
        if (
          !this.disabled &&
          this.showAccount &&
          this.namespaceInfo.enable_recharge_account_limit
        ) {
          if (this.selectSku.recharge_account_number !== 6) {
            await this.$alert({
              title: "温馨提示",
              confirmButtonText: "确认号码",
              message: `<div>确定充值到${rechargeConfig.account_type_name}：<b>${this.phone_account}</b>吗？<br />此${rechargeConfig.account_type_name}将会用于所有${rechargeConfig.account_type_name}充值的权益，填写后不可修改，请确认是否继续使用？</div>`,
            });
          }

          // 针对手机号充值类型，额外校验
          // 绑定手机号
          if (this.selectSku.recharge_account_number === 1) {
            const loading = this.$loading();
            try {
              await updateUserPhone({
                user_phone: this.account,
                hideMsg: true,
                is_save: "F",
              });
            } catch (e) {
              this.$toast(
                "手机号已被其它账号绑定，不可使用，如有疑问请联系客服"
              );
              return;
            } finally {
              loading.clear();
            }
          }
        }
        // 兑换码有激活提示
        if (
          this.selectSku.top_up_type === 3 ||
          this.selectSku.top_up_type === 4
        ) {
          const { activation_tips } = this.selectSku;
          await this.$alert({
            title: "温馨提示",
            message: replaceDay(activation_tips, this.benefitInfo.coupon_days),
            confirmButtonText: this.isFreeBenefit ? "继续领取" : "继续购买",
          });
        }
        this.toPay();
      },
      1500,
      { trailing: false }
    ),
    async activateBenefitHandle() {
      const params = pickBy(
        {
          benefit_type: this.benefitInfo.type,
          benefit_source: this.benefit_source || "会员权益",
          is_zero_pay: true,
          order_name: this.selectSku.item_name,
          item_code: this.selectSku.item_code,
          top_up_type: this.selectSku.top_up_type,
          detail_type: viproom_list.includes(this.benefitInfo.type)
            ? undefined
            : this.selectSku.item_name,
          activation_platform: this.selectSku.activation_platform,
          phone_number: this.account,
          source: this.source,
          recharge_account_type: this.selectSku.recharge_account_number || 0,
        },
        (v) => v !== undefined
      );
      // 华润通停车场激活参数
      if (params.benefit_type === "freeparking") {
        const CR_PARAMS = storage.getItem("CR_PARAMS");
        if (CR_PARAMS) {
          try {
            const obj = JSON.parse(CR_PARAMS);
            params.third_party_params = JSON.stringify({
              token: obj.token,
              device_param: obj.device,
            });
          } catch (e) {}
        }
      }
      const toast = this.$loading();
      try {
        const res = await activateBenefit(params);
        ans.track("benefit_activate_success", {
          benefit_type: this.benefitInfo.type,
          item_code: this.selectSku.item_code,
          recharge_account_type: params.recharge_account_number,
          top_up_type: params.top_up_type,
        });
        this.fetchUserInfo(), this.refresh();
        this.$alert({
          message: this.account
            ? `您已成功领取权益，充值至对应账号中，可在我的订单中查看结果`
            : "您已成功领取会员兑换码，<br/>请在我的订单中查看并激活使用",
          confirmButtonText: "去查看",
        }).then(() => {
          this.$router.push(
            `/orderDetail?order_number=${res.data.order_number}&from=activate`
          );
        });
      } finally {
        toast.clear();
      }
    },
    async toPay() {
      // top_up_type:
      // (1, "直冲-购买"),
      // (2, "直冲-免费领取"),
      // (3, "兑换码-购买"),
      // (4, "兑换码-免费领取"),
      // (5, "跳转H5")

      // 免费领取逻辑
      if (this.isFreeBenefit) {
        // 直冲虚拟权益激活提示
        if (this.account) {
          this.$alert({
            title: "温馨提示",
            message: `<div style="white-space:initial">
            <p style="font-size:16px">${this.selectSku.item_name},<br/>即将充值到您的账户，请确认</p>
            <p style="color:#999;padding-top:10px">虚拟商品，充值之后概不退换</p></div>`,
            confirmButtonText: "继续领取",
          }).then(() => {
            this.activateBenefitHandle();
          });
        } else {
          this.activateBenefitHandle();
        }
        return;
      }

      // 跳转收银台
      const params = pickBy(
        {
          source: this.source,
          item_code: this.selectSku.item_code,
          price: this.selectSku.item_price,
          item_name: decodeURIComponent(this.selectSku.item_name),
          sku_name: decodeURIComponent(this.selectSku.item_name),
          type: this.benefitInfo.type,
          recharge_account_type: this.selectSku.recharge_account_number || 0,
          phone_number: this.account,
          detail_type: viproom_list.includes(this.benefitInfo.type)
            ? undefined
            : decodeURIComponent(this.selectSku.item_name),
        },
        (v) => v !== undefined
      );
      this.$router.push(`/pay?${qs.stringify(params, { encode: false })}`);
    },
  },
};
</script>