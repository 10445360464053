import xiaopaiLayout from '@/layout/XiaopaiLayout';

export default {
  path: '/xiaopai',
  redirect: '/xiaopai/index',
  component: xiaopaiLayout,
  children: [{
    path: '/xiaopai/index',
    name: 'xiaopaiindex',
    component: require('@/views/xiaopai/index').default,
    meta: {
      title: '联合会员超值购',
      pageName: 'page_vistor_xiaopai',
      pageData: ['namespace'],
      page: 'page_visitor',
    }
  }, {
    path: '/xiaopai/activation',
    name: 'xiaopaiactivation',
    component: require('@/views/xiaopai/activation').default,
    meta: {
      title: '会员激活',
      pageName: 'page_active_xiaopai',
      pageData: ['namespace'],
      page: 'page_active_xiaopai',
    }
  }, {
    path: '/xiaopai/home',
    name: 'xiaopaihome',
    component: require('@/views/xiaopai/home').default,
    meta: {
      title: '联合会员超值购',
      pageName: 'page_member_xiaopai',
      pageData: ['namespace'],
      page: 'page_member',
    }
  }]
}