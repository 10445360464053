import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { getClientConfigList, getNamespaceInfo, getUserInfo, getUserLabel } from "@/apis";
import ans from "@/utils/ans";
import { storage } from "@/utils";
import hotel from "./hotel";
import offlineBenefit from "./offlineBenefit";
import buffet from "./buffet";
import business from "./business";
import pagj from "./pagj";
import { onepassLogin } from "@/apis/home";
import dayjs from "dayjs";
import { defaultUdesk } from "@/config";

const getPartnerData = () => {
  const partner_data = sessionStorage.getItem("partner_data");
  if (!partner_data || partner_data === "undefined") {
    return {};
  }
  let obj = {};
  try {
    obj = JSON.parse(sessionStorage.getItem("partner_data"));
  } catch (e) { }
  return obj;
};
const convertValue = value => {
  // 解析布尔值
  if (value === "true" || value === "false") {
    return JSON.parse(value);
  }

  // 解析数字
  if (/^\d+$/.test(value)) {
    return parseInt(value);
  }
  return value;
};
export default new Vuex.Store({
  modules: {
    hotel,
    business,
    pagj,
    buffet,
    offlineBenefit
  },
  state: {
    isMember: false,
    userInfo: {},
    tenant: sessionStorage.getItem("tenant"),
    namespace: storage.getItem("namespace") || "",
    namespace_group: storage.getItem("namespace_group") || "",
    package_name: sessionStorage.getItem("package_name") || "",
    source: storage.getItem("source") || "default",
    return_url: sessionStorage.getItem("return_url") || "",
    return_index_url: sessionStorage.getItem("return_index_url") || "",
    logining: true,
    token: storage.getItem("token") || "",
    ptoken: storage.getItem("ptoken") || "",
    namespaceInfo: {
      specialInfo: {},
      service_url: defaultUdesk,
    },
    benefitHotelType: storage.getItem("benefitHotelType") || "hotel",
    refund_detail: JSON.parse(storage.getItem("refund_detail") || "{}") || {},
    path: sessionStorage.getItem("path") || "",
    refund_unique_id: sessionStorage.getItem("refund_unique_id") || "",
    benefit_source: sessionStorage.getItem("benefit_source") || "",
    partner_data: getPartnerData(),
    isMiniProgram:
      navigator.userAgent.indexOf("miniProgram") !== -1 ||
      navigator.userAgent.indexOf("Alipay") !== -1,
    isAlipay: navigator.userAgent.indexOf("Alipay") !== -1,
    isWebchat:
      navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      "micromessenger",
    s_t: sessionStorage.getItem("s_t") || "",
    showKeepLogin: false,
    isShengbeiApp: navigator.userAgent.indexOf("Shengbei") > -1,
    isAndroid:
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1,
    isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    akgb_shop: storage.getItem("akgb_shop") || {},
    all_project: false,
    clientConfigs: [],
    env: ""
  },
  getters: {
    // 显示tabber
    namespaceGroup: state => state.tenant || state.namespace,

    // 运行环境  默认空为web环境
    runtime: state => {
      if (navigator.userAgent.indexOf("miniProgram") != -1) {
        return "miniProgram";
      }
      if (navigator.userAgent.indexOf("Alipay") != -1) {
        return "alipay";
      }
      if (state.isShengbeiApp && state.isAndroid) {
        return "shengBei_Android";
      }
      if (state.isShengbeiApp && state.isiOS) {
        return "shengBei_Ios";
      }
      if (window.welab) {
        return "welabApp";
      }
      return "";
    },

    // 酒店页面在微信公众号内显示返回按钮
    showHotelBack: state => {
      return state.isWebchat && !state.isWebchat;
    },
    getConfigValue: (state, getters) => (key, source = "", env = "") => {
      let results = state.clientConfigs.filter(
        item =>
          item.key === key &&
          (item.namespace_name === "" ||
            item.namespace_name
              .split(",")
              .filter(Boolean)
              .includes(state.namespace))
      );
      if (results.length === 0) {
        return "";
      }
      if (results.length === 1) {
        return convertValue(results[0].value);
      }
      // 查询到多个时，根据权重排序

      const runtime = env || getters.runtime;
      results.forEach(item => {
        item.weight = 0;
        if (item.namespace_name.split(",").includes(state.namespace)) {
          item.weight += 100;
        }
        if (item.env === runtime) {
          item.weight += 10;
        }
        if (item.source === source) {
          item.weight += 1;
        }
      });
      results.sort((a, b) => b.weight - a.weight);
      return convertValue(results[0].value);
    },
    payBtnColor(state, getters) {
      try {
        const isGold = getters.getConfigValue("PAY_PAGE_IS_GOLD");
        return isGold ? "pay-gold-style" : "pay-black-style";
      } catch (e) {
        return "pay-gold-style";
      }
    }
  },
  mutations: {
    changeState(state, payload) {
      for (let k in payload) {
        if (
          /^(source|benefitHotelType|openid|namespace|token|ptoken|namespace_group|)$/.test(k)
        ) {
          storage.setItem(k, payload[k]);
        }
        if (
          /^(return_url|tenant|return_index_url|s_t|benefit_source|package_name)$/.test(
            k
          )
        ) {
          sessionStorage.setItem(k, payload[k]);
        }
        state[k] = payload[k];
      }
    }
  },
  actions: {
    async initProjectData({ commit, dispatch, state }) {
      await Promise.all([
        dispatch("fetchNamespace"),
        dispatch("fetchClientConfigList")
      ]);
    },
    async fetchUserInfo({ commit, dispatch, state }, type) {
      if (!state.token || !storage.getItem("token")) {
        return Promise.resolve({});
      }
      try {
        const res = await getUserInfo();
        const { data } = await getUserLabel()
        const userLabel = Object.keys(data.data).filter(key => data.data[key])
        ans.alias(res.data.id + "");
        window.DATAFLUX_RUM &&
          window.DATAFLUX_RUM.onReady(function () {
            window.DATAFLUX_RUM.setUser({
              id: res.data.id + ""
            });
          });
        res.data.tags.push(...Object.keys(data.data).map(item => ({ tag_name: item, tag_id: item })))
        const {
          is_member,
          is_verified,
          namespace,
          source,
          tags,
          card_number,
          card_type,
          card_created_date
        } = res.data;
        ans.profileSet({
          is_member,
          is_verified,
          namespace,
          card_number,
          card_type,
          card_created_date: dayjs(card_created_date).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          source,
          tag_name: tags.map(r => r.tag_name).join(","),
          tag_id_new: tags.map(r => r.tag_id).filter(Boolean).join(","),
        });
        commit("changeState", {
          userInfo: res.data,
          isMember: res.data.is_member,
          namespace,
          userLabel,
        });
        return res.data;
      } catch (e) {
        return Promise.resolve({});
      }
    },
    async fetchClientConfigList({ commit, state }, namespace) {
      // 龙腾没有这个接口
      if (state.tenant === "lt") {
        return [];
      }
      try {
        const res = await getClientConfigList({
          namespace: namespace || state.namespace || "oakvip"
        });
        const config = JSON.parse(decodeURIComponent(atob(res.data)));
        commit("changeState", {
          clientConfigs: config
        });
        return config;
      } catch (e) {
        return [];
      }
    },
    async onePassLogin({ commit, dispatch, state }, data) {
      const res = await onepassLogin(data);
      const { user_token, namespace, one_pass_phone } = res.data;
      storage.setItem("login_phone_number", one_pass_phone);
      commit("changeState", {
        token: user_token,
        ptoken: user_token,
        namespace
      });
      const userInfo = await dispatch("fetchUserInfo");
      userInfo.login_phone_number = one_pass_phone;
      return userInfo;
    },

    async fetchNamespace({ commit, state, dispatch }, namespace) {
      // 龙腾没有这个接口
      if (state.tenant === "lt") {
        return;
      }
      try {
        const ns = namespace || state.namespace;
        if (!ns) {
          return;
        }
        const res = await getNamespaceInfo({ namespace: ns });
        const obj = res.data;
        const special_info = obj.special_info || "{}";
        obj.specialInfo = JSON.parse(special_info);
        commit("changeState", {
          namespaceInfo: obj
        });
        if (res.data.need_verify_scene) {
          commit("changeState", {
            need_verify_scene: res.data.need_verify_scene
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
});
