<template>
  <CommonPopup :show.sync="value" :showClose="false">
    <div class="warp">
      <svg-icon class="close-icon" iconClass="close-white" @click="close" />
      <div class="title">请输入权益领取手机号</div>
      <van-field
        v-model="phone"
        placeholder="请输入手机号"
        class="field-input phone"
      />
      <van-field
        v-model="code"
        placeholder="请输入验证码"
        class="field-input code"
      >
        <template #button>
          <div
            class="code-btn"
            @click="sendSmsCode"
            :class="{ countDown: countDown > 0 }"
          >
            {{ countDown > 0 ? `${countDown}s后获取` : "获取验证码" }}
          </div>
        </template>
      </van-field>
      <div class="btn" @click="handleLogin">立即开通</div>
      <div class="tips">
        购买后，懒人听书会员权益将<span>自动充值至您填写的手机号</span>，视频会员权益请购买后自行领取。
      </div>
    </div>
  </CommonPopup>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import Vue from "vue";

import CommonPopup from "@/components/CommonPopup";
import { register } from "@/apis/home";
import ans from "@/utils/ans";
import { loginByExtra } from "@/apis";
import { useStore } from "@/components/use/base";

const vm = Vue.prototype;

export default defineComponent({
  components: {
    CommonPopup,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: "visitor",
    },
    close: {
      type: Function,
    },
    resolve: {
      type: Function,
    },
  },
  setup(props) {
    const store = useStore();

    const code = ref("");
    const phone = ref("");
    const countDown = ref(0);
    const timer = ref(null);
    const sessionCode = ref("");

    const namespace = store.state.namespace;
    const namespaceInfo = store.state.namespaceInfo;

    const sendSmsCode = async () => {
      if (countDown.value > 0) {
        return;
      }
      if (!phone.value) {
        vm.$toast("请输入手机号");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone.value)) {
        vm.$toast("请输入正确的11位手机号");
        return;
      }

      const toast = vm.$loading();
      try {
        const res = await register({
          phone_number: phone.value,
          app_name: vm.$getConfigValue("APP_NAME"),
        });
        vm.$toast("验证码已发送");
        countDown.value = 60;
        sessionCode.value = res.session_code;
        timer.value = setInterval(() => {
          countDown.value--;
          if (countDown.value == 0) {
            clearInterval(timer.value);
          }
        }, 1000);
      } finally {
        toast.clear();
      }
    };

    const handleLogin = async () => {
      if (!phone.value) {
        vm.$toast("请输入手机号");
        return;
      }
      if (!sessionCode.value) {
        vm.$toast("请先获取短信验证码");
        return;
      }
      if (!code.value) {
        vm.$toast("请输入验证码");
        return;
      }
      const loading = vm.$loading("登录中...");
      try {
        ans.pageView("common_login", {
          namespace,
          from: props.from,
        });
        const res = await loginByExtra({
          code: code.value,
          session_code: sessionCode.value,
          phone_number: phone.value,
          platform: "web",
          namespace: namespace,
          source: "",
        });
        const { user_token } = res.data;
        store.commit("changeState", { token: user_token, ptoken: user_token });
        const userInfo = await store.dispatch("fetchUserInfo");
        if (!namespaceInfo.name) {
          store.dispatch("initProjectData");
        }
        ans.track("login_success", {
          from: props.from,
        });
        window.aplus_queue.push({
          action: "aplus.appendMetaInfo", //追加全局属性
          arguments: [
            "globalproperty",
            {
              is_login: true,
            },
          ],
        });
        props.resolve(userInfo);
        props.close();
      } finally {
        loading.clear();
      }
    };

    const beforeClose = () => {
      phone.value = "";
      code.value = "";
    };

    return {
      sendSmsCode,
      beforeClose,
      handleLogin,
      code,
      phone,
      countDown,
    };
  },
});
</script>

<style lang="less" scoped>
.warp {
  width: 295px;
  height: 296px;
  background: linear-gradient(180deg, #ffd5d5 0%, #ffffff 100%);
  box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.78);
  box-sizing: border-box;
  border-radius: 16px;

  .close-icon {
    margin-top: 10px;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }

  .title {
    font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
    font-size: 16px;
    color: #333333;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    margin-top: 11px;
  }

  .field-input {
    width: 263px;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    opacity: 0.75;
    margin: 0 auto;

    /deep/.van-field__control {
      height: 20px;
    }

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      color: #cccccc;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }

    &.phone {
      margin-top: 24px;
    }

    &.code {
      margin-top: 8px;

      .code-btn {
        font-weight: 500;
        font-size: 12px;
        color: #fc283e;
        line-height: 17px;
        text-align: center;

        &.countDown {
          color: #333;
        }
      }
    }
  }

  .btn {
    width: 251px;
    height: 40px;
    background: linear-gradient(#fc2342 2%, #ff7f00 100%);
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 32px;

    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }

  .tips {
    margin: 8px 17px 0 16px;
    font-weight: 400;
    font-size: 10px;
    color: #999999;
    line-height: 14px;
    > span {
      color: #ff7f02;
    }
  }
}
</style>