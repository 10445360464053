<template>
  <section class="cr cr-member" v-if="!fetching">
    <section class="header">
      <div :class="['header-card', `header-card-${card.card_type}`]">
        <div class="header-tag"></div>
        <div class="header-footer">
          <div class="header-footer-left">
            <div class="text">{{ saveMoney.text }}</div>
            <div class="amount">{{ saveMoney.amount }}</div>
          </div>
          <div class="header-footer-right">
            <van-button
              class="renew"
              type="info"
              size="small"
              round
              v-ans="{ event_id: 'click_xufee' }"
              @click="toBuy"
              >立即续费</van-button
            >
            <div class="expiry">{{ card.card_expiry_date }}到期</div>
          </div>
        </div>
      </div>
      <ul class="benefit-entry">
        <li class="benefit-entry-icon" v-for="item in entryIcon" :key="item">
          <van-image
            @click="scrollFloor(item)"
            fit="contain"
            :src="require(`../../../assets/images/cr_header_${item}.png`)"
            lazy-load
          />
        </li>
      </ul>
    </section>
    <section class="body">
      <section class="waist-banner" v-if="loops.length">
        <van-swipe
          class="waist-banner-swipe"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item v-for="(item, idx) in loops" :key="idx">
            <van-image
              class="waist-banner-img"
              @click="swipeClickWrap(item)"
              fit="cover"
              lazy-load
              :src="item.logo_url"
            />
          </van-swipe-item>
        </van-swipe>
      </section>
      <section class="benefit-list">
        <div
          :class="['benefit-item', `benefit-item-${item.display_code}`]"
          v-for="item in catesgorys"
          :key="item.display_code"
        >
          <div class="benefit-top">
            <div class="benefit-top-left">
              <div class="benefit-name">{{ item.name }}</div>
              <div class="benefit-desc">{{ item.desc }}</div>
            </div>
            <div class="benefit-top-right">
              <div class="benefit-savemonty-text">
                本月剩余{{ item.list[0].benefit.amount }}次
              </div>
            </div>
          </div>
          <ul :class="['benefit-spu', `benefit-spu-${item.display_code}`]">
            <hrCoupon
              v-if="item.display_code === 'shopping'"
              v-bind="item"
              :jump="jump"
              :getBenefitText="getBenefitText"
              :isDisabled="isDisabled"
            />
            <template v-else>
              <li
                :class="[
                  'benefit-spu-item',
                  `benefit-item-spu-${spu.product_code}`,
                ]"
                v-for="spu in item.list"
                :key="spu.id"
                v-ans="{
                  event_id: 'benefit_list_click',
                  spu_id: spu.id,
                  spu_name: spu.desc,
                }"
                @click="jump(spu.benefit)"
              >
                <div class="benefit-spu-inner">
                  <van-image
                    class="benefit-spu-img"
                    lazy-load
                    fit="cover"
                    :src="spu.display_img_url"
                  />
                  <template v-if="item.display_code === 'hotel'">
                    <div class="benefit-spu-name">{{ spu.desc }}</div>
                    <div class="benefit-spu-selling_points">
                      {{ spu.selling_points }}
                    </div>
                    <div class="benefit-spu-price" v-if="spu.lowest_price">
                      ¥<span>{{ spu.lowest_price }}</span>
                    </div>
                    <div
                      class="benefit-spu-facevalue"
                      v-if="spu.lowest_face_value"
                    >
                      原价<span>¥{{ spu.lowest_face_value }}</span>
                    </div>
                  </template>
                  <div
                    :class="[
                      'benefit-spu-btn',
                      isDisabled(spu.benefit) ? 'disabled' : '',
                    ]"
                  >
                    {{ getBenefitText(spu.benefit) }}
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </section>
    </section>
    <div class="agreement" v-if="agreementInfo.name">
      <a :href="agreementInfo.link">{{ agreementInfo.name }}</a>
    </div>
    <section class="page-footer">
      <router-link
        class="order"
        to="/order"
        v-ans="{ event_id: 'member_to_order_click' }"
      >
        <van-image
          :src="require('../../../assets/images/cr_member_order_icon.png')"
        />
        我的订单</router-link
      >
      <router-link
        class="service"
        to="/service"
        v-ans="{ event_id: 'member_to_service_click' }"
      >
        <van-image
          :src="require('../../../assets/images/cr_member_service_icon.png')"
        />
        在线客服</router-link
      >
    </section>
    <TakeDownDialog ref="takedownRef" />
  </section>
</template>

<script>
import { useRouter, useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import { queryLoopData, queryTotalSavMoney } from "@/apis/home";
import dayjs from "dayjs";
import hrCoupon from "./hrCoupon";
import animateScrollTo from "animated-scroll-to";
import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import { jsonSafeParse } from "@/utils";
import TakeDownDialog from "@/components/TakeDownDialog";

const vm = Vue.prototype;

export default defineComponent({
  props: {
    swipeClick: Function,
    toBuy: Function,
  },
  components: {
    hrCoupon,
    TakeDownDialog,
  },
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const fetching = ref(true);
    const agreementInfo = ref({});
    const saveMoney = ref({});
    const loops = ref([]);
    const { card, catesgorys, getProductList } = useMemberBenefitCategory();

    const namespace = store.state.namespace;
    const entryIcon = ref(["phone", "combo", "coffee", "jjhj"]);
    const jump = (item) => {
      if (content.refs.takedownRef.takedownConfig) {
        content.refs.takedownRef.showTakedown();
        return;
      }
      router.push(`/benefitDetail?benefit_type=${item.type}`);
    };

    const agreement = vm.$getConfigValue("AGREEMENT_INFO");
    if (agreement) {
      const info = jsonSafeParse(agreement);
      agreementInfo.value = info;
    }

    const nextDay = (start, end) => {
      if (dayjs().isBefore(dayjs(start).startOf("day"))) {
        return dayjs(start).format("YYYY.MM.DD");
      }
      return dayjs(end).add(1, "day").format("YYYY.MM.DD");
    };

    const fetchLoopData = async () => {
      const res = await queryLoopData({ activity_type: "cr_member" });
      loops.value = res.data.data_list;
    };

    const getBenefitText = (benefit) => {
      if (benefit.amount > 0) {
        return "去使用";
      } else {
        if (benefit.amount_reserved > 0) {
          return "已使用";
        }
        return "已领完";
      }
    };

    const isDisabled = (benefit) => {
      return (
        benefit?.amount === 0 ||
        benefit?.amount + benefit?.amount_reserved === 0
      );
    };

    const swipeClickWrap = (item) => {
      if (content.refs.takedownRef.takedownConfig) {
        content.refs.takedownRef.showTakedown();
        return;
      }
      props.swipeClick(item);
    };

    const getTotalMoney = async () => {
      try {
        const res = await queryTotalSavMoney();
        const { total, unit, expected_saving } = res.data;
        saveMoney.value = {
          text: total ? `累计节省(${unit})` : `预计可省(${unit})`,
          amount: total ? total : expected_saving,
        };
      } catch (e) {}
    };

    const scrollFloor = (name) => {
      const el = document.querySelector(`.benefit-item-${name}`);
      if (el) {
        animateScrollTo(el);
      }
    };

    onMounted(async () => {
      const loading = vm.$loading();

      try {
        await Promise.all([fetchLoopData(), getProductList(), getTotalMoney()]);
      } finally {
        loading.clear();
        fetching.value = false;
      }
    });
    return {
      catesgorys,
      fetching,
      jump,
      card,
      nextDay,
      getBenefitText,
      isDisabled,
      namespace,
      loops,
      saveMoney,
      entryIcon,
      scrollFloor,
      agreementInfo,
      swipeClickWrap,
    };
  },
});
</script>

<style lang="less" scoped>
.cr {
  background: #fff;
  .header {
    height: 275px;
    box-sizing: border-box;
    background: url("../../../assets/images/hr_member_header_bg.png") no-repeat
      top/100%;
    padding: 24px;
  }
  .header-card {
    height: 128px;
    background: url("../../../assets/images/hr_member_card_bg.png") no-repeat
      center/100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
  }

  .benefit-entry {
    height: 62px;
    display: flex;
    flex-wrap: nowrap;
    margin: 16px -8px 0;
    justify-content: space-between;
    /deep/ .van-image {
      height: 62px;
      width: auto;
    }
  }
  .benefit-entry-icon {
    flex: 1;
  }
  .header-tag {
    margin-left: 58px;
    height: 18px;
    width: 128px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header-card-CR06 {
    .header-tag {
      background-image: url("../../../assets/images/hr_card_tag_06.png");
    }
  }
  .header-card-CR12 {
    .header-tag {
      background-image: url("../../../assets/images/hr_card_tag_12.png");
    }
  }
  .header-footer {
    display: flex;
    justify-content: space-between;
    .amount {
      font-size: 24px;
      font-weight: bold;
      margin-top: 2px;
    }
    .renew {
      height: 24px;
      color: #efddd4;
      font-size: 12px;
      background: linear-gradient(90deg, #181b30 0%, #323865 100%);
      margin-bottom: 8px;
    }
    .text {
      line-height: 16px;
    }
    .header-footer-right {
      text-align: right;
    }
    .expiry {
      color: #23253d;
      font-size: 10px;
    }
  }
  .body {
    margin-top: -20px;
    background: #fff;
    padding: 24px 16px 0 16px;

    border-radius: 16px 16px 0px 8px;
  }

  .waist-banner {
    height: 90px;
    margin-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
  }
  .waist-banner-swipe,
  .waist-banner-img {
    height: 90px;
  }

  .benefit-item {
    margin-bottom: 20px;
  }
  .benefit-top {
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    margin-bottom: 16px;
    color: #78360f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
  }

  .benefit-top-right {
    align-self: flex-end;
    line-height: 16px;
  }

  .benefit-name {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 4px;
    font-weight: bold;
    color: #5f2907;
  }
  .benefit-desc {
    line-height: 16px;
    color: #8c4d28;
  }

  .benefit-spu-item {
    color: #78360f;
    .benefit-spu-name {
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .benefit-spu-price,
    .benefit-spu-facevalue {
      line-height: 16px;
      font-size: 12px;
    }
  }
  .benefit-spu-inner {
    position: relative;
  }

  .benefit-spu-phone {
    .benefit-spu-img {
      height: 80px;
    }
  }

  .benefit-spu-combo {
    .benefit-spu-img {
      height: 86px;
    }
  }
  .benefit-spu-coffee {
    .benefit-spu-img {
      height: 92px;
    }
    .benefit-spu-btn {
      background: #3d6445;
    }
  }

  .benefit-spu-music {
    .benefit-spu-img {
      height: 110px;
    }
  }
  .benefit-spu-ximalaya {
    .benefit-spu-img {
      height: 80px;
    }
    .benefit-spu-btn {
      background: #ff4400;
    }
  }

  .benefit-spu-jjhj {
    .benefit-spu-img {
      height: 80px;
    }
  }

  .benefit-spu-hotel {
    display: flex;
    margin: 0 -4px;
    .benefit-spu-item {
      padding: 0 4px;
      flex: 1;
    }
    .benefit-spu-img {
      height: 80px;
      margin-bottom: 8px;
    }
    .benefit-spu-selling_points {
      margin-bottom: 4px;
    }
    .benefit-spu-price,
    .benefit-spu-facevalue {
      display: inline-block;
    }
    .benefit-spu-price {
      span {
        padding-left: 1px;
      }
    }
    .benefit-spu-facevalue {
      span {
        text-decoration: line-through;
      }
      font-size: 10px;
      margin-left: 5px;
    }
    .benefit-spu-btn {
      margin-top: 8px;
    }
  }

  .benefit-item-spu-hotel {
    .benefit-spu-price,
    .benefit-spu-facevalue {
      span {
        &::after {
          content: "起";
        }
      }
    }
  }
}
.benefit-item-hotel,
.benefit-item-jjhj {
  .benefit-savemonty-text {
    display: none;
  }
}

.benefit-item-shopping {
  .benefit-savemonty-text {
    font-size: 0;
    &::after {
      font-size: 12px;
      content: "每日更新库存";
    }
  }
}

.page-footer {
  display: flex;
  height: 56px;
  background: #fff;
  align-items: center;
  border-radius: 8px;
  /deep/ img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
  .order,
  .service {
    background-repeat: no-repeat;
    background-size: 30px;
    flex: 1;
    color: #42352c;
    font-size: 13px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order {
    border-right: 1px solid #d8d8d8;
  }
}

.benefit-item {
  /deep/ .benefit-spu-btn {
    width: 78px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: linear-gradient(90deg, #9d5832 0%, #622b0a 100%) #9d5832;
    border-radius: 16px;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
    &.disabled {
      background: #ddd;
    }
  }
}

.benefit-item-phone,
.benefit-spu-shopping,
.benefit-spu-ximalaya,
.benefit-item-coffee {
  /deep/ .benefit-spu-btn {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.benefit-spu-jjhj,
.benefit-spu-music,
.benefit-spu-combo {
  .benefit-spu-btn {
    display: none;
  }
}

.agreement {
  text-align: center;
  line-height: 17px;
  a {
    color: #496d9c;
  }
}
</style>

<style lang="less">
.hrt-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}
</style>
