var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"benefit-btn-wrap"},[_vm._l((_vm.renderBtns),function(btn,index){return _c('van-button',_vm._b({directives:[{name:"ans",rawName:"v-ans",value:({
      free: _vm.isFreeBenefit,
      event_id: btn.event_id,
      benefit_type: _vm.benefit_type,
      spu_id: _vm.benefitInfo.type,
      spu_name: _vm.benefitInfo.name,
      recharge_account_type: _vm.selectSku.recharge_account_number,
      top_up_type: _vm.selectSku.top_up_type,
      item_code: _vm.selectSku.item_code,
    }),expression:"{\n      free: isFreeBenefit,\n      event_id: btn.event_id,\n      benefit_type,\n      spu_id: benefitInfo.type,\n      spu_name: benefitInfo.name,\n      recharge_account_type: selectSku.recharge_account_number,\n      top_up_type: selectSku.top_up_type,\n      item_code: selectSku.item_code,\n    }"}],key:index,attrs:{"block":"","type":"default","disabled":btn.disabled,"round":""},on:{"click":btn.handler}},'van-button',btn.params,false),[_vm._v(_vm._s(btn.text))])}),(_vm.showAtour)?_c('atour',{attrs:{"refresh":_vm.refresh,"showAtour":_vm.showAtour},on:{"update:showAtour":function($event){_vm.showAtour=$event},"update:show-atour":function($event){_vm.showAtour=$event}}}):_vm._e(),(_vm.showAirport)?_c('airporttransfer',{attrs:{"sku":_vm.selectSku,"refresh":_vm.refresh,"showAirport":_vm.showAirport},on:{"update:showAirport":function($event){_vm.showAirport=$event},"update:show-airport":function($event){_vm.showAirport=$event}}}):_vm._e(),_c('take-down-dialog',{ref:"takedownRef"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }