import Vue from 'vue';
import store from '@/store';
import router from '@/router';

import DrawPopupComponent from './DrawPopupComponent.vue'

let instance;


function isInDocument(element) {
  return document.body.contains(element);
}

function initInstance() {
  if (instance) {
    instance.$destroy();
  }

  instance = new (Vue.extend({
    ...DrawPopupComponent,
    store,
    router,
  }))({
    el: document.createElement('div'),
    // avoid missing animation when first rendered
    propsData: {
      lazyRender: false,
    },
  });
  document.body.appendChild(instance.$el);

  instance.$on('input', (value) => {
    instance.value = value;
  });
}


function DrawPopup(options) {
  return new Promise((resolve, reject) => {
    if (!instance || !isInDocument(instance.$el)) {
      initInstance();
    }
    Object.assign(instance, DrawPopup.currentOptions, options, {
      resolve,
      reject,
    });
  });
}

DrawPopup.show = (options) =>
  DrawPopup({
    value: true,
    ...options,
  });

DrawPopup.close = () => {
  if (instance) {
    instance.value = false;
  }
};

DrawPopup.currentOptions = {
  value: false,
  close: () => {
    if (instance) {
      instance.beforeClose?.();
      instance.value = false;
    }
  }
}

export default DrawPopup;
