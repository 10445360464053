<template>
  <section class="leiniao" v-if="!fetching">
    <section class="benefit">
      <div class="page-benefits">
        <template v-if="card.card_status === 'active'">
          <div
            v-for="item in card.benefit_list"
            :key="item.type"
            :class="['page-benefit-item', `page-benefit-${item.type}`]"
          >
            <van-button
              round
              :class="{ 'page-benefit-btn': true, disabled: isDisabled(item) }"
              @click="jump(item)"
            >
              {{ getBenefitText(item) }}
            </van-button>
          </div>
        </template>
        <div v-if="card.card_status === 'expired'" class="card-past">
          <van-image :src="require('../../../assets/images/yh-empty.png')" />
          <p>您的权益已过期</p>
        </div>
        <div v-if="card.card_status === 'refund'" class="card-past">
          <van-image :src="require('../../../assets/images/yh-empty.png')" />
          <p>您的权益已退款</p>
        </div>
        <section class="page-footer">
          <router-link
            class="order"
            to="/order"
            v-ans="{ event_id: 'member_to_order_click' }"
            ><svg-icon icon-class="leiniao-order" />我的订单</router-link
          >
          <router-link class="service" to="/service"
            ><svg-icon
              icon-class="leiniao-service"
              v-ans="{ event_id: 'member_to_service_click' }"
            />在线客服</router-link
          >
        </section>
        <div class="rule-btn" @click="showRule">查看活动规则</div>
      </div>
    </section>
  </section>
</template>

<script>
import { useRouter, useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import { getUserBenefit } from "@/apis/home";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const fetching = ref(true);
    const card = ref({});
    const jump = (item) => {
      router.push(`/benefitDetail?benefit_type=${item.type}`);
    };

    const isDisabled = (benefit) => {
      return (
        benefit?.amount === 0 ||
        benefit?.amount + benefit?.amount_reserved === 0
      );
    };

    const getBenefitText = (benefit) => {
      if (benefit.amount > 0) {
        return "去使用";
      } else {
        if (benefit.amount_reserved > 0) {
          return "已使用";
        }
        return "已领完";
      }
    };

    const showRule = () => {
      vm.$alert({
        title: "活动规则",
        message: store.state.namespaceInfo.rule,
        confirmButtonText: "我知道了",
      });
    };
    onMounted(async () => {
      const loading = vm.$loading();
      try {
        const res = await getUserBenefit();
        const card_info = res.data;
        if (card_info.benefit_order) {
          const orders = card_info.benefit_order.split(",");
          card_info.benefit_list.sort((a, b) => {
            return orders.indexOf(a.type) - orders.indexOf(b.type);
          });
        }
        card.value = card_info;
      } finally {
        fetching.value = false;
        loading.clear();
      }
    });
    return {
      fetching,
      jump,
      card,
      isDisabled,
      getBenefitText,
      showRule,
    };
  },
});
</script>


<style lang="less" scoped>
.leiniao {
  min-height: 100vh;
  box-sizing: border-box;
  background: #432891;
}

.page-benefits {
  box-sizing: border-box;
  background: url("../../../assets/images/leiniao_member_bg.jpg") no-repeat
    top/100%;
  padding: 265px 29px 20px 22px;
}

.page-benefit-item {
  height: 72px;
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 8px;
  }
  &.page-benefit-combo {
    background: url("../../../assets/images/leiniao-benefit-combo.png")
      no-repeat left/100%;
  }
  &.page-benefit-listeningmusic {
    background: url("../../../assets/images/leiniao-benefit-listeningmusic.png")
      no-repeat left/100%;
  }
  &.page-benefit-video_free {
    background: url("../../../assets/images/leiniao-benefit-video_free.png")
      no-repeat left/100%;
  }
  &.page-benefit-vip_combo {
    background: url("../../../assets/images/leiniao-benefit-vip_combo.png")
      no-repeat left/100%;
  }
  &.page-benefit-video_new {
    background: url("../../../assets/images/leiniao-benefit-video_new.png")
      no-repeat left/100%;
  }
}
.page-benefit-btn {
  margin-left: auto;
  margin-right: 8px;
  width: 60px;
  height: 23px;
  color: #ec4d25;
  font-size: 12px;
  line-height: 23px;
  padding: 0;
  font-weight: bold;
  &.disabled {
    background: #fff;
    color: #999;
    opacity: 1;
  }
}

.page-footer {
  display: flex;
  padding: 0 15px 20px;
  margin-top: 12px;
  .svg-icon {
    font-size: 20px;
    margin-right: 6px;
    color: #fd4c14;
    vertical-align: -4px;
  }
  .order,
  .service {
    font-size: 15px;
    border-radius: 42px;
    height: 42px;
    line-height: 42px;
    width: 50%;
    border: 1px solid #ebebeb;
    color: #202437;
    text-align: center;
    line-height: 40px;
    vertical-align: -4px;
    font-weight: bold;
  }
  .order {
    margin-right: 10px;
  }
}

.card-past {
  height: 195px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  margin: 40px 20px 20px;
  padding-top: 30px;
  display: inline-block;
  width: calc(100% - 40px);
  p {
    text-align: center;
    line-height: 1.5;
    color: #404040;
    margin-top: 17px;
    font-size: 14px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 0 auto 14px;
  }
}
.rule-btn {
  width: 86px;
  height: 23px;
  text-align: center;
  line-height: 21px;
  border: 1px solid rgba(#fff, 0.5);
  color: #fff;
  font-size: 10px;
  margin: 30px auto 0;
  border-radius: 99999px;
}
</style>