<template>
  <section class="aftermarket-select">
    <div class="aftermarket-card aftermarket-radio">
      <div class="aftermarket-select-title">售后类型</div>
      <van-radio-group v-model="type">
        <van-cell
          v-ans="{
            event_id: 'benefit_aftersale_click_change',
            order_number: $route.query.order_number,
          }"
          clickable
          @click="type = 'update_coupon'"
          label="更新券码，您可正常使用"
        >
          <template #title>
            <van-radio
              checked-color="#00B25A"
              :icon-size="16"
              name="update_coupon"
              >我要换券</van-radio
            >
          </template>
        </van-cell>
        <van-cell
          v-ans="{
            event_id: 'benefit_aftersale_click_refund',
            order_number: $route.query.order_number,
          }"
          clickable
          @click="type = 'refund'"
          label="券码回收，商家退款"
        >
          <template #title>
            <van-radio checked-color="#00B25A" :icon-size="16" name="refund"
              >我要退款</van-radio
            >
          </template>
        </van-cell>
      </van-radio-group>
    </div>
    <div class="aftermarket-card radio" v-if="order.instruction_img_url">
      <div class="aftermarket-select-title">使用指南</div>
      <p class="tips">为提高审核通过率，请核实您是否按照如下流程使用权益</p>
      <img class="benefit-img" :src="order.instruction_img_url" />
    </div>
    <section class="footer">
      <van-button
        class="footer-btn"
        round
        block
        v-ans="{
          event_id: 'page_aftersale_list_submit1',
          order_number: $route.query.order_number,
        }"
        @click="nextStep"
        >仍然无法使用，下一步</van-button
      >
      <p class="foot-tips">在工作时间8:00-20:00内，预计10分钟内处理完成</p>
      <div
        class="foot-sub-tips"
        v-ans="{ event_id: 'page_aftersale_service_click' }"
      >
        此次售后仅适用于券码不能使用的情景<br />其它问题点击这里进入<a
          class="link"
          @click="toService"
          >人工客服</a
        >
      </div>
    </section>
  </section>
</template>

<script>
import { getOrder } from "@/apis";
import { getItemInfo } from "@/apis/home";
import { getProjectDb } from "@/utils/index";

import { mapState } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      type: "update_coupon",
      order: {},
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    const { order_number } = this.$route.query;
    const res = await getOrder({ order_number });
    if (!res.data.res_list.length) {
      this.$toast("订单号不存在");
      return;
    }
    const order = res.data.res_list[0];
    const pay_info = JSON.parse(order.pay_info);
    const sku = await getItemInfo({
      item_code: pay_info.item_code,
      benefit_type: pay_info.product_code,
      project_db: getProjectDb(this.namespace),
    });
    order.instruction_img_url = sku.instruction_img_url;
    order.productTag = sku.productTag;
    this.order = order;
  },
  methods: {
    nextStep() {
      if (this.order.amount === 0 && this.type === "refund") {
        this.$toast("您的支付金额为0元，暂不支持退款，如有疑问请联系人工客服");
        return;
      }
      this.$router.push(
        `/afterMarket/form?${qs.stringify({
          ...this.$route.query,
          type: this.type,
        })}`
      );
    },
    toService() {
      const { fromUrl } = this.$route.query;
      if (fromUrl) {
        location.href = `${decodeURIComponent(fromUrl)}/service`;
      } else {
        this.$router.push(`/service?from=afterMarket`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aftermarket-select {
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
}

.aftermarket-card {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 0px 3px 0px rgba(153, 153, 153, 0.1);
  & + .aftermarket-card {
    margin-top: 12px;
  }
}
.aftermarket-select-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.aftermarket-radio {
  /deep/ .van-cell__label {
    padding-left: 22px;
  }
  /deep/ .van-radio-group {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.tips {
  color: #e02020;
  line-height: 22px;
  margin-bottom: 10px;
}
.benefit-img {
  max-width: 100%;
  height: auto;
}

.footer {
  margin-top: auto;
  text-align: center;
  padding: 0 17px;
}
.footer-btn {
  background-color: #2d3356;
  color: #fff;
}
.foot-tips {
  line-height: 22px;
  margin-top: 4px;
  color: #2d3356;
}

.foot-sub-tips {
  margin: 24px 0 12px;
  color: #999;
  line-height: 18px;
  padding: 0 30px;
}

.link {
  color: #e44343;
}
</style>