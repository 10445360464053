<template>
  <div class="redeem yst">
    <div class="top">
      <van-image
        src="https://common-h5-dev.xiangshuheika.com/static/upload/2024082626c064cb203a.jpg"
      />
    </div>
    <div class="container">
      <van-image
        class="redeem-title-img"
        src="https://common-h5-dev.xiangshuheika.com/static/upload/20240613239071f3ca91.png"
      ></van-image>
      <div class="redeem-view">
        <van-field
          input-align="center"
          class="redeem-input"
          placeholder="请输入正确的兑换码"
          v-model.trim="inputVal"
        />

        <van-button
          round
          size="large"
          block
          type="info"
          @click="redeem"
          v-ans="{
            event_id: 'redeem_button',
          }"
        >
          立即兑换
        </van-button>
      </div>

      <van-image
        class="redeem-title-img"
        src="https://common-h5-dev.xiangshuheika.com/static/upload/20240613467740e0f913d.png"
      ></van-image>
      <van-skeleton title :row="4" :loading="loading">
        <van-empty
          v-if="empty"
          class="empty"
          image="https://common-h5-dev.xiangshuheika.com/static/upload/20240613ca363aae848c2.png"
        />
        <div class="list">
          <div class="card" v-for="card in cards" :key="card.user_id">
            <van-image class="card-image" :src="card.logo_img_url"></van-image>
            <div class="card-content">
              <div class="card-name">{{ card.card_type_cn }}</div>
              <div class="card-facevalue">{{ card.subtitle }}</div>
              <div class="card-exprie">
                有效期：{{ card.t_created | formatDate("YYYY.MM.DD") }} -
                {{ card.t_expiration | formatDate("YYYY.MM.DD") }}
              </div>
            </div>
            <div class="card-right">
              <van-button
                v-ans="{
                  event_id: 'redeem-use',
                  card_name: card.card_type,
                }"
                class="to-use"
                round
                block
                :disabled="card.card_status !== 'active'"
                @click="toBenefit(card)"
                >去使用</van-button
              >
              <div class="time" v-if="card.amount !== -1">
                剩{{ card.amount }}次
              </div>
            </div>
          </div>
        </div>
      </van-skeleton>
    </div>
    <van-dialog
      class="redeem-dialog"
      v-model="show"
      title="恭喜您已获得"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="redeem-msg">{{ redeemCard.card_type_cn }}</div>
      <van-image
        class="redeem-icon"
        src="https://common-h5-dev.xiangshuheika.com/static/upload/20240613f9782e3fb8377.png"
      ></van-image>
      <van-button
        v-ans="{
          event_id: 'dialog_redeem_use_click',
        }"
        class="to-use"
        block
        round
        @click="toBenefit(redeemCard)"
        >去使用</van-button
      >
      <div class="dialog-close-icon" @click="show = false"></div>
    </van-dialog>
  </div>
</template>

<script>
import {
  getUserBenefit,
  getUserBenefityToken,
  goMultiCardCashing,
} from "@/apis/home";
import { mapActions, mapMutations, mapState } from "vuex";
import { storage } from "@/utils";
import LoginDialog from "@/components/loginDialog";
import CustomDialog from "@/components/dialog";
import { getMutilCardByNg } from "@/apis/product";

export default {
  data() {
    return {
      show: false,
      inputVal: "",
      msg: "权益使用权",
      cards: [],
      empty: true,
      redeemCard: {},
      loading: false,
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo", "token"]),
  },
  async mounted() {
    // 重定向链接参数，防止刷新页面时丢失

    if (this.token) {
      this.fetchList();
    } else {
      this.login();
    }
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions([
      "fetchUserInfo",
      "fetchClientConfigList",
      "initProjectData",
    ]),
    async redeem() {
      const token = storage.getItem("token");
      if (!token && this.namespace) {
        this.login();
        return;
      }
      if (!this.inputVal) {
        this.$toast("请填写兑换码");
        return false;
      }
      const loading = this.$loading();
      try {
        const res = await goMultiCardCashing({
          cashing_code: this.inputVal,
        });
        this.redeemCard = res.data.data;
        this.show = true;
        await this.fetchList();
      } finally {
        loading.clear();
      }
    },
    async login() {
      await LoginDialog.show({
        title: "手机号登录后使用",
        className: "yst-login",
        from: "redeem",
      });
      this.fetchList();
    },
    async redeemed() {
      if (!this.token && this.namespace) {
        this.login();
        return;
      }
    },
    async toBenefit(card) {
      const loading = this.$loading();
      this.changeState({ token: card.token, namespace: card.namespace });
      await this.fetchUserInfo();
      await this.initProjectData();
      loading.clear();
      let benefits = [];
      if (card.card) {
        benefits = card.card.benefit_list;
      } else {
        const res = await getUserBenefit();
        benefits = res.data.benefit_list;
      }
      // 超过1个权益或者没有权益，跳转到首页
      if (benefits.length > 1 || benefits.length === 0) {
        this.$router.push("/index");
      } else {
        this.$router.push(`/benefitDetail?benefit_type=${benefits[0].type}`);
      }
    },
    async fetchList() {
      try {
        this.loading = true;
        const res = await getMutilCardByNg();
        const cards = res.data;
        const promises = res.data.map((item) =>
          getUserBenefityToken(item.token)
        );
        const benefits = await Promise.all(promises);
        cards.forEach((item, index) => {
          item.card = benefits[index].data;
          if (item.card.benefit_list.length > 1) {
            item.subtitle = "";
            item.amount = -1;
          } else {
            item.amount = item.card.benefit_list[0].amount;
            item.subtitle = item.card.benefit_list[0].subtitle;
          }
        });
        this.cards = cards;
        console.log(cards);
        this.empty = res.data.length === 0;
      } finally {
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    CustomDialog.close();
  },
};
</script>

<style lang="less" scoped>
.redeem {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.yst {
  background: #ffc900;
  .top {
    height: 375px;
  }
  .container {
    padding: 0 16px;
  }
  .redeem-view {
    padding: 0 16px;
  }
  .redeem-title-img {
    margin: 22px 0;
    height: 25px;
    text-align: center;
    /deep/ img {
      width: 118px;
      height: 25px;
      display: inline-block;
    }
  }
  .redeem-view {
    background: #fff;
    border-radius: 10px;
    padding: 32px 25px;
  }
  .redeem-input {
    border: 1px solid #9b9b9b;
    border-radius: 9999px;
    text-align: center;
    margin-bottom: 16px;
  }
  /deep/ .van-button {
    background: #ff0000;
    color: #fff;
    font-size: 16px;
  }
}

.empty {
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  /deep/ .van-empty__image {
    width: 154px;
    height: 114px;
  }
}

.redeem-msg {
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  color: #000;
  margin-bottom: 40px;
}

.redeem-icon {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 103px;
  height: 87px;
}

.redeem-dialog {
  width: 75%;
  overflow: inherit;
  padding-top: 45px;
  padding: 45px 20px 40px;
  /deep/ .van-dialog__header {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .to-use {
    background: #ff0000;
  }
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  height: 101px;
  box-sizing: border-box;
  background: url("~@/assets/images/redeem_card_bg.png") no-repeat
    center/contain;
}

.card-image {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
.card-name {
  line-height: 20px;
  font-size: 14px;
  color: #222;
  margin-bottom: 4px;
}
.card-facevalue {
  line-height: 14px;
  color: #999;
  margin-bottom: 12px;
}

.card-exprie {
  line-height: 14px;
  color: #fc5b3c;
  font-size: 10px;
}

.card-right {
  margin-left: auto;
  width: 60px;
  text-align: center;
  .to-use {
    padding: 0;
    font-size: 12px;
    height: 26px;
    margin-bottom: 4px;
  }
  .time {
    color: #666666;
    font-size: 10px;
  }
}
</style>

<style lang="less">
.yst-login {
  .van-button {
    background: #ff0000;
    color: #fff;
  }
  .login-agreement {
    display: none;
  }
  .login-form {
    padding: 0 20px 10px;
  }
  .form-submit {
    padding-bottom: 20px;
    margin: 10px 0 0 0;
  }
  .field-input {
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 20px;
    &::after {
      content: none;
    }
  }
  .code {
    border: none;
    border-radius: 0;
    color: #405efc;
    border-left: 1px solid #ebecf1;
  }
  .error-text {
    padding: 0;
    color: #eb5e5e;
  }
}
</style>