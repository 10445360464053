<template>
  <div class="order-footer-fill">
    <div
      class="order-footer"
      v-if="
        toCouponUse ||
        showCancelButton ||
        showService ||
        showAfterMarket ||
        showIndex
      "
    >
      <div
        v-if="showCancelButton"
        class="footer-btn"
        size="small"
        round
        v-ans="{
          event_id: 'order_detail_click_cancel',
          order_id: detail.order_number,
          order_type: detail.type,
          order_time: detail.t_created,
          namespace,
        }"
        @click="cancelOrder"
      >
        {{ detail.type === "hljyk" ? "取消预约" : "取消订单" }}
      </div>

      <div
        v-if="showService"
        class="footer-btn"
        @click="toService"
        v-ans="{
          event_id: 'order_detail_click_qa',
          order_id: detail.order_number,
          order_type: detail.type,
          order_time: detail.t_created,
          namespace,
        }"
      >
        联系客服
      </div>
      <!-- 有售后标签 -->
      <div
        v-if="showAfterMarket"
        class="footer-btn"
        size="small"
        round
        v-ans="{
          event_id: 'benefit_detail_click_button4',
          order_id: detail.order_number,
          flow_status: flow.flow_status,
          namespace,
        }"
        @click="afterMarket"
      >
        {{ afterMarketText }}
      </div>
      <div
        v-if="showIndex"
        class="footer-btn"
        @click="toIndex"
        v-ans="{
          event_id: 'order_detail_click_back',
          order_id: detail.order_number,
          order_type: detail.type,
          order_time: detail.t_created,
          namespace,
        }"
      >
        返回首页
      </div>
    </div>
  </div>
</template>

<script>
import moment from "dayjs";
import { mapGetters, mapState } from "vuex";
import {
  updateOrder,
  cleanTeethChangeOrderStatus,
  getMemberInfo,
} from "@/apis";
import { getUdeskUrl } from "@/helper";
import { defaultUdesk } from "@/config";

export default {
  props: [
    "detail",
    "payInfo",
    "itemInfo",
    "toCouponUse",
    "fetchOrder",
    "fetchOrderFn",
    "available",
    "isAfterMarket",
    "flow",
    "showIndex",
    "toIndex",
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState([
      "namespaceInfo",
      "isAndroid",
      "isiOS",
      "isShengbeiApp",
      "isMiniProgram",
      "isAlipay",
      "namespace",
      "return_index_url",
      "userInfo",
      "isMember",
    ]),
    ...mapGetters(["runtime"]),
    time() {
      const current = new Date().getTime();
      const endTime = moment(this.detail.t_created).valueOf() + 1000 * 30 * 60;
      return endTime - current;
    },
    showAfterMarket() {
      return (
        this.available &&
        this.itemInfo.attributes &&
        this.itemInfo.attributes.afterMarket &&
        this.detail.status === "success"
      );
    },
    showService() {
      return !this.isMiniProgram || this.namespace === "xinsheng01";
    },
    showCancelButton() {
      if (
        (this.detail.type === "hljyk" &&
          this.payInfo.appointment_status === "预约确认中") ||
        (this.detail.type === "appointment_akgb" &&
          this.detail.status === "confirming")
      ) {
        return true;
      }
      return false;
    },
    afterMarketText() {
      if (!this.flow.flow_status || this.flow.flow_status === "cancel") {
        return "申请售后";
      }
      if (
        this.flow.flow_status === "refunded" ||
        this.flow.flow_status === "updated"
      ) {
        return "售后完成";
      }
      return "售后详情";
    },
  },
  async mounted() {},
  methods: {
    async toService() {
      if (this.$getConfigValue("JUMP_TO_UDESK_FORM_ORDER_SWITCH")) {
        let url = defaultUdesk;
        if (this.isMember) {
          const res = await getMemberInfo();
          url = res.data.service_url;
        }
        location.href = getUdeskUrl(
          url,
          this.detail.order_number,
          this.detail.name
        );
      } else {
        this.$router.push(
          `/service?order_number=${this.detail.order_number}&from=order`
        );
      }
    },
    afterMarket() {
      // 没有申请 或者取消申请 可以重新进入到申请页面
      if (!this.flow.flow_status || this.flow.flow_status === "cancel") {
        this.$router.push(
          `/afterMarket/select?order_number=${this.detail.order_number}&product_tag=${this.itemInfo.attributes.afterMarket}`
        );
      } else {
        this.$router.push(
          `/afterMarket/record?order_number=${this.detail.order_number}`
        );
      }
    },
    async cancelOrder() {
      const { order_number } = this.$route.query;
      this.$confirm({
        message: "<div class='confirm-text'>确定取消当前预约吗？</div>",
        cancelButtonText: "我再想想",
        confirmButtonText: "确定",
      }).then(async () => {
        const loading = this.$loading();
        try {
          if (this.detail.type === "hljyk") {
            await cleanTeethChangeOrderStatus({
              user_id: this.userInfo.id,
              namespace_name: this.namespace,
              order_number: this.payInfo.appointment_order_number,
              status: "canceled",
            });
          } else {
            await updateOrder({
              order_number,
              status: "canceled",
            });
          }
          // 重新查询订单信息
          await this.fetchOrder();
        } finally {
          loading.clear();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-footer-fill {
  height: 60px;
  height: calc(60px + constant(safe-area-inset-bottom));
  height: calc(60px + env(safe-area-inset-bottom));
}
.order-footer {
  height: 52px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  justify-content: flex-end;
}

.footer-btn {
  color: #999;
  padding: 0 15px;
  border: 1px solid #bbbbbb;
  line-height: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 30px;
  margin-left: 10px;
  font-size: 12px;
}

.count {
  font-size: @font-md;
}
.count-down {
  display: inline-block;
}
.unpaid-btn {
  margin-left: auto;
  border-radius: 4px;
  color: #fff;
  font-size: @font-lg;
  font-weight: normal;
}
</style>