<template>
  <main class="complaints">
    <div class="btn">
      <button
        @click="help"
        v-ans="{
          event_id: 'complaints_button1',
          namespace,
        }"
      >
        常见问题
      </button>
      <button
        @click="service"
        v-ans="{
          event_id: 'complaints_button2',
          namespace,
        }"
      >
        联系客服
      </button>
    </div>
    <div class="box">
      <div class="title">
        <i></i>
        <p>订单号 <span>必填</span></p>
      </div>
      <van-field
        class="phone"
        v-model="order"
        type="text"
        placeholder="请填写订单号"
        :disabled="!!$route.query.order_number"
      />
    </div>
    <div class="box" v-if="!isNewForm">
      <div class="title">
        <i></i>
        <p>请选择问题诉求 <span>必填</span></p>
      </div>
      <div class="select" @click="show_appeal = true">
        {{ appeal }}
      </div>
    </div>
    <div class="box">
      <div class="title">
        <i></i>
        <p>{{ isNewForm ? "请选择投诉原因" : "申请原因" }} <span>必填</span></p>
      </div>
      <div class="select" @click="show_reason = true">
        {{ reason }}
      </div>
    </div>
    <div class="box">
      <div class="title">
        <i></i>
        <p>补充描述 <span>必填</span></p>
      </div>
      <van-field
        class="textarea"
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请填写10字以上的问题描述，以便我们提供更好的服务。"
        show-word-limit
      />
    </div>
    <div class="box">
      <div class="title">
        <p>上传凭证 <span>选填</span></p>
      </div>
      <div class="content">
        <p>支持 jpg、png、gif、bmp、psd、tiff 等图片格式</p>
        <van-uploader
          v-model="fileList"
          :before-read="beforeRead"
          multiple
          :max-count="5"
        />
      </div>
    </div>
    <div class="box" v-if="isNewForm">
      <div class="title">
        <p>投诉与建议 <span>选填</span></p>
      </div>
      <van-field
        class="textarea"
        v-model="advice"
        rows="2"
        autosize
        type="textarea"
        placeholder="欢迎您对我们的服务提出宝贵的意见和建议"
        show-word-limit
      />
    </div>
    <div class="box">
      <div class="title">
        <i></i>
        <p>联系方式 <span>必填</span></p>
      </div>
      <van-field
        class="phone"
        v-model="phone"
        type="tel"
        placeholder="建议留下本人手机号"
      />
      <div class="tips">
        提供联系方式有利于我们快速响应，我们在审核后会短信通知您，请留意短信查收
      </div>
    </div>
    <div
      class="submit"
      @click="submit"
      v-ans="{
        event_id: 'complaints_submit',
        namespace,
      }"
    >
      提交
    </div>

    <van-popup v-model="show_appeal" round closeable position="bottom">
      <div class="p-tit">请选择问题诉求</div>
      <ul class="p-list">
        <li
          v-for="(item, index) in appeal_list"
          @click="selectAppeal(index)"
          :class="{ active: item.select }"
        >
          {{ item.text }}
        </li>
      </ul>
    </van-popup>
    <van-popup v-model="show_reason" round closeable position="bottom">
      <div class="p-tit">申请原因</div>
      <ul class="p-list">
        <li
          v-for="(item, index) in reason_list"
          @click="selectSeason(index)"
          :class="{ active: item.select }"
        >
          {{ item.text }}
        </li>
      </ul>
    </van-popup>
  </main>
</template>

<script>
import Vue from "vue";
import { Uploader } from "vant";
import Compressor from "compressorjs";
import { complaint, getMemberInfo, uploadFlowPicture } from "@/apis";
import { mapState } from "vuex";
import { xsoak_domain } from "@/config";
import { storage } from "@/utils";
import { getUdeskUrl } from "@/helper";
Vue.use(Uploader);

export default {
  data() {
    return {
      fileList: [],
      phone: "",
      message: "",
      advice: "",
      show_appeal: false,
      show_reason: false,
      appeal_list: [
        { text: "仅退款", select: false },
        { text: "仅退货退款", select: false },
        { text: "对商品或服务不满意", select: false },
        { text: "催促发货/履约", select: false },
      ],
      reason_list: [
        { text: "不想要了", select: false },
        { text: "未按约定时间提供商品 /服务", select: false },
        { text: "商品 /服务与承诺（约定）不符", select: false },
        { text: "商品 /服务存在质量问题", select: false },
        { text: "其他", select: false },
      ],
      order: this.$route.query.order_number,
    };
  },
  computed: {
    ...mapState(["namespace"]),
    appeal() {
      const list = this.appeal_list.filter((v) => v.select);
      if (list.length) {
        return list[0].text;
      } else {
        return "请选择";
      }
    },
    reason() {
      const list = this.reason_list.filter((v) => v.select);
      if (list.length) {
        return list[0].text;
      } else {
        return "请选择";
      }
    },
    isNewForm() {
      return !!this.$getConfigValue("COMPLAINTS_REASON");
    },
  },
  async mounted() {
    const config = JSON.parse(
      this.$getConfigValue("COMPLAINTS_REASON") || "{}"
    );
    if (config.data) {
      this.reason_list = config.data;
    }
  },

  methods: {
    help() {
      if (this.namespace.includes("_life")) {
        let url = `/help?namespace=${
          this.namespace
        }&user_token=${storage.getItem("token")}&from=complaints`;
        window.location.href = `${xsoak_domain}${url}`;
        return;
      } else {
        this.$router.push("/help");
      }
    },

    async service() {
      const res = await getMemberInfo();
      if (res.data.service_url) {
        location.href = getUdeskUrl(res.data.service_url);
      } else {
        this.$toast("暂无客服");
      }
    },

    selectAppeal(index) {
      const list = this.appeal_list;
      list.map((v) => (v.select = false));
      list[index].select = true;
      this.appeal_list = list;
      this.show_appeal = false;
    },
    selectSeason(index) {
      const list = this.reason_list;
      list.map((v) => (v.select = false));
      list[index].select = true;
      this.reason_list = list;
      this.show_reason = false;
    },

    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 1200,
          maxHeight: 1200,
          success: resolve,
          error() {},
        });
      });
    },

    async submit() {
      const { entrance } = this.$route.query;
      const loading = this.$loading();
      try {
        if (!this.order) {
          this.$toast("请填写订单号");
          return;
        }
        if (
          !this.appeal_list.filter((v) => v.select).length &&
          !this.isNewForm
        ) {
          this.$toast("请选择问题诉求");
          return;
        }
        if (!this.reason_list.filter((v) => v.select).length) {
          this.$toast("请选择申请原因");
          return;
        }
        if (!this.message) {
          this.$toast("请输入补充描述");
          return;
        }
        if (!this.phone) {
          this.$toast("请输入本人手机号");
          return;
        }
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
          this.$toast("请填写11位正确的手机号");
          return;
        }
        const promises = this.fileList.map((item) =>
          uploadFlowPicture({
            data: item.content,
          })
        );
        const uploads = await Promise.all(promises);
        const type = this.appeal_list.filter((v) => v.select);
        const reason = this.reason_list.filter((v) => v.select)[0].text;
        const opts = {
          reason,
          desc: this.message,
          phone: this.phone,
          img: uploads.map((item) => item.data.url).join(","),
        };
        if (type.length) {
          opts.type = type[0].text;
        }
        if (this.advice) {
          opts.suggestion = this.advice;
        }
        if (this.order) {
          opts.order_number = this.order;
        }
        if (entrance) {
          opts.entrance = decodeURIComponent(entrance);
        }
        await complaint(opts);
        this.$toast("提交投诉成功");
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.complaints {
  padding-bottom: 24px;
}
.btn {
  display: flex;
  justify-content: space-between;
  margin: 12px;
  button {
    width: 49%;
    background-color: #fff;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    border: none;
  }
}

.box {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 12px;
  margin: 0 12px 12px;
  .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: flex-end;
    }
    span {
      font-size: 12px;
      color: #999;
      font-weight: normal;
      margin-left: 6px;
    }
    i {
      width: 4px;
      height: 4px;
      background-color: #e02020;
      margin-right: 6px;
      border-radius: 50%;
    }
  }

  .content {
    background-color: #fafafa;
    border-radius: 4px;
    min-height: 100px;
    margin-top: 10px;
    padding: 12px;
    p {
      font-size: 12px;
      color: #999;
    }
    /deep/ .van-uploader {
      margin-top: 12px;
    }
    /deep/ .van-uploader__upload-icon {
      width: 100%;
      height: 100%;
    }
    /deep/ .van-icon-photograph::before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../assets/images/upload.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.textarea {
  background-color: #fafafa;
  min-height: 48px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 12px;
}

.phone {
  background-color: #fafafa;
  margin-top: 10px;
  font-size: 12px;
}
.tips {
  font-size: 10px;
  color: #c0bebe;
  margin-top: 8px;
}

.submit {
  background-color: #2d3356;
  margin: 24px 12px 0;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 48px;
  color: #fff;
  font-size: 16px;
}
.select {
  height: 40px;
  border-radius: 4px;
  background-color: #fafafa;
  margin-top: 8px;
  line-height: 40px;
  padding-left: 12px;
  font-size: 12px;
  position: relative;
  &::after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    right: 8px;
    top: 12px;
    background: url("../../assets/images/down2.png") no-repeat;
    background-size: 100% 100%;
  }
}
.p-tit {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.p-list {
  margin: 30px 20px 40px;
  li {
    display: flex;
    font-size: 14px;
    margin-bottom: 30px;
    background: url("../../assets/images/select.png") no-repeat right center;
    background-size: 16px 16px;
  }
  .active {
    background: url("../../assets/images/selected.png") no-repeat right center;
    background-size: 16px 16px;
  }
}
</style>
