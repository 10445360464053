<template>
  <van-dialog
    v-close="close"
    :class="['login-dialog', className]"
    :value="value"
    :show-confirm-button="false"
  >
    <div class="login-header">
      <div :class="{ 'login-title': true }" v-html="getTitle"></div>
      <div class="login-desc" v-if="subtitle">{{ subtitle }}</div>
    </div>
    <van-form
      class="login-form"
      @submit="onSubmit"
      @failed="onFailed"
      :show-error="false"
      :show-error-message="false"
    >
      <van-field
        class="field-input"
        v-model.trim="phone"
        placeholder="请输入手机号"
        :rules="[
          {
            required: true,
            message: '请输入手机号',
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的11位手机号',
          },
        ]"
      ></van-field>
      <div class="get-code">
        <van-field
          class="field-input"
          v-model.trim="code"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        />
        <div
          :class="{ code: true, countDown: countDown > 0 }"
          v-ans="{ event_id: 'validate_code_click', from, type: 'login' }"
          @click="getVerifyCode"
        >
          {{ countDown > 0 ? `${countDown}s后获取` : "获取验证码" }}
        </div>
      </div>

      <div class="error-text">{{ error }}</div>
      <div class="form-submit">
        <van-button
          round
          size="large"
          block
          type="info"
          v-ans="{ event_id: 'login_btn_click', from }"
          native-type="submit"
          >立即登录</van-button
        >
      </div>
      <div class="login-agreement" v-if="!needCheck">
        登录即代表您同意
        <span class="login-agreement-text" @click="toAgreement">
          《橡树会员权益服务协议》
        </span>
      </div>
      <div class="login-agreement display-flex" v-else>
        <van-checkbox
          v-model="radio"
          shape="round"
          checked-color="rgb(221, 100, 70)"
          icon-size="16px"
          style="margin-right: 9px"
        ></van-checkbox>
        同意
        <span class="login-agreement-text" @click="toAgreement">
          《橡树会员权益服务协议》
        </span>
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import { register } from "@/apis/home";
import { mapActions, mapMutations, mapState } from "vuex";
import { loginByExtra } from "@/apis";
import ans from "@/utils/ans";

export default {
  props: {
    from: {
      type: String,
      default: "visitor",
    },
    type: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
    reject: {
      type: Function,
      required: true,
    },
    ns: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    needCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subtitle: "",
      title: "",
      phone: "",
      code: "",
      countDown: 0,
      error: "",
      session_code: "",
      radio: false,
    };
  },

  mounted() {
    ans.track("login_dialog_show", {
      from: this.from,
    });
  },
  computed: {
    ...mapState(["namespace", "namespaceInfo", "namespaceInfo", "source"]),
    getTitle() {
      return this.title || "请先登录";
    },
  },

  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo", "initProjectData"]),
    toAgreement() {
      location.href = "https://cdn.xiangshuheika.com/h5/agreement/kaisa.html";
    },
    async onSubmit() {
      if (!this.session_code) {
        this.$toast("请先获取短信验证码");
        return;
      }
      if (this.needCheck && !this.radio) {
        this.$toast("请先同意协议");
        return;
      }
      this.error = "";
      const loading = this.$loading("登录中...");
      try {
        ans.pageView("common_login", {
          namespace: this.namespace,
          from: this.from,
        });
        const res = await loginByExtra({
          code: this.code,
          session_code: this.session_code,
          phone_number: this.phone,
          platform: this.namespace === "keep" ? "route" : "web",
          namespace: this.ns || this.namespace,
          source: this.source || "",
        });
        const { user_token } = res.data;
        this.changeState({ token: user_token, ptoken: user_token });
        const userInfo = await this.fetchUserInfo();
        if (!this.namespaceInfo.name) {
          await this.initProjectData();
        }
        ans.track("login_success", {
          from: this.from,
        });
        window.aplus_queue.push({
          action: "aplus.appendMetaInfo", //追加全局属性
          arguments: [
            "globalproperty",
            {
              is_login: true,
            },
          ],
        });
        this.resolve(userInfo);
        this.close();
      } finally {
        loading.clear();
      }
    },
    // 关闭弹窗时 清空form内容
    // 此方法由组件实例调用
    beforeClose() {
      this.phone = "";
      this.code = "";
      this.error = "";
    },

    onFailed({ errors }) {
      if (errors.length) {
        this.error = errors[0].message;
      }
    },
    async getVerifyCode() {
      if (this.countDown > 0) {
        return;
      }
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请输入正确的11位手机号");
        return;
      }

      const toast = this.$loading();
      try {
        const res = await register({
          phone_number: this.phone,
          app_name: this.$getConfigValue("APP_NAME"),
        });
        this.$toast("验证码已发送");
        this.countDown = 60;
        this.session_code = res.session_code;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown == 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      } finally {
        toast.clear();
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.changeState({ showLogin: false });
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-dialog {
  overflow: inherit;
}
.login-header {
  text-align: center;
  padding: 30px 24px 30px;
  position: relative;
}
.login-icon {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  background: url("../../assets/images/icon-s.png") no-repeat;
  background-size: 100%;
}
.login-title {
  line-height: 28px;
  font-size: @font-lg;
  font-weight: bold;
  &.free-pay-title {
    margin-top: 20px;
  }
}

.login-form {
  padding: 0 8px;
}
.login-desc {
  line-height: 25px;
  font-size: 16px;
}
.get-code {
  position: relative;
}
.field-input {
  padding-top: 13px;
  padding-bottom: 13px;
}
.code {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 80px;
  color: @red-color;
  border: 1px solid;
  border-color: currentColor;
  line-height: 26px;
  height: 26px;
  text-align: center;
  border-radius: 26px;
  font-size: @font-sm;
  box-sizing: border-box;
  &.countDown {
    color: @text-gray-color;
  }
}
.form-submit {
  margin: 18px 16px 14px;
}
.error-text {
  color: @red-color;
  font-size: @font-sm;
  line-height: 20px;
  padding: 0 16px;
  margin-top: 10px;
  height: 20px;
}

.login-agreement {
  text-align: center;
  font-size: 12px;
  color: #666;
  padding-bottom: 10px;
}
.login-agreement-text {
  color: #0091ff;
}
</style>